// frontend/src/components/LivePage/TwitchOAuthButton.js
import React from 'react';

export const handleTwitchOAuth = () => {
    const clientID = 'd7y3gcjoamzyj56gww8ls1m1pj0q8d';  // Replace with your Twitch client ID
    const redirectURI = 'https://streamchatandy.com/twitch/callback';  // Your callback URL
    const scope = 'chat:read moderation:read user:read:email';  // Define required scopes

    // Generate the full OAuth URL
    const oauthURL = `https://id.twitch.tv/oauth2/authorize?client_id=${clientID}&redirect_uri=${redirectURI}&response_type=token&scope=${scope}&force_verify=true`;

    // Redirect to the OAuth URL
    window.location.href = oauthURL;
};

const TwitchOAuthButton = () => {
    return (
        <button onClick={handleTwitchOAuth} className="btn btn-primary">
            Connect Twitch
        </button>
    );
};

export default TwitchOAuthButton;

