// frontend/src/components/LivePage/LiveAISettings.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLiveSessionSettings } from '../../redux/actions/LiveSessionActions';
import sessionService from '../../services/sessionService'; 
import { FaSmile, FaMeh, FaFrown, FaCheck, FaTimes } from 'react-icons/fa';
import './LiveAISettings.css';


const LiveAISettings = ({  liveSession }) => {
    const dispatch = useDispatch();

    const [frequency, setFrequency] = useState(liveSession.settings.frequency || 30);
    const [strictMode, setStrictMode] = useState(liveSession.settings.strictMode || true);
    const [tone, setTone] = useState(liveSession.settings.tone || 'neutral');
    const tokenBalance = useSelector((state) => state.auth.tokenBalance);
    const [tokenEstimate , setTokenEstimate] = useState(0);
    const context = useSelector(state => state.liveSessionReducer.context);
    const perHourEstimate = (3600 / frequency) * tokenEstimate;
    const [activeTab, setActiveTab] = useState("basic");
    
    

    const formatContextWithRelativeTime = (context) => {
        return context.map((ctx, index) => {
            const minutesAgo = Math.floor((Date.now() - new Date(ctx.timestamp)) / 60000);
            let timeLabel = `${minutesAgo} minutes ago`;
            if (minutesAgo >= 60) {
                const hoursAgo = Math.floor(minutesAgo / 60);
                timeLabel = `${hoursAgo} hours ago`;
            }
            return `${index + 1}: ${ctx.text} - happened ${timeLabel}`;
        }).join('; '); // Separate items with semicolons
    };

    const handleFrequencyChange = (e) => {
        const newFrequency = parseInt(e.target.value, 10);
        if (newFrequency > 0) {
            setFrequency(newFrequency);
            dispatch(updateLiveSessionSettings({ frequency: newFrequency }));
        }
    };

    const handleToneChange = (toneValue) => {
        setTone(toneValue);
        dispatch(updateLiveSessionSettings({ tone: toneValue }));
    };

    const handleModeChange = (modeValue) => {
        setStrictMode(modeValue === 'strict');
        dispatch(updateLiveSessionSettings({ strictMode: modeValue === 'strict' }));
    };
    
    useEffect(() => {
        const handleTokenEstimate = async () => {
            const formattedContextString = formatContextWithRelativeTime(context);  // Format context
            try {
                const response = await sessionService.fetchTokenEstimate(formattedContextString);
                setTokenEstimate(response.estimated_cost);
            } catch (error) {
                // console.error("Error estimating token cost:", error);
            }
        };
        handleTokenEstimate();
    }, [context]);

        // Calculate time left based on token balance and estimated hourly token usage
    const hoursLeft = Math.floor(tokenBalance / perHourEstimate);
    const minutesLeft = Math.round(((tokenBalance / perHourEstimate) - hoursLeft) * 60);
    
    return (
        <div className="ai-settings-container">
            <div className="token-info">
                <p>Token Balance: <span className="token-value">{tokenBalance}</span></p>
                <p>Estimated Tokens per spent hour at current settings: <span className="token-value">{perHourEstimate.toFixed(2)}</span></p>
                <p>Estimated Time Left: <span className="token-value">{hoursLeft}h {minutesLeft}m</span></p>
            </div>

            <div className="settings-section">
                {/* Tab Headers */}
                <div className="tabs">
                    <button
                        className={`tab ${activeTab === "basic" ? "active" : ""}`}
                        onClick={() => setActiveTab("basic")}
                    >
                        Basic Messages
                    </button>
                    <button className="tab disabled-tab" data-tooltip="Under construction">
                        AI to AI Convo
                    </button>
                    <button className="tab disabled-tab" data-tooltip="Under construction">
                        Dynamic Discussion
                    </button>
                    <button className="tab disabled-tab" data-tooltip="Under construction">
                        Filler Messages
                    </button>
                </div>

                {/* Tab Content */}
                {activeTab === "basic" && (
                    <div className="tab-content">
                        <p>Basic Messages</p>
                        <p className="settings-description">At the given frequency (or around there in ambiguous mode) this selects a random chatter based on a weighted system, that takes into account a ChatterAndy's "Chatter Frequency" found in that Chatter's LIVE Settings. We then generate a message based off of their Archetype, Custom Message, and your Context from the Context tab above.  </p>
                        <p>Estimated token cost per Generation: <span className="token-value">{tokenEstimate.toFixed(2)}</span></p>
                        <label>Frequency (seconds):</label>
                        <input
                            type="range"
                            min="20"
                            max="600"
                            step="10"
                            value={frequency}
                            onChange={handleFrequencyChange}
                        />
                        
                        <span>{frequency} sec</span>
                        <p className="settings-description">Set how often messages are generated (between every 20 seconds to every 10 minutes).</p>
                        <p className="settings-description">(300 seconds is 5 minutes. 600 Seconds is 10 minutes)</p>

                        <label>Tone:</label>
                        <div className="tone-buttons">
                            <button
                                className={`tone-btn ${tone === 'neutral' ? 'selected' : ''}`}
                                onClick={() => handleToneChange('neutral')}
                            >
                                <FaMeh /> Neutral
                            </button>
                            <button
                                className={`tone-btn ${tone === 'sarcastic' ? 'selected' : ''}`}
                                onClick={() => handleToneChange('sarcastic')}
                            >
                                <FaFrown /> Sarcastic
                            </button>
                            <button
                                className={`tone-btn ${tone === 'enthusiastic' ? 'selected' : ''}`}
                                onClick={() => handleToneChange('enthusiastic')}
                            >
                                <FaSmile /> Enthusiastic
                            </button>
                        </div>
                        <p className="settings-description">Choose the tone for generated messages.</p>

                        <label>Mode:</label>
                        <div className="mode-buttons">
                            <button
                                className={`mode-btn ${strictMode ? 'selected' : ''}`}
                                onClick={() => handleModeChange('strict')}
                            >
                                <FaCheck /> Strict
                            </button>
                            <button
                                className={`mode-btn ${!strictMode ? 'selected' : ''}`}
                                onClick={() => handleModeChange('ambiguous')}
                            >
                                <FaTimes /> Ambiguous
                            </button>
                        </div>
                        <p className="settings-description">Strict mode generates a message on the second of your specified frequency. Ambiguous mode generates one within a time range of .5x and 1.5x the specified frequency.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiveAISettings;