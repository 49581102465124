// src/aboutpage/components/aboutMain.js

import React from 'react';
import '../styles/aboutMain.css';

const AboutMain = () => {
  return (
    <div className="about-main-container">
      <h2 className="about-main-title">About StreamChatAndy</h2>
      
      <div className="about-main-intro">
        <p>
          <strong>StreamChatAndy</strong> is designed to enhance live streaming and content creation through AI-driven, interactive chat simulations. Whether you're an established streamer or just starting out, StreamChatAndy adds an engaging, interactive experience to your live streams and recorded content.<strong> It's like viewbotting for your brain.</strong>
        </p>
        <p>
         Currently the subscription price is <strong>$9.99</strong> for month to use the AI Features and the saving feature on the backend -- subject to change.
        </p>
        <p>This is about 20 hours of live AI generation with default settings</p>
      </div>

      <div className="about-main-sections">
        
        <div className="about-main-section">
          <h3 className="section-heading">Who Can Benefit from StreamChatAndy?</h3>
          <p>
            StreamChatAndy is ideal for:
          </p>
          <ul className="benefit-list">
            <li><strong>Small or Growing Streamers</strong> – Enhance streams with lively chat interaction even with smaller audiences.</li>
            <li><strong>Content Creators</strong> – Simulate chat for recorded content, making it more engaging.</li>
            <li><strong>Podcasters & Live Streamers</strong> – Integrate an active chat overlay into OBS for a realistic audience experience.</li>
            <li><strong>Brands & Influencers</strong> – Simulate live events with interactive chat for greater engagement.</li>
          </ul>
        </div>
        
        <div className="about-main-section">
          <h3 className="section-heading">Main Features</h3>
          <div className="feature-section">
            <h4 className="feature-heading">Live Page</h4>
            <p>
              The Live Page integrates real-time Twitch chat with AI-generated messages, creating an immersive chat experience during broadcasts. Features include:
            </p>
            <ul className="feature-list">
              <li><strong>Twitch Integration</strong> – Merges real Twitch chat with AI-driven responses.</li>
              <li><strong>OBS Overlay</strong> – Pop-out chat window and overlay browser source link for OBS, allowing live chat overlays on streams.</li>
              <li><strong>AI Customization</strong> – Adjust frequency, tone, and mode of AI messages to match your stream’s mood.</li>
            </ul>
          </div>

          <div className="feature-section">
            <h4 className="feature-heading">Create Page</h4>
            <p>
              The Create Page allows users to build custom chat timelines for recorded content, simulating a chat environment. Key features include:
            </p>
            <ul className="feature-list">
              <li><strong>Timeline Management</strong> – Create a chat timeline with custom pacing and flow.</li>
              <li><strong>Playback & Recording</strong> – Test and record chat playback for video content.</li>
              <li><strong>Chatter Andy Customization</strong> – Personalize each "Chatter Andy" and each message for a unique chat experience.</li>
            </ul>
          </div>
        </div>

        <div className="about-main-section">
          <h3 className="section-heading">Future Plans for StreamChatAndy</h3>
          <ul className="feature-list">
            <li><strong>Integration with other platforms</strong> like YouTube and Kick</li>
            <li><strong>Advanced AI responses</strong> that react to real-time audio and video cues</li>
            <li><strong>Increased customization</strong> for chatters and conversation dynamics</li>
          </ul>
          <p>StreamChatAndy aims to be a comprehensive tool for creators seeking to engage audiences, whether live or in recorded sessions.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutMain;
