// frontend/src/components/chatPages/obsOverlay.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './obsOverlay.css';
import { sortBadges, badgeOptions } from '../LivePage/LivePageUtils';
import { validateOverlayUUID, checkOverlayStatus } from '../../services/obsApi';

const ObsOverlay = () => {
    const [messages, setMessages] = useState([]);
    const chatEndRef = useRef(null);
    const [overlayUUID, setOverlayUUID] = useState('');
    const [isListenerActive, setIsListenerActive] = useState(false);
    const [isAIActive, setIsAIActive] = useState(false);
    const isWebSocketOpenRef = useRef(false); // Ref to track WebSocket open status

    const twitchWebSocketRef = useRef(null);
    const aiWebSocketRef = useRef(null);

    // Handle incoming messages, ensuring the limit is 50
    const addNewMessage = useCallback((messageData) => {
        // // console.log("[addNewMessage] Received new message:", messageData);
        if (messageData.badge_ids && messageData.badge_ids.length > 0) {
            messageData.badge_urls = mapBadgeIdsToUrls(messageData.badge_ids);
        }

        setMessages((prevMessages) => {
            const newMessages = [...prevMessages, messageData];
            if (newMessages.length > 50) {
                newMessages.shift(); // Remove the first (oldest) message
            }
            // console.log("[addNewMessage] Updated messages list:", newMessages);
            return newMessages;
        });
    }, []);

    const mapBadgeIdsToUrls = (badgeIds) => {
        // console.log("[mapBadgeIdsToUrls] Mapping badge IDs:", badgeIds);
        const sortedBadgeIds = sortBadges(badgeIds);
        return sortedBadgeIds
            .map((id) => {
                const badge = Object.values(badgeOptions).flat().find((option) => option.id === id);
                return badge ? badge.imageUrl : null;
            })
            .filter((url) => url !== null);
    };

    useEffect(() => {
        // console.log("[validateAndSetUUID] Validating overlay UUID.");
        const validateAndSetUUID = async () => {
            const currentUUID = window.location.pathname.split('/').pop();
            setOverlayUUID(currentUUID);
            // console.log("[validateAndSetUUID] Current UUID:", currentUUID);

            try {
                const response = await validateOverlayUUID(currentUUID);
                // console.log("[validateAndSetUUID] UUID validation response:", response);
                if (!response.is_valid) {
                    alert('Invalid or outdated overlay URL. Please generate a new one.');
                    window.close();
                }
            } catch (error) {
                // console.error('[validateAndSetUUID] Error validating overlay UUID:', error);
                window.close();
            }
        };

        validateAndSetUUID();
    }, []);

    const connectToWebSocket = useCallback((url, connectionType) => {
        // // console.log(`[connectToWebSocket] Connecting to WebSocket: ${url} for ${connectionType}`);
        const ws = new WebSocket(url);
        ws.onopen = () =>  console.log(`[WebSocket] Connected`);
        ws.onmessage = (event) => {
            // console.log(`[WebSocket] Message received on ${url}:`, event.data);
            try {
                const messageData = JSON.parse(event.data);
                // console.log("[WebSocket] Parsed message data:", messageData);
        
                if (messageData.type === 'test_connection') {
                    // console.log("[WebSocket] Received test connection message. Ignoring.");
                    return;
                }
        
                addNewMessage(JSON.parse(event.data));
            } catch (error) {
                // console.error("[WebSocket] Error parsing WebSocket message:", error);
            }
        };

        ws.onclose = () => {
            // console.log(`[WebSocket] Closed connection for ${url}`);
            if (connectionType === 'twitch') twitchWebSocketRef.current = null;
            if (connectionType === 'ai') aiWebSocketRef.current = null;
            isWebSocketOpenRef.current = false;
        };

        ws.onerror = (err) => console.error(`[WebSocket] Error in connection for ${url}:`, err);

        if (connectionType === 'twitch') twitchWebSocketRef.current = ws;
        if (connectionType === 'ai') aiWebSocketRef.current = ws;
        isWebSocketOpenRef.current = true;
    }, [addNewMessage]);

    const checkActiveStatus = useCallback(async () => {
        // console.log("[checkActiveStatus] Checking listener and AI active status.");
        try {
            const response = await checkOverlayStatus(overlayUUID);
            // console.log("[checkActiveStatus] Active status response:", response);
            setIsListenerActive(response.is_listener_active);
            setIsAIActive(response.is_ai_active);
        } catch (error) {
            // console.error("[checkActiveStatus] Error fetching overlay status:", error);
        }
    }, [overlayUUID]);

    useEffect(() => {
        // console.log("[useEffect] Setting up interval for active status check.");
        const interval = setInterval(() => {
            // console.log("[useEffect] Interval check for active status.");
            checkActiveStatus();
        }, 30000);

        return () => {
            // console.log("[useEffect] Clearing interval.");
            clearInterval(interval);
        };
    }, [checkActiveStatus]);

    useEffect(() => {
        const initializeConnection = async () => {
            // console.log("[initializeConnection] Initializing WebSocket connections.");
            if (overlayUUID && (isAIActive || isListenerActive) && !isWebSocketOpenRef.current) {
                // console.log("[initializeConnection] Conditions met to open WebSockets.");

                const twitchUrl = `wss://api.streamchatandy.com/ws/chat/twitchWSgroup_${overlayUUID}/`;
                const aiUrl = `wss://api.streamchatandy.com/ws/chat/aiWSgroup_${overlayUUID}/`;

                if (!twitchWebSocketRef.current) {
                    // console.log("[initializeConnection] Connecting to Twitch WebSocket.");
                    connectToWebSocket(twitchUrl, 'twitch');
                }
                if (!aiWebSocketRef.current) {
                    // console.log("[initializeConnection] Connecting to AI WebSocket.");
                    connectToWebSocket(aiUrl, 'ai');
                }
            } 
        };

        initializeConnection();

        return () => {
            // console.log("[useEffect] Cleaning up WebSocket connections on unmount.");
            if (twitchWebSocketRef.current) {
                // console.log("[useEffect] Closing Twitch WebSocket on unmount.");
                twitchWebSocketRef.current.close();
                twitchWebSocketRef.current = null;
            }
            if (aiWebSocketRef.current) {
                // console.log("[useEffect] Closing AI WebSocket on unmount.");
                aiWebSocketRef.current.close();
                aiWebSocketRef.current = null;
            }
            isWebSocketOpenRef.current = false;
        };
    }, [overlayUUID, addNewMessage, isListenerActive, isAIActive, connectToWebSocket]);

    // Periodic check of WebSocket status and messages
    useEffect(() => {
        const interval = setInterval(() => {
            // console.log("[Periodic Check] WebSocket open status:", isWebSocketOpenRef.current);
            // console.log("[Periodic Check] Current messages array:", messages);
            // console.log("[Periodic Check] Twitch WebSocket:", twitchWebSocketRef.current);
            // console.log("[Periodic Check] AI WebSocket:", aiWebSocketRef.current);
        }, 10000); // Every 10 seconds

        return () => {
            clearInterval(interval);
        };
    }, [messages]);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ });
        }
    }, [messages]);

    return (
        <div className="overlay-chat-container" >
            <div className="overlay-chat-messages">
                {messages.map((message, index) => (
                    <div key={index} className="overlay-chat-message">
                        <div className="chat-line">
                            {message.badge_urls && message.badge_urls.map((url, idx) => (
                                <img key={idx} src={url} alt="badge" className="chat-badge-img" />
                            ))}
                            <span className="chat-username" style={{ color: message.chatter_color }}>
                                {message.chatter_name || 'Unknown'}
                            </span>
                            <span className="chat-colon">:</span>
                            <span className="chat-content" dangerouslySetInnerHTML={{ __html: message.message }} />
                        </div>
                    </div>
                ))}
                <div ref={chatEndRef} />
            </div>
        </div>
    );
};

export default ObsOverlay;