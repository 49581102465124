// frontend/src/services/sessionService.js
import api from './api';

// Function to check and log the Authorization header
const checkAuthHeader = () => {
  const authHeader = api.defaults.headers.common['Authorization'];
  if (!authHeader) {
    // console.error("sessionService: Authorization header is missing");
  } else {
    // console.log("sessionService: Authorization header found");
  }
};

// Function to generate multiple chatters
const generateChatters = (numberOfChatters) => {
  checkAuthHeader(); // Check authorization header before making the call
  return api.post('chat/chatters/generate/', { number: numberOfChatters })
    .then(res => res.data)
    .catch(error => {
      // console.error("Error generating chatters:", error);
      throw error;
    });
};

// Function to fetch chatters
const getChatters = () => {
  checkAuthHeader(); // Check authorization header before making the call
  return api.get('chat/chatters/')
    .then(res => res.data)
    .catch(error => {
      // console.error("Error fetching chatters:", error);
      throw error;
    });
};

// Function to create a new Chatter Andy
const createChatter = (chatterData) => {
  checkAuthHeader(); // Check authorization header before making the call
  return api.post('chat/chatters/', chatterData)
    .then(res => res.data)
    .catch(error => {
      // console.error("Error creating chatter:", error);
      throw error;
    });
};

// Function to delete a Chatter Andy
const deleteChatter = async (chatterId) => {
  checkAuthHeader(); // Check authorization header before making the call
  const chatter = await api.get(`chat/chatters/${chatterId}/`);
  if (chatter.data.username === 'Unassigned') {
    // console.log(`Attempted to delete 'Unassigned' chatter. Deletion prevented.`);
    return;
  }
  return api.delete(`chat/chatters/${chatterId}/`)
    .then(res => res.data)
    .catch(error => {
      // console.error(`Error deleting chatter ${chatterId}:`, error);
      throw error;
    });
};

// Function to update Chatter Andy
const updateChatter = (chatterId, chatterData) => {
  checkAuthHeader(); // Check authorization header before making the call
  return api.patch(`chat/chatters/${chatterId}/`, chatterData)
    .then(res => res.data)
    .catch(error => {
      // console.error(`Error updating chatter ${chatterId}:`, error);
      throw error;
    });
};

// Generate an AI message for a Chatter Andy
const generateMessage = (chatterId, custom_message, context, tone, reduxTwitchUsername) => {
  checkAuthHeader(); // Check authorization header before making the call
  return api.post('chat/chat_sessions/generate_message/', {
    chatter_id: chatterId,
    custom_message,
    tone,
    context,
    reduxTwitchUsername
  })
    .then(res => res.data)
    .catch(error => {
      // console.error(`Error generating message for chatter ${chatterId}:`, error);
      throw error;
    });
};


const generateBasicChatTimeline = (gen_timeline_length, gen_event_timings, chatters, streamer_name, customContext, messageRate, timeInterval) => {
  checkAuthHeader(); 
  return api.post('chat/basic_chat_generate/', {
    gen_timeline_length,
    gen_event_timings,
    chatters,  
    streamer_name,
    customContext,
    messageRate, 
    timeInterval
  }, { timeout: 300000 })
    .then(res => res.data)
    .catch(error => {
      console.error(`Error generating basic chat timeline:`, error);
      throw error;
    });
};

const fetch_basic_generate_TokenEstimate = (chatter_info_list, gen_timeline_length, gen_event_timings, streamer_name, customContext) => {
  checkAuthHeader(); 
  return api.get('user-tokens/basic_chat_token_estimate/', {
    params: {
      chatter_info_list: JSON.stringify(chatter_info_list), // Convert to JSON string
      gen_timeline_length,
      gen_event_timings: JSON.stringify(gen_event_timings), // Convert to JSON string
      streamer_name,
      customContext
    }
  })
    .then(res => res.data)
    .catch(error => {
      console.error('Error fetching token estimate:', error);
      throw error;
    });
};


const getTaskStatus = (taskId) => {
  checkAuthHeader();
  return api.get(`chat/tasks/status/${taskId}/`)
    .then(res => res.data)
    .catch(error => {
      console.error(`Error fetching task status:`, error);
      throw error;
    });
};

const createUnassignedChatter = () => {
  checkAuthHeader();
  return api.post('chat/chatters/create_unassigned/')
      .then(res => res.data)
      .catch(error => {
          // console.error('Error creating unassigned chatter:', error);
          throw error;
      });
};


const startTwitchListener = (twitchUsername) => {
  checkAuthHeader(); // Check authorization header before making the call

  return api.post('chat/twitch_listener/', { twitch_username: twitchUsername })
    .catch(error => {
      // console.error(`Error starting Twitch listener for ${twitchUsername}:`, error);
      throw error;
    });
};

const stopTwitchListener = (twitchUsername) => {
  checkAuthHeader(); // Check authorization header before making the call

  return api.delete('chat/twitch_listener/', { data: { twitch_username: twitchUsername } })
    .catch(error => {
      // console.error(`Error stopping Twitch listener for ${twitchUsername}:`, error);
      throw error;
    });
};
//basicChat
// Save a session
const saveSession = (sessionData, sessionName) => {
  checkAuthHeader();
  return api.post('chat/saved_sessions/', {
    session_data: sessionData,  // Pass the entire Redux state slice
    session_name: sessionName   // Optional name for the session
  })
    .then(res => res.data)
    .catch(error => {
      // console.error("Error saving session:", error);
      throw error;
    });
};

// Load all saved sessions for the authenticated user
const loadSessions = () => {
  checkAuthHeader();
  return api.get('chat/saved_sessions/')
    .then(res => res.data)
    .catch(error => {
      // console.error("Error loading sessions:", error);
      throw error;
    });
};

// Load a specific saved session by ID
const loadSessionById = (sessionId) => {
  checkAuthHeader();
  return api.get(`chat/saved_sessions/${sessionId}/`)
    .then(res => res.data)
    .catch(error => {
      // console.error(`Error loading session ${sessionId}:`, error);
      throw error;
    });
};

// Delete a session
const deleteSession = (sessionId) => {
  checkAuthHeader();
  return api.delete(`chat/saved_sessions/${sessionId}/`)
    .then(res => res.data)
    .catch(error => {
      // console.error(`Error deleting session ${sessionId}:`, error);
      throw error;
    });
};

// Function to get the token balance for the authenticated user
const getTokenBalance = () => {
  checkAuthHeader();
  return api.get('usertokens/token_balance/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error fetching token balance:', error);
      throw error;
    });
};

// Function to get the subscription status for the authenticated user
const getSubscriptionStatus = () => {
  checkAuthHeader(); // Ensure token is added to the request
  return api.get('subscriptionstatus/subscription_status/') 
    .then(res => res.data)
    .catch(error => {
      // console.error('Error fetching subscription status:', error);  
      throw error;
    });
};

// Update POST URL in setSubscriptionStatus function too
const setSubscriptionStatus = (isPaidSubscriber) => {
  checkAuthHeader();
  return api.post('subscriptionstatus/subscription_status/', { is_paid_subscriber: isPaidSubscriber })  
    .then(res => res.data)
    .catch(error => {
      // console.error('Error setting subscription status:', error);
      throw error;
    });
};

// Set the token balance for the authenticated user
const setTokenBalance = (tokenBalance) => {
  checkAuthHeader();
  return api.post('usertokens/set_token_balance/', { token_balance: tokenBalance })
    .then(res => res.data)
    .catch(error => {
      // console.error('Error setting token balance:', error);
      throw error;
    });
};

//twitch
// Function to connect Twitch OAuth and store connection info
const connectTwitch = (twitchData) => {
  checkAuthHeader();  // Ensure Auth0 token is attached to headers

  // Post Twitch OAuth token as part of the payload, not headers
  return api.post('/twitch_connection/connect_twitch/', twitchData)
    .then(res => res.data)
    .catch(error => {
      // console.error('Error connecting Twitch:', error);
      throw error;
    });
};

// Function to check if the bot is modded in the user's Twitch channel
const checkBotModStatus = () => {
  checkAuthHeader(); // Ensure Authorization header is set
  return api.get('/twitch_connection/check_mod_status/')
      .then(res => {
          // Return mod status, username, and channel ID
          return {
              is_bot_modded: res.data.is_bot_modded,
              twitch_username: res.data.twitch_username,
              twitch_channel_id: res.data.twitch_channel_id
          };
      })
      .catch(error => {
          // console.error('Error checking bot mod status:', error);
          throw error;
      });
};

const checkOauthStatus = () => {
  checkAuthHeader();  // Ensure Auth0 token is attached to headers
  return api.get('/twitch_connection/check_oauth_status/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error checking OAuth status:', error);
      throw error;
    });
};


const getOverlayUUID = () => {
  checkAuthHeader();
  return api.get('subscriptionstatus/overlay_uuid/')
      .then(res => res.data)
      .catch(error => {
          // console.error('Error fetching overlay UUID:', error);
          throw error;
      });
};

const regenerateOverlayUUID = () => {
  checkAuthHeader();
  return api.post('subscriptionstatus/regenerate_overlay_uuid/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error generating new overlay UUID:', error);
      throw error;
    });
};

const validateOverlayUUID = (uuid) => {
  return api.get(`subscriptionstatus/overlay_uuid/?uuid=${uuid}`)
    .then(res => res.data)
    .catch(error => {
      // console.error('Error validating overlay UUID:', error);
      throw error;
    });
};

const fetchOverlayUUID = () => {
  checkAuthHeader(); // Ensure the Authorization header is set
  return api.get('subscriptionstatus/fetch_overlay_uuid/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error fetching overlay UUID:', error);
      throw error;
    });
};

const createCheckoutSession = () => {
  checkAuthHeader();
  return api.post('stripe/create-checkout-session/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error creating checkout session:', error);
      throw error;
    });
};

// Function to create a Stripe Customer Portal session
const createCustomerPortalSession = () => {
  checkAuthHeader();
  return api.post('stripe/create-customer-portal-session/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error creating customer portal session:', error);
      throw error;
    });
};

const fetchStripeSubscription = () => {
  checkAuthHeader();
  return api.get('stripe/get-subscription/')
    .then(res => res.data)
    .catch(error => {
      // console.error('Error fetching Stripe subscription:', error);
      throw error;
    });
}


const fetchTokenEstimate = (context) => {
  checkAuthHeader();
  return api.get('user-tokens/token_estimate/', {
    params: { context } 
  })
    .then(res => res.data)
    .catch(error => {
      // console.error('Error fetching token estimate:', error);
      throw error;
    });
};


const deactivate_ai = () => {
  checkAuthHeader();
  return api.post('chat/chat_sessions/deactivate_ai/')
    .then(res => res.data)
    .catch(error => {
      // console.error("Error stopping AI generation:", error);
      throw error;
    });
};

const activate_ai = () => {
  checkAuthHeader();
  return api.post('chat/chat_sessions/activate_ai/')
    .then(res => res.data)
    .catch(error => {
      // console.error("Error stopping AI generation:", error);
      throw error;
    });
};

const getListenerStatus = () => {
  checkAuthHeader();  // Ensure Auth0 token is attached to headers
  return api.get('chat/twitch_listener/')  // GET request to check listener status
    .then(res => res.data.is_listener_active)  // Returns true/false for is_listener_active
    .catch(error => {
      // console.error('Error fetching listener status:', error);
      throw error;
    });
};




const sessionService = {
  generateChatters,
  getChatters,
  deleteChatter,
  updateChatter,
  generateMessage,
  createChatter,
  createUnassignedChatter,
  startTwitchListener,
  stopTwitchListener,
  saveSession,
  loadSessions,
  loadSessionById,
  deleteSession,
  getTokenBalance,
  getSubscriptionStatus,
  setSubscriptionStatus,
  setTokenBalance,
  connectTwitch,
  checkBotModStatus,
  checkOauthStatus,
  getOverlayUUID,
  regenerateOverlayUUID,
  validateOverlayUUID,
  fetchOverlayUUID,
  createCheckoutSession,
  createCustomerPortalSession,
  fetchStripeSubscription,
  fetchTokenEstimate,
  deactivate_ai,
  getListenerStatus,
  activate_ai,
  generateBasicChatTimeline,
  fetch_basic_generate_TokenEstimate,
  getTaskStatus,
};

export default sessionService;
