// frontend/src/redux/redcuers/TwitchReducer.js

import { ADD_TWITCH_MESSAGE, CLEAR_TWITCH_MESSAGES } from '../actions/types';

const initialState= {
    twitchMessages: []
};

const twitchMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TWITCH_MESSAGE: {
            // Add the new message and ensure the max limit is 200 messages
            const updatedMessages = [...state.twitchMessages, action.payload];

            // If the number of messages exceeds 200, remove the oldest one
            if (updatedMessages.length > 200) {
                updatedMessages.shift(); // Remove the first (oldest) message
            }

            return {
                ...state,
                twitchMessages: updatedMessages
            };
        }
        case CLEAR_TWITCH_MESSAGES:
            return {
                ...state,
                twitchMessages: []
            };

        default:
            return state;
    }
};

export default twitchMessageReducer;