// frontend/src/hooks/useCanAccessSubFeat.js

import { useSelector } from 'react-redux';

// Custom hook to check if the user can access subscription-based features
const useCanAccessSubFeat = () => {
  const isPaidSubscriber = useSelector((state) => state.auth.isPaidSubscriber);
  const tokenBalance = useSelector((state) => state.auth.tokenBalance);

  // Can access features requiring tokens, like AI generation
  const canAccessTokenFeat = () => {
      return isPaidSubscriber && tokenBalance > 0;
  };

  // Can access pages limited to subscribers, like LivePage
  const canAccessSubPage = () => {
    console.log("isPaidSubscriber:", isPaidSubscriber);  // Log the subscription status
    return isPaidSubscriber;
};

  // Allow page access but restrict save functionality on the CreatePage
  const canSaveSessions = () => {
      return isPaidSubscriber;
  };

  return {
      canAccessTokenFeat,
      canAccessSubPage,
      canSaveSessions,
  };
};

export default useCanAccessSubFeat;
