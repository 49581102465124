// frontend/src/basicChatPage/components/basicChatTimeblock.js
import React from 'react';
import '../styles/basicChatTimeblock.css'; 

const BasicChatTimeblock = ({ display_time, duration }) => {
    return (
        <div className="time-block">  
            <div>
                No messages for {duration} second(s)
            </div>
        </div>
    );
};

export default BasicChatTimeblock;


