// src/aboutpage/components/aboutCreate.js

import React from 'react';
import '../styles/aboutCreate.css';

const AboutCreate = () => {
  return (
    <div className="about-create-container">
      <h2 className="about-create-title">Using the Create Page</h2>
      <div className="about-create-content">
        <div className="video-container-about">
          <iframe
            width="719"
            height="408"
            src="https://www.youtube.com/embed/zZ_WT17a6Oc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      
      <div className="about-create-content">
        <div className="create-section">
          <h3 className="section-heading">Generation</h3>
          <p>
            Watch the video to see a quick demo showcasing the features
          </p>
        </div>

        <div className="create-section">
          <h3 className="section-heading">Manual Create</h3>
          <p>
            Begin by setting up your chat participants, called "Chatter Andys."  To add a new chatter, click <strong>Create Chatter Andy</strong> at the bottom of the left menu. All created chatters appear in a list, where you can edit or delete them as needed.
          </p>


          <h3 className="section-heading">Building the Message Timeline</h3>
          <p>
            With your Chatter Andys set up, navigate to the main timeline area to start scripting the chat. Select a chatter from the dropdown menu, type the desired message, and click <strong>Add Message</strong>. 
          </p>
          <p>Control the pacing by setting a delay using the custom or preset time buttons (e.g., +1 sec, +5 sec), defining when the next message appears. The timeline displays messages in sequence, with options to edit or remove each entry for precise control over the chat flow.</p>



          <h3 className="section-heading">Playback Simulation</h3>
          <p>
            Once your chat timeline is ready, preview it by using the <strong>Play</strong> and <strong>Stop</strong> buttons at the bottom of the chat window. The popout window will play, pause,. or stop from the buttons on the dashboard.
          </p>
        </div>
        </div>
        </div>
  );
};

export default AboutCreate;