// frontend/src/basicChatPage/redux/basicChatRootReducer.js
import { combineReducers } from 'redux';
import basicChatMessageReducer from './basicChatMessageReducer';
import basicChatChatterReducer from './basicChatChatterReducer';
import basicChatPlaybackReducer from './basicChatPlaybackReducer';
import basicChatSessionReducer from './basicChatSessionReducer';


const basicChatRootReducer = combineReducers({
    messages: basicChatMessageReducer,
    chatters: basicChatChatterReducer,
    playback: basicChatPlaybackReducer,
    sessions: basicChatSessionReducer,
});

export default basicChatRootReducer;