//src/basicChatPage/generation/basiChatGeneration.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sessionService from '../../services/sessionService';
import { getAllChatters } from '../redux/basicChatSelectors';
import { addMessage, clearMessages, updateTimelineLength } from '../redux/basicChatMessageActions';
import { getTokenBalance } from '../../redux/actions/authActions';
import '../styles/basicChatGeneration.css';

const BasicChatGeneration = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [timelineLength, setTimelineLength] = useState(0);
    const [eventTimings, setEventTimings] = useState([{ display_time: '', event_string: '' }]);
    const [streamer_name, setStreamerName] = useState('StreamerName');
    const [customContext, setCustomContext] = useState('');
    const [tokenEstimate, setTokenEstimate] = useState(null);
    const [saved, setSaved] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedMinutes, setEditedMinutes] = useState(0);
    const [editedSeconds, setEditedSeconds] = useState(0);
    const [timelineMinutes, setTimelineMinutes] = useState(0);
    const [timelineSeconds, setTimelineSeconds] = useState(0);
    const tokenBalance = useSelector((state) => state.auth.tokenBalance);
    const [rawMessageRate, setMessageRate] = useState(1);
    const [isEditingTimeline, setIsEditingTimeline] = useState(false);
    const [rawTimeInterval, setTimeInterval] = useState(10);
    const chatters = useSelector(getAllChatters);
    const [isGenerating, setIsGenerating] = useState(false);


    useEffect(() => {
        dispatch(getTokenBalance());
        
    }, [dispatch, tokenBalance]);

    const parseAndDispatchMessages = (response, dispatch) => {
        try {
            const parsedResponse = JSON.parse(response.result.result); // Note the double .result to access the nested result
            parsedResponse.messageList.forEach(message => {
                dispatch(addMessage(message));
                //console.log('Adding message:', message);
            });
            console.log('Parsed response:', parsedResponse);
            const maxDisplayTime = Math.max(...parsedResponse.messageList.map(message => message.display_time));
            dispatch(updateTimelineLength(maxDisplayTime + 1)); 
            //console.log('Max display time:', maxDisplayTime);
            setIsGenerating(false);
        } catch (error) {
            console.error('Error parsing and dispatching messages:', error);
            setIsGenerating(false);
        }
    };

    const pollTaskStatus = async (taskId) => {
        try {
            const result = await sessionService.getTaskStatus(taskId);
            if (result.status === 'SUCCESS') {
                parseAndDispatchMessages(result, dispatch);
                setResponse(result);
                setError(null);
                dispatch(getTokenBalance());
            } else if (result.status === 'PENDING' || result.status === 'STARTED') {
                setTimeout(() => pollTaskStatus(taskId), 5000); // Poll every 2 seconds
            } else {
                setError('Task failed');
                setIsGenerating(false);
            }
        } catch (err) {
            setError(err.message);
            setIsGenerating(false);
        }
    };

    const gcd = (a, b) => {
        if (!b) {
            return a;
        }
        return gcd(b, a % b);
    };
    const normalizeMessageRateAndInterval = (rate, interval) => {
        const divisor = gcd(rate, interval);
        return {
            messageRate: rate / divisor,
            timeInterval: interval / divisor
        };
    };


    const handleTestCall = async () => {
        const { messageRate, timeInterval } = normalizeMessageRateAndInterval(rawMessageRate, rawTimeInterval);
        dispatch(clearMessages());
        setIsGenerating(true);
        
        try {
            const gen_timeline_length = timelineLength; // Use timeline length from state
            const gen_event_timings = eventTimings.map(event => ({
                display_time: parseInt(event.display_time, 10),
                event_string: event.event_string || 'No content' // Ensure event_string is not blank
            })); // Extract event timings from messages

            
            console.log('Normalized message rate:', messageRate)
            console.log('Normalized time interval:', timeInterval);
    
            const result = await sessionService.generateBasicChatTimeline(gen_timeline_length, gen_event_timings, chatters, streamer_name, customContext, messageRate, timeInterval);
            setResponse(result);
            setError(null);
            
    
            // Poll the task status
            pollTaskStatus(result.task_id);
        } catch (err) {
            setError(err.message);
            setResponse(null);
            setIsGenerating(false);
        }
    };

    const handleSave = async () => {
        try {
            const gen_timeline_length = timelineLength; // Use timeline length from state
            const gen_event_timings = eventTimings.map(event => ({
                display_time: parseInt(event.display_time, 10),
                event_string: event.event_string || 'No content' // Ensure event_string is not blank
            })); // Extract event timings from messages
            
            const estimate = await sessionService.fetch_basic_generate_TokenEstimate(chatters, gen_timeline_length, gen_event_timings, streamer_name, customContext);
            estimate.estimated_cost = Math.round(estimate.estimated_cost);
            setTokenEstimate(estimate);
            setError(null);
            setSaved(true); // Mark as saved
        } catch (err) {
            setError(err.message);
            setTokenEstimate(null);
        }
    };

    const handleTimelineMinutesChange = (e) => {
        const minutes = Math.max(0, Math.min(5, parseInt(e.target.value, 10))); // Cap minutes at 5
        const totalSeconds = (minutes * 60) + timelineSeconds;
        if (totalSeconds <= 300) { // Ensure total timeline length does not exceed 300 seconds
            setTimelineMinutes(minutes);
            setTimelineLength(totalSeconds);
        } else {
            setTimelineMinutes(5);
            setTimelineSeconds(0);
            setTimelineLength(300);
        }
    };
    

    const handleTimelineSecondsChange = (e) => {
        const seconds = Math.max(0, Math.min(59, parseInt(e.target.value, 10)));
        const totalSeconds = (timelineMinutes * 60) + seconds;
        if (totalSeconds <= 300) { // Ensure total timeline length does not exceed 300 seconds
            setTimelineSeconds(seconds);
            setTimelineLength(totalSeconds);
        } else {
            setTimelineMinutes(5);
            setTimelineSeconds(0);
            setTimelineLength(300);
        }
    };


    const handleUsernameChange = (e) => {
        setStreamerName(e.target.value);
    };

    const handleEventTimingChange = (index, field, value) => {
        const newEventTimings = [...eventTimings];
        newEventTimings[index][field] = value;
        setEventTimings(newEventTimings);
    };

    const handleAddEventTiming = () => {
        setEventTimings([...eventTimings, { display_time: '', event_string: '' }]);
        setEditingIndex(eventTimings.length);
        
    };

    const handleRemoveEventTiming = (index) => {
        const newEventTimings = eventTimings.filter((_, i) => i !== index);
        setEventTimings(newEventTimings);
    };
    
    // Helper function to format seconds to mm:ss
    const formatSecondsToMMSS = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSaveEdit = (index) => {
        const totalSeconds = (editedMinutes * 60) + editedSeconds;
        handleEventTimingChange(index, 'display_time', totalSeconds);
        setEditingIndex(null);
    };

    const handleEditTimeline = () => {
        setTimelineMinutes(Math.floor(timelineLength / 60));
        setTimelineSeconds(timelineLength % 60);
        setIsEditingTimeline(true);
    };
    

    const handleEditEventTiming = (index) => {
        setEditingIndex(index);
        const event = eventTimings[index];
        setEditedMinutes(Math.floor(event.display_time / 60));
        setEditedSeconds(event.display_time % 60);
    };

    const handleCustomContextChange = (e) => {
        if (e.target.value.length <= 512) {
            setCustomContext(e.target.value);
        }
      };

      const handleMessageRateChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= 1 && value <= getMaxMessagesForInterval(rawTimeInterval)) {
            setMessageRate(value);
        }
    };

    const handleTimeIntervalChange = (newInterval) => {
        setTimeInterval(newInterval);
        setMessageRate(getDefaultMessagesForInterval(newInterval));
    };

    const getMaxMessagesForInterval = (interval) => {
        switch (interval) {
            case 10:
                return 5;
            case 30:
                return 15;
            case 60:
                return 30;
            default:
                return 10;
        }
    };

    const getDefaultMessagesForInterval = (interval) => {
        switch (interval) {
            case 10:
                return 1;
            case 30:
                return 10;
            case 60:
                return 15;
            default:
                return 1;
        }
    };


    return (
        <div className="basic-chat-generation">
            <div className="basic-chat-generation-input-group">

                <label className="basic-chat-generation-label">
                    Timeline Length:
                    <p> Currently 5 minutes MAX for stability</p>                   
                        <div className="time-edit-inline">
                            <label className="time-label-gen">Min:</label>
                            <input
                                type="number"
                                value={timelineMinutes}
                                onChange={handleTimelineMinutesChange}
                                className="form-control no-arrows time-input"
                            />
                            <label className="time-label">Sec:</label>
                            <input
                                type="number"
                                value={timelineSeconds}
                                onChange={handleTimelineSecondsChange}
                                className="form-control no-arrows time-input"
                            />
                        </div>
                </label>
            </div>
            <div className="basic-chat-generation-input-group">
                <label className="basic-chat-generation-label">
                    Streamer Name:
                    <div>
                        <input
                            type="text"
                            value={streamer_name}
                            onChange={handleUsernameChange}
                            className="basic-chat-generation-input-name"
                        />
                    </div>
                </label>
            </div>
            <div className="basic-chat-generation-input-group">
                <label className="basic-chat-generation-label">
                    Average Message Rate:
                    <div>
                        <input
                            type="number"
                            value={rawMessageRate}
                            onChange={handleMessageRateChange}
                            className="basic-chat-generation-message-rate"
                            min="1"
                            max={getMaxMessagesForInterval(rawTimeInterval)}
                        />
                        <span>message(s) for every</span>
                        <select
                            value={rawTimeInterval}
                            onChange={(e) => handleTimeIntervalChange(parseInt(e.target.value, 10))}
                            className="basic-chat-generation-message-rate"
                        >
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={60}>60</option>
                        </select>
                        <span> seconds.</span>

                    </div>
                </label>
            </div>
            <div className="basic-chat-generation-input-group">
                <label className="basic-chat-generation-label">Custom Context about the stream (512 characters max):</label>
                <div>
                <textarea
                    className="basic-chat-generation-input-context"
                    value={customContext}
                    onChange={handleCustomContextChange}
                    maxLength="512"
                    rows="2"
                />
                </div>
                <label className="basic-chat-generation-label">
            Token Balance: <span className="token-value">{tokenBalance}</span>
            {tokenEstimate && (
                <div className="basic-chat-generation-token-estimate">
                    <h2 className="basic-chat-generation-subtitle">Token Estimate:</h2>
                    <p>Estimated Token Cost: {tokenEstimate.estimated_cost}</p>
                </div>
            )}
            </label>
            </div>
            <div className="basic-chat-generation-event-timings">
                <h2 className="basic-chat-generation-subtitle">Event Timings</h2>
                {eventTimings.map((event, index) => (
                    <div key={index} className="basic-chat-generation-event">
                        {editingIndex === index ? (
                            <div className="editing-section">
                                <div className="time-edit-inline">
                                    <label className="time-label">Min:</label>
                                    <input
                                        type="number"
                                        value={editedMinutes}
                                        onChange={(e) => setEditedMinutes(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                                        className="form-control no-arrows time-input"
                                    />
                                    <label className="time-label">Sec:</label>
                                    <input
                                        type="number"
                                        value={editedSeconds}
                                        onChange={(e) => setEditedSeconds(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                                        className="form-control no-arrows time-input"
                                    />
                                </div>
                                <label className="basic-chat-generation-label">
                                    <input
                                        type="text"
                                        value={event.event_string}
                                        onChange={(e) => handleEventTimingChange(index, 'event_string', e.target.value)}
                                        className="basic-chat-generation-input"
                                    />
                                </label>
                                <button onClick={() => handleSaveEdit(index)} className="btn-primary">Save</button>
                                <button onClick={() => handleRemoveEventTiming(index)} className="btn btn-sm btn-danger delete-button">Remove</button>
                            </div>
                        ) : (
                            <div className="message-display">
                                <div className="message-content-wrapper-generate">
                                <button onClick={() => handleEditEventTiming(index)} className="btn btn-sm btn-primary">Edit</button>
                                    <span className="chat-time">{formatSecondsToMMSS(event.display_time)}</span>
                                    <span className="message-content"> {event.event_string}</span>
                                    
                                </div>
                                <button onClick={() => handleRemoveEventTiming(index)} className="btn btn-sm btn-danger delete-button">Remove</button>
                            </div>
                        )}
                    </div>
                ))}
                <div className="basic-chat-generation-buttons">
                    <button onClick={handleAddEventTiming} className="btn btn-sm btn-primary add">Add Event Timing</button>
                </div>
            </div>

            <div className="basic-chat-generation-bottom-buttons">
                <button onClick={handleSave} className="btn btn-sm btn-primary estimate" disabled={isGenerating} >Estimate Token Cost</button>
                <button onClick={handleTestCall} disabled={!saved || isGenerating} className="btn btn-sm btn-primary test">
                    {isGenerating ? 'Generating... Please Wait' : 'Generate Basic Chat Timeline'}
                </button>
            </div>
        </div>
    );
};

export default BasicChatGeneration;