// frontend/src/components/LivePage/LiveControls.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Button, Form } from 'react-bootstrap';
import { triggerChatEvent, addContextItem, removeContextItem, startLiveSession, stopLiveSession } from '../../redux/actions/LiveSessionActions';
import sessionService from '../../services/sessionService';
import { addTwitchMessage } from '../../redux/actions/TwitchActions';
import { getTokenBalance, checkTwitchOauthStatus } from '../../redux/actions/authActions';
import { useAuth0 } from '@auth0/auth0-react';
import LiveAISettings from './LiveAISettings';
import { FaSyncAlt } from 'react-icons/fa';
import TwitchOAuthButton, { handleTwitchOAuth } from './TwitchOauthButton'; 
import { setAuthToken } from '../../services/api';
import './LiveControls.css';

const LiveControls = ({ liveSession, navigate }) => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const tokenBalance = useSelector(state => state.auth.tokenBalance);
    const reduxTwitchUsername = useSelector(state => state.auth.twitch_username);
    const isOauthConnected = useSelector(state => state.auth.twitch_oauth_status);
    const overlayUUID = useSelector(state => state.auth.overlayUUID);
    const contextQueue = useSelector(state => state.liveSessionReducer.context);
    const heartbeatIntervalRef = useRef(null);
    const [contextInput, setContextInput] = useState('');
    const [twitchListening, setTwitchListening] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [twitchUsername, setTwitchUsername] = useState('');
    const [isBotModded, setIsBotModded] = useState(false);
    const [isEditingTwitchUsername, setIsEditingTwitchUsername] = useState(false);
    const twitchWSgroupRef = useRef(null);
    
    const reconnectIntervalRef = useRef(null);
    const [isCheckingModStatus, setIsCheckingModStatus] = useState(false);
    
    useEffect(() => {
        const initLiveControls = async () => {
            const token = await getAccessTokenSilently();
            setAuthToken(token);
            setTwitchUsername(reduxTwitchUsername);

        };    
        initLiveControls();
        
        // Cleanup function to run on unmount
        return () => {
            if (twitchWSgroupRef.current) {
                twitchWSgroupRef.current.close(1000, "Component unmounted");  // Clean WebSocket
                twitchWSgroupRef.current = null;
                sessionService.stopTwitchListener(reduxTwitchUsername)
                
            }
            clearInterval(heartbeatIntervalRef.current);  // Clear heartbeat interval
        };
    }, [getAccessTokenSilently, reduxTwitchUsername]);

    useEffect(() => {        
        return () => {
           if (liveSession.isLive) {
            dispatch(stopLiveSession());
            sessionService.deactivate_ai();
           }
        }
    }, [liveSession.isLive, dispatch ]);

    // WebSocket connection with reconnect interval
    const connectWebSocket = useCallback(() => {
        if (overlayUUID && twitchListening && !isConnected) {
            const twitchUrl = `wss://api.streamchatandy.com/ws/chat/twitchWSgroup_${overlayUUID}/`;
    
            const ws = new WebSocket(twitchUrl);
            ws.onopen = () => {
                // console.log("[LiveControls] WebSocket connection established.");
                setIsConnected(true);
    
                // Start sending heartbeats every 30 seconds
                heartbeatIntervalRef.current = setInterval(() => {
                    if (twitchWSgroupRef.current && twitchWSgroupRef.current.readyState === WebSocket.OPEN) {
                        twitchWSgroupRef.current.send(JSON.stringify({ type: 'heartbeat' }));
                        // console.log("[LiveControls] Heartbeat sent.");
                    }
                }, 30000);
            };
    
    
            ws.onmessage = (event) => {
                try {
                    const messageData = JSON.parse(event.data);
                            
                    if (messageData.type === 'test_connection') {
                        // console.log("[LiveControls] Received test connection message. Ignoring.");
                        return; 
                    }

                    dispatch(addTwitchMessage({
                        chatter_name: messageData.chatter_name || 'Unknown',
                        message: messageData.message || '',
                        chatter_color: messageData.chatter_color || '#000FFF',
                        timestamp: new Date().toISOString(),
                        badge_urls: messageData.badge_urls || []
                    }));
                    // console.log("[LiveControls] Message received and dispatched:", messageData);
                } catch (error) {
                    // console.error("[LiveControls] Error parsing WebSocket message:", error);
                }
            };
    
            ws.onclose = (event) => {
                // console.log("[LiveControls] WebSocket connection closed.");
                setIsConnected(false);
                twitchWSgroupRef.current = null;
                clearInterval(heartbeatIntervalRef.current);

                // Attempt reconnection after a delay if it wasn't a manual close
                if (event.code !== 1000) {
                    setTimeout(() => {
                        if (twitchListening) connectWebSocket();
                    }, 2000);
                }
            };
    
            ws.onerror = (error) => {
                // console.error("[LiveControls] WebSocket error:", error);
                ws.close(); // Close WebSocket on error to trigger reconnect
            };
    
            twitchWSgroupRef.current = ws;
        } else if (!twitchListening && twitchWSgroupRef.current) {
            // Close WebSocket if listener is off
            twitchWSgroupRef.current.close(1000, "Listener toggled off");
            twitchWSgroupRef.current = null;
        }
    }, [overlayUUID, twitchListening, isConnected, dispatch]);

    // Toggle Twitch listener
    const handleTwitchToggle = () => {
        if (!twitchListening) {
            sessionService.startTwitchListener(twitchUsername)
                .then(() => {
                    // console.log("Twitch listener started.");
                    setTwitchListening(true);
                    connectWebSocket();
                })
                .catch((error) => console.error('Error starting listener:', error));
        } else {
            // Optimistically set twitchListening to false before stopping listener
            setTwitchListening(false);
    
            sessionService.stopTwitchListener(twitchUsername)
                .then(() => {
                    // console.log("Twitch listener stopped.");
                    if (twitchWSgroupRef.current) {
                        twitchWSgroupRef.current.close(1000, "Listener toggled off");
                        twitchWSgroupRef.current = null;
                    }
                })
                .catch((error) => {
                    // console.error('Error stopping listener:', error);
                    // Optionally re-enable the button or set up a retry mechanism here
                });
        }
    };
    // OAuth and bot mod status
    const handleRefreshModStatus = useCallback(() => {
        if (!isOauthConnected) return;

        setIsCheckingModStatus(true);
        sessionService.checkBotModStatus()
            .then((response) => {
                setIsBotModded(response.is_bot_modded);
                setTwitchUsername(response.twitch_username);
            })
            .catch((error) => {
                // console.error('Error checking bot mod status:', error);
            })
            .finally(() => {
                setIsCheckingModStatus(false);
            });
    }, [isOauthConnected]);

    useEffect(() => {
        dispatch(checkTwitchOauthStatus());


        return () => {
            if (twitchWSgroupRef.current) twitchWSgroupRef.current.close();
            clearInterval(heartbeatIntervalRef.current);  // Clear heartbeat interval on unmount
        };
    }, [dispatch]);

    useEffect(() => {
        if (isOauthConnected) handleRefreshModStatus();
    }, [isOauthConnected, handleRefreshModStatus]);

    useEffect(() => {
        dispatch(getTokenBalance());
        
    }, [dispatch]);

    useEffect(() => {
        if (twitchListening && !isConnected) {
            connectWebSocket();
        }
    }, [twitchListening, isConnected, connectWebSocket]);

    // Additional handler functions
    const handleTwitchUsernameChange = (e) => setTwitchUsername(e.target.value);
    const toggleTwitchUsernameEdit = () => {
        if (reduxTwitchUsername === 'brentroyale') {
            setIsEditingTwitchUsername(!isEditingTwitchUsername);
        }
    };
    const handleStartLiveSession = () => {
        dispatch(startLiveSession());
        sessionService.activate_ai();
    };
    const handleStopSession = () => {
        dispatch(stopLiveSession());
        sessionService.deactivate_ai();
    };

    const handleAddContext = () => {
        if (contextInput.trim()) {
            dispatch(addContextItem({ text: contextInput, timestamp: new Date().toISOString() }));
            setContextInput('');
        }
    };

    const handleRemoveContext = (index) => {
        dispatch(removeContextItem(index));
    };

    const handleEventTrigger = (eventType) => {
        dispatch(triggerChatEvent(eventType));
    };

    return (
        <div className="live-controls">
        <Tabs defaultActiveKey="session" id="live-controls-tabs" className="mb-3">
            
            {/* Second Tab: Chat Control */}
            <Tab eventKey="session" title="Chat Control">
                <div className="control-sections">
                    {/* AI Messages Section */}
                    <div className="control-column">
                        <h4 className="control-header">AI Messages</h4>
                        <p className="control-description">
                            Start and stop AI-generated messages for this session.
                        </p>
                        <Button
                            variant="success"
                            onClick={handleStartLiveSession}
                            disabled={liveSession.isLive || tokenBalance <= 0}  // Check both isLive and token balance
                            className="mt-2"
                        >
                            Start AI Messages
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleStopSession}
                            disabled={!liveSession.isLive || tokenBalance <= 0}  // Check both isLive and token balance
                            className="mt-2"
                        >
                            Stop AI Messages
                        </Button>
                        <div>
                            <LiveAISettings liveSession={liveSession} />
                        </div>
                    </div>

                    {/* Twitch Listener Section */}
                    <div className="control-column">
                        <h4 className="control-header">Twitch Listener</h4>
                        <p className="control-description">
                            Start and stop listening to your Twitch channel's chat. 
                        </p>
                        <Button variant={twitchListening ? 'danger' : 'twitch-listen'}
                            onClick={handleTwitchToggle}
                            className="mt-3 twitch-listen"
                            disabled={!isOauthConnected  || !isBotModded} // Disable if Twitch isn't connected or bot not modded
                        >
                            {twitchListening ? 'Stop Listening to Twitch' : 'Start Listening to Twitch'}
                        </Button>                            
                        {isEditingTwitchUsername ? (
                            <Form.Control
                                type="text"
                                value={twitchUsername}
                                onChange={handleTwitchUsernameChange}
                                onBlur={toggleTwitchUsernameEdit}
                                className="twitch-username-input"
                            />
                        ) : (
                            
                    <div className="twitch-username-display" onClick={toggleTwitchUsernameEdit}>
                        {twitchUsername}
                    </div>
                        )}
                     <div>
                        {/* OAuth Permissions Section */}
                        <h4>Get OAuth Permissions</h4>
                     {isOauthConnected ? (
                                <>
                                    <p>Connected as: {twitchUsername}</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Form.Check
                                            type="checkbox"
                                            label="OAuth Permissions Granted"
                                            checked={isOauthConnected}
                                            readOnly
                                        />
                                        <FaSyncAlt
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={handleTwitchOAuth}  // Reuse handleTwitchOAuth here
                                            title="Reconnect OAuth"
                                        />


                                    </div>
                                </>
                            ) : (
                                <TwitchOAuthButton />
                            )}
                        {/* Display further instructions and mod status */}
                        <p className="twitch-mod-status">Give us permission to read your chat & check your mod list</p>
                        


                        {/* Mod Bot in Channel Section */}
                        <h4>Mod 'MrFerretAi' in Chatbot</h4>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Check
                        type="checkbox"
                        label={`Mod 'MrFerretAi' in Channel: ${twitchUsername}`}
                        checked={isBotModded}
                        disabled={!isOauthConnected}  // Disable if OAuth not connected
                        readOnly
                        />

                        <FaSyncAlt
                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                        onClick={handleRefreshModStatus}  // Reuse handleTwitchOAuth here
                        title="Refresh Mod Status"
                        />
                        </div>

                    </div>

                    </div>
                </div>
            </Tab>

            {/* First Tab: Context */}
            <Tab eventKey="context" title="Context">
                <div className="context-tab">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="Add context..."
                            value={contextInput}
                            onChange={(e) => setContextInput(e.target.value)}
                        />
                        <Button variant="primary" onClick={handleAddContext} className="mt-2">
                            Add Context
                        </Button>
                    </Form.Group>
                    <ul className="context-list">
                        {contextQueue.map((item, index) => (
                            <li key={index}>
                                {item.text} (added at {new Date(item.timestamp).toLocaleTimeString()})
                                <Button variant="danger" onClick={() => handleRemoveContext(index)} className="ml-2">
                                    Remove
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            </Tab>

            {/* Third Tab: Events */}
            <Tab eventKey="events" title="Events">
                <div className="events-tab">
                    <Button variant="primary" onClick={() => handleEventTrigger('pogchamp')}>
                        PogChamp
                    </Button>
                    <Button variant="success" onClick={() => handleEventTrigger('gogo')}>
                        GoGo!
                    </Button>
                </div>
            </Tab>
        </Tabs>
    </div>
);
};

export default LiveControls;