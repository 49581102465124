// frontend/src/basicChatPage/redux/basicChatMessageReducer.js

import { ADD_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE, SELECT_MESSAGE, CLEAR_MESSAGES, UPDATE_TIMELINELENGTH } from "./basicChatTypes";

const initialState = {
    messageList: [],  // Corrected to messageList
    timelineLength: 1,
    selectedMessage: null
};

const basicChatMessageReducer = (state = initialState, action) => {
    switch (action.type) { 
        
        case ADD_MESSAGE: 
            return {
                ...state, 
                messageList: [...state.messageList, action.payload] // Fix reference
            };

        case UPDATE_MESSAGE:
            // console.log('Message Reducer: Updating message:', action.payload.messageID, action.payload.messageData);
            return {
                ...state,
                messageList: state.messageList.map(message => { 
                    if (message.id === action.payload.messageID) {
                        return { ...message, ...action.payload.messageData };  // Update the entire message object with new data
                    }
                    return message;
                })
            };

        case DELETE_MESSAGE:
            return {
                ...state,
                messageList: state.messageList.filter(message => message.id !== action.payload) // Fix reference
            };

        case SELECT_MESSAGE:
            return {
                ...state,
                selectedMessage: action.payload
            };

        case CLEAR_MESSAGES:
            return {
                ...state,
                messageList: [],
                timelineLength: 0,  // Reset the timeline
                selectedMessage: null
            };
        
        case UPDATE_TIMELINELENGTH:
            return {
                ...state,
                timelineLength: action.payload
            };

        default:
            return state;
    }
};

export default basicChatMessageReducer;