// src/redux/actions/types.js
// test

export const FETCH_SESSIONS_REQUEST = 'FETCH_SESSIONS_REQUEST';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAILURE = 'FETCH_SESSIONS_FAILURE';

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';

export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';

export const UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS';



export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';

export const SET_SUBSCRIPTIONS_STATUS_FAILURE = 'SET_SUBSCRIPTION_STATUS_FAILURE';
export const SET_SUBSCRIPTIONS_STATUS_REQUEST = 'SET_SUBSCRIPTION_STATUS_REQUEST';
export const SET_SUBSCRIPTIONS_STATUS_SUCCESS = 'SET_SUBSCRIPTIONS_STATUS_SUCCESS';

export const SET_TOKEN_BALANCE_REQUEST = 'SET_TOKEN_BALANCE_REQUEST';
export const SET_TOKEN_BALANCE_SUCCESS = 'SET_TOKEN_BALANCE_SUCCESS';
export const SET_TOKEN_BALANCE_FAILURE = 'SET_TOKEN_BALANCE_FAILURE';

export const GET_SUBSCRIPTIONS_STATUS_REQUEST = 'GET_SUBSCRIPTIONS_STATUS_REQUEST';
export const GET_SUBSCRIPTIONS_STATUS_SUCCESS = 'GET_SUBSCRIPTIONS_STATUS_SUCCESS';
export const GET_SUBSCRIPTIONS_STATUS_FAILURE = 'GET_SUBSCRIPTIONS_STATUS_FAILURE';

export const GET_TOKEN_BALANCE_REQUEST = 'GET_TOKEN_BALANCE_REQUEST';
export const GET_TOKEN_BALANCE_SUCCESS = 'GET_TOKEN_BALANCE_SUCCESS';
export const GET_TOKEN_BALANCE_FAILURE = 'GET_TOKEN_BALANCE_FAILURE';







export const GENERATE_CHATTER_ANDYS_REQUEST = 'GENERATE_CHATTER_ANDYS_REQUEST';
export const GENERATE_CHATTER_ANDYS_SUCCESS = 'GENERATE_CHATTER_ANDYS_SUCCESS';
export const GENERATE_CHATTER_ANDYS_FAILURE = 'GENERATE_CHATTER_ANDYS_FAILURE';

export const UPDATE_CHATTER_REQUEST = 'UPDATE_CHATTER_REQUEST';
export const UPDATE_CHATTER_SUCCESS = 'UPDATE_CHATTER_SUCCESS';
export const UPDATE_CHATTER_FAILURE = 'UPDATE_CHATTER_FAILURE';


export const FETCH_CHATTERS_REQUEST = 'FETCH_CHATTERS_REQUEST';
export const FETCH_CHATTERS_SUCCESS = 'FETCH_CHATTERS_SUCCESS';
export const FETCH_CHATTERS_FAILURE = 'FETCH_CHATTERS_FAILURE';

export const DELETE_CHATTER_REQUEST = 'DELETE_CHATTER_REQUEST';
export const DELETE_CHATTER_SUCCESS = 'DELETE_CHATTER_SUCCESS';
export const DELETE_CHATTER_FAILURE = 'DELETE_CHATTER_FAILURE';

export const START_LIVE_SESSION = 'START_LIVE_SESSION';
export const STOP_LIVE_SESSION = 'STOP_LIVE_SESSION';
export const ADD_LIVE_MESSAGE = 'ADD_LIVE_MESSAGE';
export const UPDATE_LIVE_SESSION = 'UPDATE_LIVE_SESSION';
export const CLEAR_LIVE_MESSAGES = 'CLEAR_LIVE_MESSAGES';
export const CLEAR_LIVE_SESSION_DATA = 'CLEAR_LIVE_SESSION_DATA';

export const CREATE_LIVE_SESSION_REQUEST = 'CREATE_LIVE_SESSION_REQUEST';
export const CREATE_LIVE_SESSION_SUCCESS = 'CREATE_LIVE_SESSION_SUCCESS';
export const CREATE_LIVE_SESSION_FAILURE = 'CREATE_LIVE_SESSION_FAILURE';
export const SET_CURRENT_LIVE_SESSION = 'SET_CURRENT_LIVE_SESSION';

export const CLEAR_CHATTERS = 'CLEAR_CHATTERS';

export const RESET_SESSION_STATE = 'RESET_SESSION_STATE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';

export const TRIGGER_CHAT_EVENT = 'TRIGGER_CHAT_EVENT';
export const ADD_CONTEXT_ITEM = 'ADD_CONTEXT_ITEM';
export const REMOVE_CONTEXT_ITEM = 'REMOVE_CONTEXT_ITEM';

export const ADD_TWITCH_MESSAGE = 'ADD_TWITCH_MESSAGE';
export const CLEAR_TWITCH_MESSAGES = 'CLEAR_TWITCH_MESSAGES';

export const CREATE_UNASSIGNED_CHATTER_FAILURE = 'CREATE_UNASSIGNED_CHATTER_FAILURE';
export const CREATE_UNASSIGNED_CHATTER_SUCCESS = 'CREATE_UNASSIGNED_CHATTER_SUCCESS';

export const CREATE_CHATTER_REQUEST = 'CREATE_CHATTER';
export const CREATE_CHATTER_SUCCESS = 'CREATE_CHATTER_SUCCESS';
export const CREATE_CHATTER_FAILURE = 'CREATE_CHATTER_FAILURE';
export const SET_OVERLAY_UUID = 'SET_OVERLAY_UUID';

export const ADD_TWITCH_USERNAME_REQUEST = 'ADD_TWITCH_USERNAME_REQUEST';
export const ADD_TWITCH_USERNAME_FAILURE = 'ADD_TWITCH_USERNAME_FAILURE';
export const ADD_TWITCH_USERNAME_SUCCESS = 'ADD_TWITCH_USERNAME_SUCCESS';

export const ADD_TWITCH_OAUTH_STATUS_REQUEST = 'ADD_TWITCH_OAUTH_STATUS_REQUEST';
export const ADD_TWITCH_OAUTH_STATUS_FAILURE = 'ADD_TWITCH_OAUTH_STATUS_FAILURE';
export const ADD_TWITCH_OAUTH_STATUS_SUCCESS = 'ADD_TWITCH_OAUTH_STATUS_SUCCESS';

export const CHECK_TWITCH_OAUTH_REQUEST = 'CHECK_TWITCH_OAUTH_REQUEST';
export const CHECK_TWITCH_OAUTH_FAILURE = 'CHECK_TWITCH_OAUTH_FAILURE';
export const CHECK_TWITCH_OAUTH_SUCCESS = 'CHECK_TWITCH_OAUTH_SUCCESS';