// frontend/src/redux/actions/TwitchActions.js
import { ADD_TWITCH_MESSAGE, CLEAR_TWITCH_MESSAGES } from "./types";



export const addTwitchMessage = (message) => ({
    type: ADD_TWITCH_MESSAGE,
    payload: message
});

export const clearTwitchMessages = () => ({
    type: CLEAR_TWITCH_MESSAGES
});
