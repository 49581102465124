// frontend/src/components/LivePage/LiveChatterManagement.js
// frontend/src/components/LivePage/LiveChatterManagement.js
import React, { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import ChatterList from '../SessionEditor/ChatterList';
import ChatterSettingsModal from '../SessionEditor/ChatterSettingsModal';
import './LiveChatterManagement.css';

const LiveChatterManagement = () => {
    useDispatch();
    const [selectedChatterId, setSelectedChatterId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    
    useEffect(() => {
        // console.log('LiveChatterManagement Mounted');
    }, []);


    // Handle chatter click: Set the selected chatter and open the modal
    const handleChatterClick = (chatterId, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setModalPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
        setSelectedChatterId(chatterId);
        setShowModal(true);  // Open modal
    };

    const handleCloseModal = () => {
        setShowModal(false);  // Close modal
    };

    return (
        <div className="chatter-management">
            <h3 className="h5 mb-3">Manage Chatter Andys</h3>

            <ChatterList onChatterClick={handleChatterClick} />


            {/* Chatter Settings Modal */}
            {selectedChatterId && (
                <ChatterSettingsModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    chatterId={selectedChatterId}
                    modalPosition={modalPosition}  // Pass modal position if needed
                />
            )}
        </div>
    );
};

export default LiveChatterManagement;