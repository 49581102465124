//frontend/src/basicChatPage/components/basicChatControl.js
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, updateTimelineLength, } from '../redux/basicChatMessageActions';
import { getAllChatters, getTimelineLength } from '../redux/basicChatSelectors';
import EmotePicker from './basicChatEmotePicker';
import BasicChatControlModal from './basicChatControlModal'; 
import BasicChatGeneration from '../generation/basicChatGeneration';
import { getSubscriptionStatus } from '../../redux/selectors';
import BasicChatSaveManager from './basicChatSaveManager';
import SubscriptionNotSubscribed from './basicSubscriptionNotSubscribed';
import { Tabs, Tab } from 'react-bootstrap';
import '../styles/basicChatControl.css';




const BasicChatControl = () => {
    const dispatch = useDispatch();
    const controlChatters = useSelector(getAllChatters) || [];    
    const currentTimelineLength = useSelector(getTimelineLength) || 0;
    const [message, setMessage] = useState('');
    const [messager_id, setMessager_id] = useState(null);
    const [timeIncrement, setTimeIncrement] = useState(1);
    const isPaidSubscriber = useSelector(getSubscriptionStatus);
    const [isEmotePickerVisible, setEmotePickerVisible] = useState(false);
    const emoteButtonRef = useRef(null);


    const [editedMinutes, setEditedMinutes] = useState(0); 
    const [editedSeconds, setEditedSeconds] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customTimeLabel, setCustomTimeLabel] = useState('+Custom Time');
    const [activeTab, setActiveTab] = useState('control');


    const handleAddMessage = () => {
        // If no chatter is selected, exit early
        if (!messager_id) {
            alert('You must create and select a chatter before adding a message');
            return;
        }
    
        const newDisplayTime = currentTimelineLength + timeIncrement - 1;
    
        const selectedChatter = controlChatters.find(chatter => chatter.id === messager_id);
        if (!selectedChatter) return;
    
        const newMessage = {
            id: Math.random().toString(36).substring(7),
            chatter_id: messager_id,
            chatter_name: selectedChatter.username,
            color_code: selectedChatter.color_code,
            session_id: '',
            content: message,
            display_time: newDisplayTime,
        };
    
        dispatch(addMessage(newMessage));
        dispatch(updateTimelineLength(newDisplayTime));
        setMessage('');  // Clear input after adding the message
    };

    const openCustomTimeModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleSaveTime = () => {
        const totalSeconds = (editedMinutes * 60) + editedSeconds;
    
        if (isNaN(totalSeconds) || totalSeconds <= 0) return; // Ensure valid time input
    
        setTimeIncrement(totalSeconds);
    
        // Update the button label with the custom time in seconds
        const formattedTime = totalSeconds < 60 ? `${totalSeconds} sec` : `${editedMinutes}m ${editedSeconds}s`;
        setCustomTimeLabel(`+${formattedTime}`);
        setIsModalOpen(false); // Close the modal after saving
    };

    const handleEmoteSelect = (emoteName) => {
        setMessage(prev => `${prev} ${emoteName}`.trim()); // Add emote without colons
        setEmotePickerVisible(false);
      };

      return (
        <div className="basic-chat-control">
            <Tabs
                id="chat-control-tabs"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
            >
                <Tab eventKey="control" title="Chat Control">
                    <div className="text-box">
                    </div>
                    <div className="chat-control-container">
                        <div className="message-input-section">
                            <select onChange={(e) => setMessager_id(e.target.value)}>
                                <option value="">Select a chatter</option>
                                {controlChatters.map((chatter) => (
                                    <option key={chatter.id} value={chatter.id}>
                                        {chatter.username}
                                    </option>
                                ))}
                            </select>
                            {/* Input with embedded emote button */}
                            <div className="input-with-emote">
                                <input
                                    type="text"
                                    className="custom-message-input"
                                    placeholder="Enter your message here"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <button
                                    className="emote-button"
                                    onClick={() => setEmotePickerVisible(!isEmotePickerVisible)}
                                >
                                    😊
                                </button>

                                <EmotePicker
                                    onSelectEmote={handleEmoteSelect}
                                    isVisible={isEmotePickerVisible}
                                    emoteButtonRef={emoteButtonRef} // Pass ref to position picker
                                />
                            </div>
                        </div>

                        <div className="controls-section">
                            <div className="increment-control">
                                <div className="text-box-2">
                                    <label>Adds new message 'this many seconds' after the final message</label>
                                </div>
                                {/* Custom Time Button */}
                                <button
                                    className={`btn btn-custom-time ${timeIncrement === (editedMinutes * 60 + editedSeconds) ? 'active' : ''}`}
                                    onClick={openCustomTimeModal}
                                >
                                    {customTimeLabel}
                                </button>
                                {[1, 3, 5, 10, 30].map(inc => (
                                    <button
                                        key={inc}
                                        className={`btn btn-outline-primary ${timeIncrement === inc ? 'active' : ''}`}
                                        onClick={() => setTimeIncrement(inc)}
                                    >
                                        +{inc} sec
                                    </button>
                                ))}
                            </div>

                            <button onClick={handleAddMessage} className="btn-primary">Add Message</button>
                        </div>
                    </div>

                    {/* Modal for editing custom time */}
                    <BasicChatControlModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSave={handleSaveTime}
                        minutes={editedMinutes}
                        seconds={editedSeconds}
                        setMinutes={setEditedMinutes}
                        setSeconds={setEditedSeconds}
                    />
                </Tab>
                <Tab eventKey="generation" title="Chat Generation">
          {isPaidSubscriber ? (
            <BasicChatGeneration />
          ) : (
            <SubscriptionNotSubscribed />
          )}
        </Tab>
        <Tab eventKey="saveManager" title="Save/Load">
          {isPaidSubscriber ? (
            <BasicChatSaveManager />
          ) : (
            <SubscriptionNotSubscribed />
          )}
        </Tab>
      </Tabs>
        </div>
    );
};

export default BasicChatControl;
