// frontend/src/components/LivePage/LivePage.js
// frontend/src/components/LivePage/LivePage.js
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addLiveMessage, clearLiveMessages, clearLiveSessionData } from '../../redux/actions/LiveSessionActions';
import LiveChatterManagement from './LiveChatterManagement';
import LivePlaybackComponent from './LivePlaybackComponent';
import sessionService from '../../services/sessionService';
import { getSubscriptionStatus, getTokenBalance, setOverlayUUID  } from '../../redux/actions/authActions';
import PopoutMessageRelay from './popoutMessageRelay';
import Navbar from '../common/NavBar';
import LiveControls from './LiveControls';
import { clearTwitchMessages } from '../../redux/actions/TwitchActions';
import { useAuth0 } from '@auth0/auth0-react';  // Import useAuth0 for token management
import { setAuthToken } from '../../services/api';
import { sortBadges } from './LivePageUtils';
import { BiExpand } from 'react-icons/bi';
import { FiCopy, FiRefreshCw } from 'react-icons/fi';
import './LivePage.css';

const badgeOptions = {
    rope: [
        { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3' },
        { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3' }
    ],
    sub: [
        { id: 2, label: 'Tier 1', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3' },
        { id: 4, label: 'Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3' },
        { id: 5, label: 'Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3' }
    ],
    global: [
        { id: 3, label: 'Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3' }
    ]
  };
  

const LivePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();  // Destructure getAccessTokenSilently from useAuth0
    const allChatters = useSelector(state => state.liveSessionReducer.chatters);
    const liveSession = useSelector(state => state.liveSessionReducer);
    const twitchMessages = useSelector(state => state.twitchMessageReducer.twitchMessages);
    const popoutRef = useRef(null);
    const [showTimestamps, setShowTimestamps] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const overlayUUID = useSelector(state => state.auth.overlayUUID); 
    const overlayURL = `${window.location.origin}/obs/chat/${overlayUUID}`;
    const isPaidSubscriber = useSelector((state) => state.auth.isPaidSubscriber);
    const [showMessage, setShowMessage] = useState(false);
    const reduxTwitchUsername = useSelector(state => state.auth.twitch_username);
    const [isPopoutOpen, setIsPopoutOpen] = useState(false);

    // Combine AI and Twitch messages for playback
    const filteredMessages = [...liveSession.messages, ...twitchMessages]
        .filter(message => message.chatter_name !== 'Unassigned')
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));


        useEffect(() => {
            if (!isPaidSubscriber) {
              setShowMessage(true);
              setTimeout(() => {
                setShowMessage(false);
                navigate('/account?tab=subscription');
              }, );
            }
          }, [isPaidSubscriber, navigate]);

    useEffect(() => {
        const initializeLivePage = async () => {
            if (isAuthenticated) {
                try {
                    // Fetch Auth0 token
                    const token = await getAccessTokenSilently();
                    setAuthToken(token);  // Attach token to Axios

            // Fetch overlay UUID
            const response = await sessionService.fetchOverlayUUID();
            const uuid = response.uuid;

            if (uuid) {
                // If a UUID exists, dispatch it to Redux
                dispatch(setOverlayUUID(uuid));
            } else {
                // If no UUID exists, call generateNewOverlayUrl to create one
                const new_response = await sessionService.regenerateOverlayUUID();
                const new_uuid = new_response.uuid;
                dispatch(setOverlayUUID(new_uuid));
            }

            // Dispatch actions to update subscription status
            dispatch(getSubscriptionStatus());

            } catch (error) {
            if (error.error === 'login_required' || error.error === 'consent_required' || error.error === 'missing_refresh_token') {
                // Automatically log the user out if token renewal fails
                logout({ returnTo: window.location.origin });
            } else {
                console.error('Error initializing LivePage:', error);
            }
            }
        }
        };

        initializeLivePage(); // Initialize the page when the component mounts
    }, [isAuthenticated, getAccessTokenSilently, dispatch, overlayUUID, logout]);

    const formatContextWithRelativeTime = (context) => {
        return context.map((ctx, index) => {
          const minutesAgo = Math.floor((Date.now() - new Date(ctx.timestamp)) / 60000);
          let timeLabel = `${minutesAgo} minutes ago`;
          if (minutesAgo >= 60) {
            const hoursAgo = Math.floor(minutesAgo / 60);
            timeLabel = `${hoursAgo} hours ago`;
          }
          // Format each item with a number, content, and time label
          return `${index + 1}: ${ctx.text} - happened ${timeLabel}`;
        }).join('; '); // Separate items with semicolons
      };
        

    // Send updated filtered messages when they change
    const handleMessagesUpdated = () => {
        if (popoutRef.current && !popoutRef.current.closed) {
            popoutRef.current.postMessage({ messages: filteredMessages }, window.location.origin);
        }
    };
    // Generate messages
    useEffect(() => {
        let interval;
        const filteredChatters = allChatters.filter(chatter => chatter.username !== 'Unassigned');
        
        if (liveSession.isLive && filteredChatters.length > 0) {
            // Create weights based on chatter frequency
            const chatterWeights = filteredChatters.map(chatter => chatter.frequency || 2);
            const totalWeight = chatterWeights.reduce((a, b) => a + b, 0);
    
            let currentInterval = liveSession.settings.strictMode ? liveSession.settings.frequency * 1000 : getRandomInterval(liveSession.settings.frequency);
            // console.log("Current Interval", currentInterval);
    
            interval = setInterval(async () => {
                try {
                    // Select chatter based on weights
                    const chosenChatterIndex = weightedRandom(chatterWeights, totalWeight);
                    const currentChatter = filteredChatters[chosenChatterIndex];
                    const formattedContextString = formatContextWithRelativeTime(liveSession.context); // Format the context with relative time
                    
                    // console.log("Generating Message", new Date().toISOString());
                    // console.log("Current Chatter:", currentChatter, chatterWeights, totalWeight);

                    const sortedBadgeIds = sortBadges(currentChatter.badge_ids);
                    const badgeUrls = sortedBadgeIds.map(id => {
                        const badge = Object.values(badgeOptions).flat().find(option => option.id === id);
                        return badge ? badge.imageUrl : null;
                    }).filter(url => url !== null);
    
                    // Generate message
                    const message = await sessionService.generateMessage(
                        currentChatter.id, 
                        currentChatter.custom_message, 
                        formattedContextString,
                        liveSession.settings.tone,
                        reduxTwitchUsername
                    );
    
                    dispatch(addLiveMessage({
                        chatter_name: currentChatter.username,
                        message: message.message,
                        chatter_color: currentChatter.color_code,
                        badge_urls: badgeUrls,  // Send badge URLs along with the message
                        timestamp: new Date().toISOString()
                    }));
                    dispatch(getTokenBalance());
                    // Set next interval
                    currentInterval = liveSession.settings.strictMode ? liveSession.settings.frequency * 1000 : getRandomInterval(liveSession.settings.frequency);
                } catch (error) {
                    // console.error("Error generating message:", error);
                }
            }, currentInterval);
    
            return () => clearInterval(interval);
        }
    }, [liveSession.isLive, liveSession.settings, allChatters, liveSession.context, dispatch, reduxTwitchUsername ]);

    // Cleanup when leaving the page
    useEffect(() => {
        return () => {
            if (overlayUUID && reduxTwitchUsername) {  // Stop AI generation when unmounting
                sessionService.stopTwitchListener(reduxTwitchUsername);  // Stop Twitch listener
            }
            dispatch(clearLiveSessionData());
            dispatch(clearLiveMessages());
            dispatch(clearTwitchMessages());
        };
    }, [dispatch, overlayUUID, reduxTwitchUsername]);

    // Open popout chat window and send state
    const handlePopoutChat = (darkMode) => {
        // If a popout is already open, focus it instead of opening a new one
        if (popoutRef.current && !popoutRef.current.closed) {
            popoutRef.current.focus();
            return;
        }

        // Open a new popout window
        const url = `/popout/chat/`;
        popoutRef.current = window.open(url, 'PopoutChat', 'width=400,height=800');
        setIsPopoutOpen(true);

        // Send initial messages and dark mode setting after popout loads
        popoutRef.current.onload = () => {
            popoutRef.current.postMessage(
                {
                    type: 'INITIAL_MESSAGES',
                    payload: filteredMessages,
                    darkMode
                },
                window.location.origin
            );
        };

        // Monitor the popout to know when it’s closed
        const popoutInterval = setInterval(() => {
            if (popoutRef.current && popoutRef.current.closed) {
                clearInterval(popoutInterval);
                popoutRef.current = null;
                setIsPopoutOpen(false);
            }
        }, 1000);
    };

    const getRandomInterval = (base) => {
        base = parseFloat(base); 
        // console.log("Base passed to getRandomInterval (seconds):", base, typeof base); 
    
        const variation = 0.5 * base; 
        // console.log("Variation (seconds):", variation, typeof variation);
    
        const min = base - variation; 
        const max = base + variation;
        // console.log("Min (seconds):", min, typeof min);
        // console.log("Max (seconds):", max, typeof max);
    
        
        const randomIntervalInSeconds = Math.random() * (max - min) + min;
        const randomIntervalInMs = Math.floor(randomIntervalInSeconds * 1000); // Convert to milliseconds
    
        // console.log("Random Interval (ms)", randomIntervalInMs, typeof randomIntervalInMs);
        
        return randomIntervalInMs;
    };
    

    // Utility function to pick a chatter based on weights
    const weightedRandom = (weights, totalWeight) => {
        // console.log("Weighted Random", weights, totalWeight);
        const rand = Math.random() * totalWeight;
        // console.log("Random Number", rand);
        let runningSum = 0;
        // console.log("Running Sum", runningSum);
        for (let i = 0; i < weights.length; i++) {
            // console.log("Running Sum", runningSum);
            runningSum += weights[i];
            // console.log ("Running Sum", runningSum);
            if (rand < runningSum) return i;
            // console.log("Random Number", rand);
        }
    };


    const handleTimestampToggle = (e) => {
        setShowTimestamps(e.target.checked);
    };

    const generateNewOverlayUrl = async () => {
        try {
            const token = await getAccessTokenSilently();
            setAuthToken(token);  // Attach token to Axios
    
            const response = await sessionService.regenerateOverlayUUID();
            setOverlayUUID(response.uuid);
            navigator.clipboard.writeText(`${window.location.origin}/obs/chat/${response.uuid}`);
            alert('New OBS overlay URL generated and copied to clipboard!');
        } catch (error) {
            // console.error('Error generating new overlay URL:', error);
        }
    };


    return (
        <div className="live-page-background">
        <div>
            <Navbar />
            {showMessage && (
                <div className="subscription-message">
                <p>You need to be a paid subscriber to access this page. Redirecting to the account page...</p>
                </div>
            )}            
            <div className="live-page-layout">
                <div className="main-content">
                    <div className="left-column">
                        <LiveChatterManagement />
                    </div>
                    <div className="middle-column">
                        <LiveControls liveSession={liveSession} navigate={navigate} />
                    </div>
                </div>
                <div className="chat-column">
                <div className="buttons-section">
                <div className="popout-button-container">
                    <button className="live-popout-button" onClick={() => handlePopoutChat(darkMode)}>
                        <BiExpand size={24} />
                    </button>
                    {isPopoutOpen && <PopoutMessageRelay popoutRef={popoutRef} />}
                </div>

                <div className="url-copy-container">
                <button className="copy-url-button" onClick={() => navigator.clipboard.writeText(overlayURL)}>
                    <FiCopy size={24} /> Copy OBS Overlay URL
                </button>
                <button className="generate-new-url-button" onClick={generateNewOverlayUrl}>
                    <FiRefreshCw size={24} /> Generate New URL
                </button>
            </div>
                    

                {/* Place Dark Mode toggle and timestamp toggle in separate containers */}
                <div className="toggle-container">
                    <div className="toggle-item">
                        <span>Dark Mode</span>
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={darkMode} 
                                onChange={() => setDarkMode(!darkMode)} 
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="toggle-item">
                        <span>Show Timestamps</span>
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                checked={showTimestamps} 
                                onChange={handleTimestampToggle} 
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                        
                    </div>
                    <LivePlaybackComponent 
                    messages={filteredMessages} 
                    onMessagesUpdated={handleMessagesUpdated} 
                    showTimestamps={showTimestamps} 
                    darkMode={darkMode} 
                />
                </div>
            </div>
        </div>
        </div>
    );
};

export default LivePage;