//frontend/src/basicChatPage/components/basicChatterAndyManagement.js
import React, { useState } from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import { selectChatter } from '../redux/basicChatChatterActions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChatterId } from '../redux/basicChatSelectors';
import BasicChatterSettings from './basicChatterSettings';
import BasicChatterList from './basicChatterList';
import '../styles/basicChatterAndyManagement.css';

function BasicChatterAndyManagement() {

    const dispatch = useDispatch();
    const selectedChatter = useSelector(getSelectedChatterId) || null;
    const [activeTab, setActiveTab ] = useState('list');
    

    const handleChatterSelect = (chatterID) => {
        dispatch(selectChatter(chatterID));
        setActiveTab('settings');        
    };

    return (
        <div className="basic-chatter-andy-management">
            <Tabs
            id="chatter-andy_tabs"
            activeKey={activeTab}
            onSelect={(k) => {
                console.log('Tab selected:', k); // Log which tab is selected
                setActiveTab(k);
            }}
            className="mb-3"
        >
            <Tab eventKey="list" title="Chatters">
                <BasicChatterList onChatterSelect={handleChatterSelect} />
            </Tab>
            
            <Tab eventKey="settings" title="Settings" disabled={!selectedChatter}>
                {selectedChatter && (
                <BasicChatterSettings chatterId={selectedChatter} setActiveTab={setActiveTab} />
                )}
            </Tab>
      </Tabs>
    </div>
    )
}

export default BasicChatterAndyManagement;