//frontend/src/basicChatPage/redux/basicChatSelectors.js
import { createSelector } from 'reselect';

/* -------------------- Chatters Selectors -------------------- */

// Basic selector that grabs chatters from the state
export const getChatterList = (state) => state.basicChat.chatters.chatterList || [];

// Memoized selector using Reselect to return all chatters
export const getAllChatters = createSelector(
  [getChatterList],
  (chatterList) => chatterList.filter(
    (chatter) => chatter.username !== 'Unknown' && chatter.username !== 'Unassigned'
  )
);

// Selector to get a specific chatter by ID
export const getChatterById = (state, chatterId) =>
  state.basicChat.chatters.chatterList.find((chatter) => chatter.id === chatterId) || null;



/* ----------------- Selected Chatter Selectors ---------------- */

// Selector to get the currently selected chatter ID
export const getSelectedChatterId = (state) => state.basicChat.chatters.selectedChatter;




/* ----------------- Messages Selectors ---------------- */
// Basic selector to get all messages from the state
export const getMessageList = (state) => state.basicChat.messages.messageList;

// Memoized selector to get a specific message by ID
export const getMessageById = (state, messageId) =>
  state.basicChat.messages.messageList.find(message => message.id === messageId);

// Memoized selector to get all messages sorted by display time
export const getAllMessagesSorted = createSelector(
  [getMessageList],
  (messageList) => messageList.slice().sort((a, b) => a.display_time - b.display_time)
);

export const getTimelineLength = (state) => state.basicChat.messages.timelineLength;


/* ----------------- Session Selectors ---------------- */

// Selector to get the list of all saved sessions
export const getSessionList = (state) => state.basicChat.sessions.savedSessions || [];


// Selector to get a specific session by ID
export const getSessionById = (state, sessionId) =>
  state.basicChat.sessions.savedSessions.find(session => session.id === sessionId);

// Selector to check if sessions are currently loading
export const getSessionLoadingState = (state) => state.basicChat.sessions.loading;

// Selector to get the error state, if any
export const getSessionErrorState = (state) => state.basicChat.sessions.error;

// Selector to get the currently loaded session
export const getCurrentSession = (state) => state.basicChat.sessions.currentSession;

// Selector to get sessions formatted for display, showing only ID and name
export const getSessionListForDisplay = createSelector(
  [getSessionList],
  (savedSessions) => {
    // If savedSessions is empty, return the same empty reference (to avoid unnecessary recomputation)
    if (savedSessions.length === 0) return savedSessions;

    // If savedSessions hasn't changed, return the same reference to avoid unnecessary recomputation
    return savedSessions.map((session) => ({
      id: session.id,
      session_name: session.session_name,
    }));
  }
);