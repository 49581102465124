// frontend/src/basicChatPage/components/basicSubscriptionNotSubscribed.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../accountPage/styles/Subscription.css';


const SubscriptionNotSubscribed = () => {
    const navigate = useNavigate();
  
    const handleSubscribeClick = () => {
      navigate('/account?tab=subscription');
    };
  
    return (
      <div className="subscription-not-subscribed">
        <div className="subscribe-basic-container">
          <div className="subscribe-features">
            <p className="highlight-text"><strong> AI services</strong></p>      
            <p><span className="highlight-number">600,000</span> tokens. Approx <span className="highlight-number">20 hours</span> of <strong>LIVE!</strong> at default settings. Save up to <span className="highlight-number">10</span> <strong>Create!</strong> ChatSessions</p>
          </div>
          <div className="custom-price-sub">
            <p>$9.99</p>
          </div>
          <button className="btn btn-primary mt-3 subscribe-button" onClick={handleSubscribeClick}>
            Subscribe Now
          </button>
          <div className="subscribe-info">
            <p>Payments and subscriptions are securely handled by Stripe</p>
            <p>Re-occuring • Renews once a month • Cancel anytime</p>
          </div>
        </div>
      </div>
    );
  };
  
  export default SubscriptionNotSubscribed;