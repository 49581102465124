// frontend/src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// Configuration for Redux Persist
const persistConfig = {
  key: 'root', 
  storage,  // Use localStorage
  stateReconciler: autoMergeLevel2, // Manage state reconciliation for nested states
  
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,  // Use the persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for non-serializable actions
      immutableCheck: false,  // Disable immutability check for performance
    }),
});

export const persistor = persistStore(store);

export default store;
