// src/redux/reducers/authReducer.js
import { 
  LOGOUT_USER, 
  SET_CURRENT_USER, 
  SET_AUTH_STATUS, 
  GET_SUBSCRIPTIONS_STATUS_REQUEST, 
  GET_SUBSCRIPTIONS_STATUS_SUCCESS, 
  GET_SUBSCRIPTIONS_STATUS_FAILURE, 
  SET_SUBSCRIPTIONS_STATUS_REQUEST, 
  SET_SUBSCRIPTIONS_STATUS_SUCCESS, 
  SET_SUBSCRIPTIONS_STATUS_FAILURE, 
  GET_TOKEN_BALANCE_REQUEST, 
  GET_TOKEN_BALANCE_SUCCESS, 
  GET_TOKEN_BALANCE_FAILURE, 
  SET_TOKEN_BALANCE_REQUEST, 
  SET_TOKEN_BALANCE_SUCCESS, 
  SET_TOKEN_BALANCE_FAILURE,
  SET_OVERLAY_UUID,
  CHECK_TWITCH_OAUTH_REQUEST,
  CHECK_TWITCH_OAUTH_SUCCESS,
  CHECK_TWITCH_OAUTH_FAILURE,
} from '../actions/types';




const initialState = {
  isAuthenticated: false,
  user: {
    updated_at: '',
    email: '',
    email_verified: false,
    name: '',
    picture: '',
  },
  isPaidSubscriber: false,
  tokenBalance: 0,
  overlayUUID: '',
  subscriptionLoading: false,
  subscriptionError: null,
  tokenLoading: false,
  tokenError: null,
  twitch_username: null,
  twitch_oauth_status: null,
};

function authReducer(state = initialState, action) {
   

  switch (action.type) {
    case SET_CURRENT_USER:
      const { updated_at, email, email_verified, name, picture } = action.payload || {};
      // console.log("authReducer.js: User logged in:", email);  // Log user login
      return {
        ...state,
        isAuthenticated: Boolean(action.payload),
        user: { updated_at, email, email_verified, name, picture },
      };

    case LOGOUT_USER:
      // console.log("authReducer.js: User logged out");
      return {
        ...state,
        isAuthenticated: false,
        user: { updated_at: '', email: '', email_verified: false, name: '', picture: '' },
      };

    case SET_AUTH_STATUS:
      // console.log("authReducer.js: Setting auth status:", action.payload);
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    // Handle GET Subscription Status
    case GET_SUBSCRIPTIONS_STATUS_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionError: null,
      };

    case GET_SUBSCRIPTIONS_STATUS_SUCCESS:
      // console.log("authReducer.js: Subscription status updated:", action.payload);  // Log the status update
      return {
        ...state,
        subscriptionLoading: false,
        isPaidSubscriber: action.payload,
      };

    case GET_SUBSCRIPTIONS_STATUS_FAILURE:
      // console.error("authReducer.js: Error updating subscription status:", action.payload);  // Log any error
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionError: action.payload,
      };

    // Handle SET Subscription Status
    case SET_SUBSCRIPTIONS_STATUS_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionError: null,
      };

    case SET_SUBSCRIPTIONS_STATUS_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        isPaidSubscriber: action.payload,
      };

    case SET_SUBSCRIPTIONS_STATUS_FAILURE:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionError: action.payload,
      };

    // Handle GET Token Balance
    case GET_TOKEN_BALANCE_REQUEST:
      return {
        ...state,
        tokenLoading: true,
        tokenError: null,
      };

    case GET_TOKEN_BALANCE_SUCCESS:
      return {
        ...state,
        tokenLoading: false,
        tokenBalance: action.payload,
      };
      
    case GET_TOKEN_BALANCE_FAILURE:
      return {
        ...state,
        tokenLoading: false,
        tokenError: action.payload,
      };

    // Handle SET Token Balance
    case SET_TOKEN_BALANCE_REQUEST:
      return {
        ...state,
        tokenLoading: true,
        tokenError: null,
      };

    case SET_TOKEN_BALANCE_SUCCESS:
      return {
        ...state,
        tokenLoading: false,
        tokenBalance: action.payload,
      };

    case SET_TOKEN_BALANCE_FAILURE:
      return {
        ...state,
        tokenLoading: false,
        tokenError: action.payload,
      };

    case SET_OVERLAY_UUID:
      return {
        ...state,
        overlayUUID: action.payload,
      };

    case CHECK_TWITCH_OAUTH_REQUEST:
      return {
        ...state,
        twitch_oauth_status: null,
      };
      
    case CHECK_TWITCH_OAUTH_SUCCESS:
      return {
        ...state,
        twitch_oauth_status: action.payload.is_oauth_connected,
        twitch_username: action.payload.twitch_username,
      };
      
    case CHECK_TWITCH_OAUTH_FAILURE:
      return {
        ...state,
        twitch_oauth_status: false,
      };


    default:
      return state;
  }
}

export default authReducer;