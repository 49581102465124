//frontend/src/basicChatPage/basicChatEditor.js
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BasicChatterAndyManagement from './components/basicChatterAndyManagement';
import BasicChatTimeline from './components/basicChatTimeline';
import BasicChatPlayback from './components/basicChatPlayback';
import BasicChatControl from './components/basicChatControl';
import NavBar from '../components/common/NavBar'; 
import PopoutChat from './components/basicPopoutChat';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 hook
import { setAuthToken } from '../services/api'; // Import setAuthToken function
import './styles/basicChatEditor.css';



function BasicChatEditor() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0(); // Get Auth0 functions
    const { navigate } = useNavigate();


    useEffect(() => {
        const setToken = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently(); // Fetch Auth0 token
                    setAuthToken(token); // Attach token to Axios headers
                } catch (error) {
                    // console.error('Error fetching Auth0 token:', error);
                    // Optionally redirect to login page if token fetch fails
                    navigate('/login');
                }
            }
        };
        setToken();
    }, [isAuthenticated, getAccessTokenSilently, navigate]);

    return (
        <div className="basic-chat-editor-page">
        <div>
            <NavBar />
                {/* Main layout container */}
                <div className="basic-chat-editor-layout">
                    <div className="Main-Content">
                        
                        {/*Left Column: Andy Management*/}
                        <div className="left-column">
                            <BasicChatterAndyManagement />
                        </div>

                        {/*Middle Column: Chat Timeline*/}
                        <div className="middle-column">
                            <BasicChatControl />
                            <BasicChatTimeline />
                        </div>

                        {/* Right Column: Chat Playback */}
                        <div className="right-column">
                            <BasicChatPlayback />
                        </div>          

                    
                    </div>
                </div>

                    <Routes>
                    <Route path="/basicPopout" element={<PopoutChat />} /> 
                    </Routes>

        </div>
        </div>
    )
};

export default BasicChatEditor;