//frontend/src/basicChatPage/components/basicChatterList.js
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllChatters } from "../redux/basicChatSelectors";
import { addChatter, deleteChatter, clearChatters } from "../redux/basicChatChatterActions";
import { generateUniqueUsername } from '../utils/basicChatUtils';
import '../styles/basicChatterList.css';

const TWITCH_DEFAULT_COLORS = [
    '#FF0000', '#0000FF', '#008000', '#B22222', '#FF7F50',
    '#9ACD32', '#FF4500', '#2E8B57', '#DAA520', '#D2691E',
    '#5F9EA0', '#1E90FF', '#FF69B4', '#8A2BE2', '#00FF7F'
];

const BasicChatterList = ({ onChatterSelect }) => {
    const dispatch = useDispatch();
    const chatters = useSelector(getAllChatters) || [];
    
    // Memoize the chatters array to avoid recalculations
    const memoizedChatters = useMemo(() => chatters, [chatters]);

    const [filter, setFilter] = useState('');

    useEffect(() => {
       // console.log('BasicChatterList mounted');

        // Add a default chatter if none exist
        if (memoizedChatters.length === 0) {
            const baseUsername = 'ChatterAndy';
            const uniqueUsername = generateUniqueUsername(baseUsername, memoizedChatters);

            const newChatter = {
                id: Math.random().toString(36).substring(7),
                username: uniqueUsername,
                archetype: 'hype_man',
                color_code: '#FF4500',
                custom_message: '',
                badge_ids: []
            };
            dispatch(addChatter(newChatter));
        }

        return () => {
            //console.log('BasicChatterList unmounted');
        };
    }, [memoizedChatters, dispatch]);

    const filteredChatters = memoizedChatters.filter(chatter =>
        chatter.username.toLowerCase().includes(filter.toLowerCase())  // Filtering based on input
    );

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * TWITCH_DEFAULT_COLORS.length);
        return TWITCH_DEFAULT_COLORS[randomIndex];
    };
    
    const handleAddChatter = () => {
        const baseUsername = 'ChatterAndy';
        const uniqueUsername = generateUniqueUsername(baseUsername, memoizedChatters);

        const newChatter = {
            id: Math.random().toString(36).substring(7),
            username: uniqueUsername,
            archetype: 'hype_man',
            color_code: getRandomColor(),
            custom_message: '',
            badge_ids: []
        };
        dispatch(addChatter(newChatter));
    };

    const handleDeleteChatter = (chatterId) => {
        dispatch(deleteChatter(chatterId)); // Dispatch the delete action
    };

    const handleClearChatters = () => {
        if (window.confirm("Are you sure you want to clear all chatters? This action cannot be undone.")) {
            dispatch(clearChatters());
        }
    };
    
    return (
        <div className="basic-chatter-list">            
            <h3>Chatter Andys</h3>
            <div className="filter-container">
                <input
                    type="text"
                    className="custom-filter-input"
                    placeholder="Type to filter"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                {filter && (
                    <button className="clear-filter-btn" onClick={() => setFilter('')}>
                        &times;
                    </button>
                )}
               
            </div>
                <div>
                <ul>
                    {filteredChatters.map((chatter) => (
                        <li key={chatter.id}>
                            <span style={{ color: chatter.color_code }}>
                                {chatter.name || chatter.username}
                            </span>    
                            <div className="chatter-buttons">
                                {/* Edit button */}
                                <button 
                                    className="btn btn-sm btn-primary me-2" 
                                    onClick={() => onChatterSelect(chatter.id)}
                                >
                                    Edit
                                </button>

                                {/* Delete button */}
                                <button 
                                    className="btn btn-sm btn-danger" 
                                    onClick={() => handleDeleteChatter(chatter.id)}
                                >
                                    X
                                </button>
                            </div>
                        </li>
                    ))}
                    
                </ul>
                </div>
            <div className="chatter-buttons-container">
                <button className="btn btn-primary" onClick={handleAddChatter}>
                    Create Chatter Andy
                </button>
                <button className="btn btn-sm btn-danger custom-clear-btn" onClick={handleClearChatters}>
                    Clear All Chatters
                </button>
            </div>
        </div>
    );
};

export default BasicChatterList;
