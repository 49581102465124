//frontend/src/basicChatPage/components/basicChatterSettings.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChatterById, getAllMessagesSorted } from '../redux/basicChatSelectors';
import { updateChatter } from '../redux/basicChatChatterActions';
import { getSubscriptionStatus } from '../../redux/selectors';
import { updateMessage } from '../redux/basicChatMessageActions';
import '../styles/basicChatterSettings.css';

const badgeOptions = {
  rope: [
      { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3' },
      { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3' }
  ],
  sub: [
      { id: 2, label: 'Tier 1', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3' },
      { id: 4, label: 'Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3' },
      { id: 5, label: 'Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3' }
  ],
  global: [
      { id: 3, label: 'Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3' },
      
  ]
};
// Predefined color options

const default_colors = [
    ["Red", "#FF0000"],
    ["Blue", "#0000FF"],
    ["Green", "#00FF00"],
    ["FireBrick", "#B22222"],
    ["Coral", "#FF7F50"],
    ["YellowGreen", "#9ACD32"],
    ["OrangeRed", "#FF4500"],
    ["SeaGreen", "#2E8B57"],
    ["GoldenRod", "#DAA520"],
    ["Chocolate", "#D2691E"],
    ["CadetBlue", "#5F9EA0"],
    ["DodgerBlue", "#1E90FF"],
    ["HotPink", "#FF69B4"],
    ["BlueViolet", "#8A2BE2"],
    ["SpringGreen", "#00FF7F"]
];

const archetypeOptions = [
  { value: 'hype_man', label: 'The Hype Man' },
  { value: 'advisor', label: 'The Advisor' },
  { value: 'sarcastic_observer', label: 'The Sarcastic Observer' },
  { value: 'nostalgic_fan', label: 'The Nostalgic Fan' },
  { value: 'troll', label: 'The Troll' },
  { value: 'contrarian', label: 'The Contrarian' },
  { value: 'know_it_all', label: 'The Know-It-All' },
  { value: 'lurker', label: 'The Lurker' },
  { value: 'cynic', label: 'The Cynic' },
  { value: 'memer', label: 'The Memer' },
];

const BasicChatterSettings = ({ chatterId, setActiveTab }) => {
    const dispatch = useDispatch();
    const chatter = useSelector((state) => getChatterById(state, chatterId));
    const isPaidSubscriber = useSelector(getSubscriptionStatus);
    const [username, setUsername] = useState('');
    const [badgeIds, setBadgeIds] = useState(chatter?.badge_ids || []);
    const [color, setColor] = useState('#FFFFFF');
    const [archetype, setArchetype] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const allMessages = useSelector(getAllMessagesSorted);


      // Sync state with chatter prop whenever chatter changes
  useEffect(() => {
    if (chatter) {
      setUsername(chatter.username || '');
      setBadgeIds(chatter.badge_ids || []);
      setColor(chatter.color_code || '#FFFFFF');
      setArchetype(chatter.archetype || '');
      setCustomMessage(chatter.custom_message || '');
    }
  }, [chatterId]);


    const handleSave = () => {
        dispatch(updateChatter(chatterId, {
            username,
            badge_ids: badgeIds,
            color_code: color,
            archetype,
            custom_message: customMessage
        }));

        handleUpdateReduxMessagesForID(chatterId, username);
        // console.log("Save the chatter settings");
        setActiveTab('list');
    };

    const handleUpdateReduxMessagesForID = (chatterId, newChatterName) => {
      // Loop through all messages and update the ones with the matching chatter ID
      allMessages.forEach(message => {
          if (message.chatter_id === chatterId) {
              dispatch(updateMessage(message.id, { chatter_name: newChatterName }));
          }
      });
  };


    const handleBadgeChange = (badgeId, group) => {
      const otherGroupBadges = badgeOptions[group].map(badge => badge.id);
      if (badgeIds.includes(badgeId)) {
          setBadgeIds(badgeIds.filter(id => id !== badgeId));
      } else {
          const updatedBadges = badgeIds.filter(id => !otherGroupBadges.includes(id));
          setBadgeIds([...updatedBadges, badgeId]);
      }
  };

  const handleColorSelect = (selectedColor) => {
      setColor(selectedColor);
  };

  const handleUsernameChange = (e) => {
      setUsername(e.target.value);
  };

  const handleArchetypeChange = (e) => {
      setArchetype(e.target.value);
  };

  const handleCustomMessageChange = (e) => {
    if (e.target.value.length <= 128) {
        setCustomMessage(e.target.value);
    }
  };

  // Get badge URLs for displaying selected badges next to the username
  const getBadgeUrls = () => {
    let ropeBadge = null;
    let subBadge = null;
    let globalBadge = null;

    badgeIds.forEach((badgeId) => {
      const badge = Object.values(badgeOptions).flat().find(badge => badge.id === badgeId);
      if (badge) {
        if (badgeOptions.rope.some(b => b.id === badgeId)) {
          ropeBadge = badge.imageUrl;
        } else if (badgeOptions.sub.some(b => b.id === badgeId)) {
          subBadge = badge.imageUrl;
        } else if (badgeOptions.global.some(b => b.id === badgeId)) {
          globalBadge = badge.imageUrl;
        }
      }
    });

    return [ropeBadge, subBadge, globalBadge].filter(Boolean);
  };

    return (
        <div className="basic-chatter-settings">
            <h1>{username ? `${username} Settings` : 'Chatter Settings'}</h1>
            <div className="input-row">
              <div className="selected-badges">
              {getBadgeUrls().map((url, index) => (
                <img key={index} src={url} alt="badge" className="badge-icon" />))}
              </div>
              <input
                type="text"
                className="form-control"
                style={{ color: color }}
                value={username}
                onChange={handleUsernameChange}
              />
              </div>

            {/* Badge Selection Groups */}
            <div className="badge-group">
                
                <div className="badge-selection-grid">
                    {badgeOptions.rope.map(option => (
                        <div
                            key={option.id}
                            className={`badge-option ${badgeIds.includes(option.id) ? 'selected-badge' : ''}`}
                            onClick={() => handleBadgeChange(option.id, 'rope')}
                        >
                            <img src={option.imageUrl} alt={option.label} className="badge-icon" />
                            <label>{option.label}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className="badge-group">
                
                <div className="badge-selection-grid">
                    {badgeOptions.sub.map(option => (
                        <div
                            key={option.id}
                            className={`badge-option ${badgeIds.includes(option.id) ? 'selected-badge' : ''}`}
                            onClick={() => handleBadgeChange(option.id, 'sub')}
                        >
                            <img src={option.imageUrl} alt={option.label} className="badge-icon" />
                            <label>{option.label}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className="badge-group">
                
                <div className="badge-selection-grid">
                    {badgeOptions.global.map(option => (
                        <div
                            key={option.id}
                            className={`badge-option ${badgeIds.includes(option.id) ? 'selected-badge' : ''}`}
                            onClick={() => handleBadgeChange(option.id, 'global')}
                        >
                            <img src={option.imageUrl} alt={option.label} className="badge-icon" />
                            <label>{option.label}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="color-selection-grid">
              <div className="color-row">
                {default_colors.slice(0, 8).map(([colorName, colorValue], index) => (
                  <div
                    key={index}
                    className="color-square"
                    style={{ backgroundColor: colorValue }}
                    onClick={() => handleColorSelect(colorValue)} // Select color on click
                  ></div>
                ))}
              </div>
              <div className="color-row">
                {default_colors.slice(8).map(([colorName, colorValue], index) => (
                  <div
                    key={index}
                    className="color-square"
                    style={{ backgroundColor: colorValue }}
                    onClick={() => handleColorSelect(colorValue)} // Select color on click
                  ></div>
                ))}
              </div>
            </div>
            {isPaidSubscriber && (
                <>
                    <div className="input-row-basic-archetype">
                        <label>Archetype:</label>
                        <select
                            className="form-select"
                            value={archetype}
                            onChange={handleArchetypeChange}
                        >
                            {archetypeOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-row-basic-bio">
                        <label>Custom Bio (128 characters max):</label>
                        <input
                            type="text"
                            className="form-control"
                            value={customMessage}
                            onChange={handleCustomMessageChange}
                            maxLength="128"
                        />
                    </div>
                </>
            )}

            <button className="btn btn-primary mt-3" onClick={handleSave}>
                Save
            </button>
        </div>
    );
};

export default BasicChatterSettings;