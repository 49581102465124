// frontend/src/basicChatPage/components/basicChatSaveManager.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSessions, loadSessionById, deleteSession } from '../redux/basicChatSessionActions';
import { getSessionListForDisplay } from '../redux/basicChatSelectors';
import useCanAccessSubFeat from '../../hooks/useCanAccessSubFeat';
import { getSubscriptionStatus } from '../../redux/actions/authActions';
import sessionService from '../../services/sessionService';
import '../styles/basicChatSaveManager.css';

const BasicChatSaveManager = () => {
  const dispatch = useDispatch();
  const savedSessions = useSelector(getSessionListForDisplay);
  const [sessionName, setSessionName] = useState('');
  const basicChat = useSelector((state) => state.basicChat);
  const { canSaveSessions } = useCanAccessSubFeat();
  

  useEffect(() => {
    const delayLoadSessions = setTimeout(() => {
      dispatch(getSubscriptionStatus());
      dispatch(loadSessions());
      // console.log('BasicChatSaveManager Mounted: Fetching Sessions');
    }, 500); // Delay of 0.5 seconds

    // Cleanup function to clear the timeout if the component unmounts before 0.5 sec
    return () => clearTimeout(delayLoadSessions);
  }, [dispatch]);

  const handleSaveSession = async () => {
    if (!sessionName.trim()) {
      alert('Please enter a session name to save.');
      return;
    }

    try {
      await sessionService.saveSession(basicChat, sessionName); // Use basicChat state here
      // console.log(`Session "${sessionName}" saved successfully.`);
      
      // Clear the session name after saving
      setSessionName('');

      // Reload sessions after saving
      dispatch(loadSessions());
    } catch (error) {
      // console.error('Error saving session:', error);
    }
  };

  const handleDeleteSession = (sessionId) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      dispatch(deleteSession(sessionId)); // Dispatch the delete action
    }
  };

  
  return (
    <div className="save-manager-container">
      <h3>Saved Sessions</h3>
      
      <ul className="save-manager-list">
        {savedSessions.length > 0 ? (
          savedSessions.map((session) => (
            <li key={session.id} className="save-manager-item">
              <span>{session.session_name}</span>
              <div className="save-manager-buttons">
                <button 
                  className="btn-save btn-sm btn-primary me-2" 
                  onClick={() => dispatch(loadSessionById(session.id))}  
                  disabled={!canSaveSessions()} // Disable while loading
                >
                  Load
                </button>
                <button 
                  className="btn btn-sm btn-danger" 
                  onClick={() => handleDeleteSession(session.id)} 
                  disabled={!canSaveSessions()} // Delete button
                >
                  X
                </button>
              </div>
            </li>
          ))
        ) : (
          <p>No saved sessions found.</p>
        )}
      </ul>

      <input
        type="text"
        className="save-manager-input"
        value={sessionName}
        onChange={(e) => setSessionName(e.target.value)}
        placeholder="Enter session name"
      />
      <button onClick={handleSaveSession} disabled={!canSaveSessions()} className="btn-save-session btn-primary mt-2">
        Save Current
      </button>
      <span className="max-saves-text">Maximum of 5 Saves</span>
    </div>
  );
};

export default BasicChatSaveManager;