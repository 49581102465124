//frontend/src/redux/reducers/rootReducer.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import liveSessionReducer from './LiveSessionReducers';
import twitchMessageReducer from './TwitchReducer';
import basicChatRootReducer from '../../basicChatPage/redux/basicChatRootReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    liveSessionReducer: liveSessionReducer,
    twitchMessageReducer: twitchMessageReducer,
    basicChat: basicChatRootReducer,
});

export default rootReducer;


