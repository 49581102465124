// src/components/LivePage/popoutMessageRelay.js
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sortBadges, badgeOptions } from './LivePageUtils';

const PopoutMessageRelay = ({ popoutRef }) => {
    const aiMessages = useSelector((state) => state.liveSessionReducer.messages);
    const twitchMessages = useSelector((state) => state.twitchMessageReducer.twitchMessages);
    const darkMode = useSelector((state) => state.liveSessionReducer.darkMode);

    // Map badge IDs to URLs
    // Wrap mapBadgeIdsToUrls in useCallback to avoid dependency issues
    const mapBadgeIdsToUrls = useCallback((badgeIds) => {
        return sortBadges(badgeIds)
            .map((id) => badgeOptions[id]?.imageUrl || null)
            .filter(Boolean);
    }, []);

    // Memoize prepareMessages to avoid recreating it on each render
    const prepareMessages = useCallback((messages) => {
        return messages.map((message) => ({
            ...message,
            badge_urls: message.badge_urls || mapBadgeIdsToUrls(message.badge_ids || [])
        }));
    }, [mapBadgeIdsToUrls]);

    useEffect(() => {
        const combinedMessages = [...prepareMessages(aiMessages), ...prepareMessages(twitchMessages)]
            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    
        if (popoutRef.current && !popoutRef.current.closed) {
            popoutRef.current.postMessage({
                type: 'NEW_MESSAGES',
                payload: combinedMessages,
                darkMode,
            }, window.location.origin);
        }
    }, [aiMessages, twitchMessages, darkMode, popoutRef, prepareMessages]);

    // Send dark mode updates independently
    useEffect(() => {
        if (popoutRef.current && !popoutRef.current.closed) {
            popoutRef.current.postMessage({
                type: 'UPDATE_DARK_MODE',
                payload: darkMode,
            }, window.location.origin);
        }
    }, [darkMode, popoutRef]);

    return null;
};

export default PopoutMessageRelay;