// frontend/src/components/SessionEditor/ChatterList.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChatters, deleteChatter, createUnassignedChatter, createChatter } from '../../redux/actions/LiveSessionActions';
import ChatterSettingsModal from './ChatterSettingsModal';
import '../../styles/ChatterList.css';

const TWITCH_DEFAULT_COLORS = [
    '#FF0000', '#0000FF', '#008000', '#B22222', '#FF7F50',
    '#9ACD32', '#FF4500', '#2E8B57', '#DAA520', '#D2691E',
    '#5F9EA0', '#1E90FF', '#FF69B4', '#8A2BE2', '#00FF7F'
];

const ChatterList = () => {
    const dispatch = useDispatch();
    const { chatters, loading, error } = useSelector(state => state.liveSessionReducer);  // Check loading, chatters, and error
    const [filter, setFilter] = useState('');
    const [selectedChatterId, setSelectedChatterId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: -20, left: 0 });

    // Fetch chatters when the component mounts
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(fetchChatters());
            // console.log('ChatterList Mounted: Fetching Chatters');
        }, 5000); // 5-second delay

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, [dispatch]);

    // Ensure unassigned chatter exists after fetching
    useEffect(() => {
        if (!loading && chatters.length > 0 && !error) {
            const unassignedChatterExists = chatters.some(chatter => chatter.username === 'Unassigned');
            if (!unassignedChatterExists) {
                dispatch(createUnassignedChatter());
            }
        }
    }, [loading, chatters, error, dispatch]);

    // Refresh chatters when modal closes
    const refreshChatters = () => {
        dispatch(fetchChatters());
        // console.log ('Chatterlist: CallingRefreshChatters');
    };

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * TWITCH_DEFAULT_COLORS.length);
        return TWITCH_DEFAULT_COLORS[randomIndex];
    };    

    const generateUniqueUsername = (baseUsername, chatters) => {
        let counter = 1;
        let uniqueUsername = baseUsername;
    
        // Helper function to check if a username exists
        const isUsernameTaken = (username) => {
            return chatters.some(chatter => chatter.username === username);
        };
    
        // Loop until we find a unique username
        while (isUsernameTaken(uniqueUsername)) {
            uniqueUsername = `${baseUsername}_${counter}`;
            counter++;
        }
    
        return uniqueUsername;
    };
    


    const handleAddChatter = () => {
        const baseUsername = 'ChatterAndy';
        const uniqueUsername = generateUniqueUsername(baseUsername, chatters);
    
        const newChatter = {
            username: uniqueUsername,
            archetype: 'hype_man',
            color_code: getRandomColor(),
            custom_message: '',
            badge_ids: []
        };
    
        // Dispatch the createChatter action
        dispatch(createChatter(newChatter));
    };
    

    const handleChatterClick = (chatterId, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setModalPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
        setSelectedChatterId(chatterId);
        setShowModal(true);  
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedChatterId(null);
        refreshChatters();
        // console.log ('ChatterList: Modal Closed, Refreshing Chatters');
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;  // Display error if fetching fails

    // Filter out the "Unassigned" chatter from the initial chatters list
    const filteredChatters = chatters
    .filter(chatter => chatter.username.toLowerCase() !== 'unassigned')
    .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));  // Sort alphabetically

// Apply search filter on top of the default filtering
const searchedChatters = filteredChatters.filter(chatter =>
    chatter.username.toLowerCase().includes(filter.toLowerCase())
);

return (
    <div className="chatter-list-container">

        {/* Input field for searching chatters */}
        <input
            type="text"
            className="custom-filter-input"
            placeholder="Filter Chatters"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
        />

        {/* List of chatters */}
        <div className="chatter-list">
            {searchedChatters.map(chatter => (
                <div
                    key={chatter.id}
                    className="chatter-item d-flex"
                    onClick={(event) => handleChatterClick(chatter.id, event)}
                    style={{ color: chatter.color_code }} // Apply the color to the text
                >
                    <span>{chatter.username}</span>
                    <button
                        className="btn btn-sm btn-danger delete-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(deleteChatter(chatter.id));
                        }}
                    >
                        X
                    </button>
                </div>
            ))}
        </div>

        {/* Button to add a new chatter */}
        <button className="btn btn-primary mt-2" onClick={handleAddChatter}>
            Create Chatter Andy
        </button>

        {/* Modal for Chatter Settings */}
        {selectedChatterId && (
            <ChatterSettingsModal
                show={showModal}
                handleClose={handleCloseModal}
                chatterId={selectedChatterId}
                modalPosition={modalPosition}
            />
        )}
    </div>
);
};

export default ChatterList;
