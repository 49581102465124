// frontend/src/containers/components/LogoutButton.js
import { useDispatch } from 'react-redux';  // Add this
import { logoutUser } from '../../redux/actions/authActions';
import { useAuth0 } from '@auth0/auth0-react';
import { setAuthToken } from '../../services/api';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();  // Use dispatch hook

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    dispatch(logoutUser());  // Clear Redux state after logout
    setAuthToken(null);
  };
  
  return (
    <button className="btn btn-link nav-link" onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;