// frontend/src/basicChatPage/redux/basicChatTimeActions.js

export const START_TIMER = 'START_TIMER';
export const PAUSE_TIMER = 'PAUSE_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const SET_PLAYBACK_TIME = 'SET_PLAYBACK_TIME';
export const SET_PLAYBACK_STATUS = 'SET_PLAYBACK_STATUS';

let timer = null; // Timer stored outside Redux

export const startTimer = () => (dispatch, getState) => {
  // Clear any existing timer
  if (timer) clearInterval(timer);

  dispatch({ type: SET_PLAYBACK_STATUS, payload: 'playing' }); // Set status to 'playing'

  // Start a new timer
  timer = setInterval(() => {
    const state = getState();
    const newTime = state.basicChat.playback.currentTime + 1;
    const timelineLength = state.basicChat.messages.timelineLength;

    if (newTime >= timelineLength) {
      dispatch(pauseTimer()); // Pause when the timeline ends
    } else {
      dispatch(setPlaybackTime(newTime)); // Increment the time
    }
  }, 1000); // Increment every second
};

export const pauseTimer = () => (dispatch) => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  dispatch({ type: SET_PLAYBACK_STATUS, payload: 'paused' });
};

export const stopTimer = () => (dispatch) => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  dispatch({ type: SET_PLAYBACK_STATUS, payload: 'stopped' });
  dispatch(setPlaybackTime(0)); // Reset the time to 0
};

export const setPlaybackTime = (time) => ({
  type: SET_PLAYBACK_TIME,
  payload: time // Payload contains the updated time
});
