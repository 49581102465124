// src/aboutpage/aboutPage.js

import React, { useState } from 'react';
import NavBar from '../components/common/NavBar';
import AboutMain from './components/aboutMain';
import AboutCreate from './components/aboutCreate';
import AboutLive from './components/aboutLive';
import AboutDev from './components/aboutDev';
import ContactSection from '../containers/components/ContactSection';


import './styles/aboutPage.css';

const AboutPage = () => {
  const [activeTab, setActiveTab] = useState('about-main');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderComponent = () => {
    switch (activeTab) {
        case 'about-main':
            return <AboutMain />;
        case 'about-create':
            return <AboutCreate />;
        case 'about-live':
            return  <AboutLive />;
        case 'about-dev':
            return <AboutDev />;

        default:
            return null;
    }
  };

  return (
    <div className="about-page-container">
      <NavBar />
      
      {/* Sidebar Toggle Button for Mobile */}
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarOpen ? 'Close Menu' : 'Open Menu'}
      </div>

      {/* Sidebar */}
      <div className={`about-sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <ul className="about-sidebar-menu">
          <li
            className={`about-sidebar-item ${activeTab === 'about-main' ? 'active' : ''}`}
            onClick={() => { setActiveTab('about-main'); setIsSidebarOpen(false); }}
          >
            Overview
          </li>
          <li
            className={`about-sidebar-item ${activeTab === 'about-create' ? 'active' : ''}`}
            onClick={() => { setActiveTab('about-create'); setIsSidebarOpen(false); }}
          >
            How to Use Create!
          </li>
          <li
            className={`about-sidebar-item ${activeTab === 'about-live' ? 'active' : ''}`}
            onClick={() => { setActiveTab('about-live'); setIsSidebarOpen(false); }}
          >
            How to use Live!
          </li>
          
          <li
            className={`about-sidebar-item ${activeTab === 'about-dev' ? 'active' : ''}`}
            onClick={() => { setActiveTab('about-dev'); setIsSidebarOpen(false); }}
          >
            Future Development
          </li>
        </ul>
      </div>
      
    {/* Main Content */}
    <div className="about-page-content">
      {renderComponent()}
    </div>
    <div className="about-page-footer">
      <ContactSection />
    </div>
  </div>
  );
};

export default AboutPage;
