// frontend/src/redux/reducers/liveSessionReducer.js

import {
    START_LIVE_SESSION,
    STOP_LIVE_SESSION,
    ADD_LIVE_MESSAGE,
    UPDATE_LIVE_SESSION,
    CLEAR_LIVE_MESSAGES,
    CLEAR_LIVE_SESSION_DATA,
    FETCH_CHATTERS_SUCCESS,
    TRIGGER_CHAT_EVENT,
    ADD_CONTEXT_ITEM,
    REMOVE_CONTEXT_ITEM,
    FETCH_CHATTERS_FAILURE,
    FETCH_CHATTERS_REQUEST,
    DELETE_CHATTER_REQUEST,
    DELETE_CHATTER_SUCCESS,
    DELETE_CHATTER_FAILURE,
    GENERATE_CHATTER_ANDYS_REQUEST,
    GENERATE_CHATTER_ANDYS_SUCCESS,
    GENERATE_CHATTER_ANDYS_FAILURE,
    UPDATE_CHATTER_REQUEST,
    UPDATE_CHATTER_SUCCESS,
    UPDATE_CHATTER_FAILURE,
    CREATE_UNASSIGNED_CHATTER_SUCCESS,
    CREATE_UNASSIGNED_CHATTER_FAILURE,
    CREATE_CHATTER_REQUEST,
    CREATE_CHATTER_SUCCESS,
    CREATE_CHATTER_FAILURE
} from '../actions/types';


const initialState = {
    isLive: false,
    sessionId: null,
    settings: {
        frequency: 30,
        tone: 'neutral',
        strictMode: true,
    },
    messages: [],
    events: [],
    context: [],
    chatters: [],
    loading: false,
    error: null, 
};

const liveSessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LIVE_SESSION:
            return {
                ...state,
                isLive: true,
            };
        
        case STOP_LIVE_SESSION:
            return {
                ...state,
                isLive: false,
            };

        case ADD_LIVE_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
            };

        case CLEAR_LIVE_MESSAGES:
            return {
                ...state,
                messages: [],
            };

        case UPDATE_LIVE_SESSION:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload,
                },
            };

        case CLEAR_LIVE_SESSION_DATA:
            return initialState;

        case FETCH_CHATTERS_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_CHATTERS_SUCCESS:
            return { ...state, chatters: action.payload, loading: false };
        
        case FETCH_CHATTERS_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case TRIGGER_CHAT_EVENT:
            return {
                ...state,
                events: [...state.events, action.payload],
            };

        case ADD_CONTEXT_ITEM:
            if (state.context.length >= 10) {
                return {
                    ...state,
                    context: [...state.context.slice(1), action.payload], // Remove the oldest, add new
                };
            }
            return {
                ...state,
                context: [...state.context, action.payload],
            };

        case REMOVE_CONTEXT_ITEM:
            return {
                ...state,
                context: state.context.filter((_, index) => index !== action.payload),
            };

        // Chatter Andys //
        case GENERATE_CHATTER_ANDYS_REQUEST:
            return { ...state, loading: true };

        case GENERATE_CHATTER_ANDYS_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                chatters: [...state.chatters, ...action.payload] 
            };

        case GENERATE_CHATTER_ANDYS_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case DELETE_CHATTER_REQUEST:
            return { ...state, loading: true };

        case DELETE_CHATTER_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                chatters: state.chatters.filter(chatter => chatter.id !== action.payload)
            };

        case DELETE_CHATTER_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case UPDATE_CHATTER_REQUEST:
            return { ...state, loading: true };

        case UPDATE_CHATTER_SUCCESS:
            return {
                ...state,
                chatters: state.chatters.map(chatter =>
                    chatter.id === action.payload.id ? { ...chatter, ...action.payload } : chatter
                )
            };

        case UPDATE_CHATTER_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case CREATE_UNASSIGNED_CHATTER_SUCCESS:
            return {
                ...state,
                chatters: [...state.chatters, action.payload],
            };

        case CREATE_UNASSIGNED_CHATTER_FAILURE:
            return { ...state, error: action.payload };

        case CREATE_CHATTER_REQUEST:
            return { ...state, loading: true };

        case CREATE_CHATTER_SUCCESS:
            return {
                ...state,
                chatters: [...state.chatters, action.payload],
                loading: false,
            };
        
        case CREATE_CHATTER_FAILURE:
            return { ...state, error: action.payload, loading: false };
            
        default:
            return state;
    }
};

export default liveSessionReducer;
