// frontend/src/containers/CallbackPage.js
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/actions/authActions';
import { setAuthToken } from '../services/api'; // Import setAuthToken

function CallbackPage() {
  const dispatch = useDispatch();
  const { handleRedirectCallback, getAccessTokenSilently, error, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthRedirect = async () => {
      const searchParams = new URLSearchParams(window.location.search);
    
      if (!searchParams.has('code') || !searchParams.has('state')) {
        // console.error("CallbackPage error: Missing Auth0 query params");
        navigate('/login');
        return;
      }
    
      try {
        await handleRedirectCallback();
        const accessToken = await getAccessTokenSilently();
        setAuthToken(accessToken);
        if (isAuthenticated && user) {
          dispatch(loginUser(user));
        }
        navigate('/');
      } catch (e) {
        // console.error("CallbackPage error:", e);
        navigate('/login');
      }
    };

    handleAuthRedirect();
  }, [isAuthenticated, user, dispatch, handleRedirectCallback, getAccessTokenSilently, navigate, location.search]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return <div>Logging you in...</div>;
}

export default CallbackPage;
