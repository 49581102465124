// frontend/src/components/LivePage/LivePageUtils.js

export const badgeOptions = {
    rope: [
        { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3' },
        { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3' }
    ],
    sub: [
        { id: 2, label: 'Tier 1', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3' },
        { id: 4, label: 'Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3' },
        { id: 5, label: 'Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3' }
    ],
    global: [
        { id: 3, label: 'Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3' }
    ]
};
  // Utility function to sort badges in the correct order
  export const sortBadges = (badgeIds) => {
    const ropeBadges = badgeIds.filter((id) =>
      badgeOptions.rope.some((badge) => badge.id === id)
    );
    const subBadges = badgeIds.filter((id) =>
      badgeOptions.sub.some((badge) => badge.id === id)
    );
    const globalBadges = badgeIds.filter((id) =>
      badgeOptions.global.some((badge) => badge.id === id)
    );
  
    // Return badges in the correct order: rope, sub, global
    return [...ropeBadges, ...subBadges, ...globalBadges];
  };
  