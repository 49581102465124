//frontend/src/basicChatPage/redux/basicChatChatterActions.js
import { ADD_CHATTER, UPDATE_CHATTER, CLEAR_CHATTERS, SELECT_CHATTER, DELETE_CHATTER, SET_CHATTER_BIO_SUCCESS, SET_CHATTER_ARCHETYPE_SUCCESS } from "./basicChatTypes";

export const addChatter = (chatter) => {
    return {
        type: ADD_CHATTER,
        payload: chatter
    }
}

export const updateChatter = (chatterID, chatterData) => ({
    type: UPDATE_CHATTER,
    payload: { chatterID, chatterData }
});

export const deleteChatter = (chatterID) => ({
    type: DELETE_CHATTER,
    payload: chatterID
});

export const selectChatter = (chatterId) => ({
    type: SELECT_CHATTER,
    payload: chatterId
});

export const clearChatters = () => ({
    type: CLEAR_CHATTERS
});

export const setChatterArchetype = (chatterID, archetype) => ({
    type: SET_CHATTER_ARCHETYPE_SUCCESS,
    payload: { chatterID, archetype }
});

export const setChatterBio = (chatterID, customMessage) => ({
    type: SET_CHATTER_BIO_SUCCESS,
    payload: { chatterID, customMessage }
});