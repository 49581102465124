// frontend/src/containers/LoginPage.js
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/actions/authActions';
import './LoginPage.css';


function LoginPage() {
  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  // If authenticated, store the user info in Redux
  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(loginUser(user));
    }
  }, [isAuthenticated, user, dispatch]);

  return (
    <div className="login-wrapper-login">
    <div className="container-login">
      <div className="row-login">
        <div className="col-login">
          <div className="card-login">
            <div className="card-body-login">
              <button 
                className="login-button-login" 
                onClick={() => loginWithRedirect()}
              >
                Log in with Auth0
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default LoginPage;
