//frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

// Root element
const root = ReactDOM.createRoot(document.getElementById('root'));
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

root.render(
  //<React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: 'https://streamchatandy.com/callback',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        state: 'streamchatandy-state',  // Ensure 'offline_access' is included for refresh tokens
      }}
      useRefreshTokens={true}  // Enable rotating refresh tokens
      cacheLocation="localstorage"  // Store tokens in localStorage for persistence across sessions
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Auth0Provider>
  //</React.StrictMode>
);

reportWebVitals();
