// frontend/src/basicChatPage/utils/basicChatUtils.js
// frontend/src/basicChatPage/utils/basicChatUtils.js

// Function to check if a username is unique and append numbers if necessary
export const generateUniqueUsername = (baseUsername, chatters) => {
    let counter = 1;

    // Helper function to check if a username exists
    const isUsernameTaken = (username) => {
        return chatters.some(chatter => chatter.username === username);
    };

    let uniqueUsername = baseUsername;

    // Loop to find a unique username
    while (isUsernameTaken(uniqueUsername)) {
        uniqueUsername = `${baseUsername}_${counter}`;
        counter++;
    }

    return uniqueUsername;
};


// Time conversion utility (seconds to mm:ss)
export const secondsToMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};