// frontend/src/basicChatPage/redux/basicChatPlaybackReducer.js

import { SET_PLAYBACK_TIME, SET_PLAYBACK_STATUS, RESET_PLAYBACK } from "./basicChatTypes";

const initialState = {
  currentTime: 0,
  playbackStatus: 'stopped', // 'playing', 'paused', or 'stopped'
  speed: 1 // Optional, can manage speed for future flexibility
};

const basicChatPlaybackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYBACK_TIME:
      return {
        ...state,
        currentTime: action.payload, // Update the time
      };

    case SET_PLAYBACK_STATUS:
      return {
        ...state,
        playbackStatus: action.payload, // Update the status
      };

    case RESET_PLAYBACK:
      return {
        ...state,
        currentTime: 0,
        playbackStatus: 'stopped',
      };

    default:
      return state;
  }
};

export default basicChatPlaybackReducer;
