// frontend/src/components/LivePage/LivePlaybackComponent.js
import React, { useEffect, useRef, useState } from 'react';
import FakeChatDisplay from '../common/fakeChatDisplay';
import './LivePlaybackComponent.css';

const LivePlaybackComponent = ({ messages = [], onMessagesUpdated, showTimestamps, darkMode }) => {
  const messagesContainerRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (!isUserScrolling && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }

    if (onMessagesUpdated) {
      onMessagesUpdated(messages);
    }
  }, [messages, onMessagesUpdated, isUserScrolling]);

  // Handle user scrolling
  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      // If the user is near the bottom, auto-scroll is enabled. Otherwise, stop auto-scroll.
      setIsUserScrolling(scrollHeight - scrollTop > clientHeight + 50);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div className={`live-chat-playback ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div
        className="live-chat-messages"
        ref={messagesContainerRef}
        onScroll={handleScroll} // Capture scroll events
      >
        {messages
          .slice() // Create a copy of the messages array to avoid mutating it
          .reverse() // Reverse the message order
          .map((message, index) => (
            <div key={index} className="live-chat-message">
              {/* Conditional rendering for timestamp */}
              {showTimestamps && <span className="chat-timestamp"> {formatTimestamp(message.timestamp)}</span>}
              {message.badge_urls && message.badge_urls.map((url, idx) => (
                <img key={idx} src={url} alt="badge" className="chat-badge-img" />
              ))}              
              <span className="chat-username" style={{ color: message.chatter_color }}>
                {message.chatter_name || 'Unknown'}
              </span>
              <span className="chat-colon">:</span>
              <span className="chat-content" dangerouslySetInnerHTML={{ __html: message.message }} />
            </div>
          ))}
      </div>
      <div className="fake-chat-display-div"><FakeChatDisplay /></div>
      
    </div>
  );
};

export default LivePlaybackComponent;
