// frontend/src/containers/HomePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from '../components/common/NavBar';
import StreamChatAndyHomePageCreateGif from '../assets/icons/StreamChatAndyHomePageCreateGif.gif';
import LeftHeaderGif from '../assets/icons/left_header.gif';
import RightHeaderGif from '../assets/icons/right_header.gif';
import Carosel_Live_Chats from '../assets/icons/carosel_live_chats.gif';
import Carosel_Live_Main from '../assets/icons/carosel_live_main.gif';
import ContactSection from './components/ContactSection';
import { useAuth0 } from '@auth0/auth0-react';

function HomePage() {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleNavigateToCreate = () => {
    navigate('/create');
  };

  const handleNavigateToLive = () => {
    if (isAuthenticated) {
      navigate('/live');
    } else {
      loginWithRedirect();
    }
  };

  const handleNavigateToAccount = () => {
    if (isAuthenticated) {
      navigate('/account');
    } else {
      loginWithRedirect();
    }
  };

  useEffect(() => {
    document.body.classList.add('no-scroll'); // Add class on mount
    return () => {
      document.body.classList.remove('no-scroll'); // Remove class on unmount
    };
  }, []);

  return (
    <div className="homepage-container">
      <Navbar />
      <div className="home-page-content">
        {/* Left and Right GIFs */}
        <img src={LeftHeaderGif} alt="Left Header" className="header-gif left-gif" />
        
        <header className="header-section">
          <div className="header-wrapper">
            <div className="header-text">
              <h1 className="header-title">
                Chat is the core of the streaming experience. <strong>Not seeing enough movement down there?</strong>
              </h1>
              <div className="header-description">
                <h5>Simulate and stimulate interaction in your chat by blending your <strong>real Twitch chat</strong> with <strong>AI-generated chatters</strong> seamlessly on the Live Dashboard Page.</h5>
                <p>View the chat on the dashboard, a popout, or even an OBS Overlay Browser Source. </p>
                <p>The best part? It's all hosted on this site! There's only a listening Bot in your real Twitch chat. So your <strong>real chatters'</strong> experience is preserved! Available to susbcribers</p>
                <p>The create page lets you manually create a spoofed twitch chat -- message by message -- user by user. For FREE</p>
              </div>
            </div>
          </div>
        </header>

        <img src={RightHeaderGif} alt="Right Header" className="header-gif right-gif" />

        {/* Carousel Section */}
        <section className="carousel-section">
          <div className="carousel-container">
            <div id="homepageCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="42000">
              <div className="carousel-inner">

                <div className="carousel-item active">
                <h2 className="carousel-title">Live Stream with AI messages to stimulate interaction!</h2>
                  <p className="carousel-description">Play them back on the dashboard or in a popout for your recording pleasure</p>
                  <div className="carousel-button-wrapper">
                    
                  </div>
                  <img src={Carosel_Live_Chats} className="carousel-image" alt="Live GIF" />

                </div>
                <div className="carousel-item">
                  <h2 className="carousel-title"><strong>Create! Page</strong></h2>
                  <p className="carousel-description">Design custom chat timelines for pre-recorded content. Save and play back chat timelines on demand -- great for VOD and offline content.</p>
                  <div className="carousel-button-wrapper">
                    <button className="btn btn-primary" onClick={handleNavigateToCreate}>Get Started</button>
                  </div>
                  <img src={StreamChatAndyHomePageCreateGif} className="carousel-image" alt="Create GIF" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#homepageCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#homepageCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>

        </section>

        

        {/* Services Section */}
        <section className="services-section">
          <div className="services-container">
            <h2 className="services-title">Services</h2>
            <div className="services-cards">
              <div className="service-card" onClick={handleNavigateToCreate}>
                <h3 className="service-card-title">Create! Page</h3>
                <p className="service-card-description">The Create! Page is free manually but <strong>you can only use AI Generation or save your work if you become a paid subscriber</strong>.</p>
              </div>
              <div className="service-card" onClick={handleNavigateToLive}>
                <h3 className="service-card-title">Live! Page</h3>
                <p className="service-card-description">The Live! Page is <strong>only available to paid subscribers</strong>. It consumes Tokens during usage due to AI being powered by GPT 4 and GPT 4oMini.</p>
              </div>
              <div className="service-card" onClick={handleNavigateToAccount}>
                <h3 className="service-card-title">Subscription</h3>
                <p className="service-card-description"> Approx 20 hours of LIVE Service, and 200 Create! AI Chat Generations. Monthly the subscription price is:</p>
                <p className="service-card-description-price"> <strong>$9.99</strong></p>
                <div className="click-here-homepage-subscribe"> 
                  <p>SUBSCRIBE!</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact-section">
          <ContactSection />
        </section>
      </div>
    </div>
  );
}

export default HomePage;
