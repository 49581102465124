// src/aboutpage/components/aboutLive.js

import React from 'react';
import '../styles/aboutLive.css';

const AboutLive = () => {
  return (
    <div className="about-live-container">
      <h2 className="about-live-title">Using the Live Page</h2>
      <div className="about-create-content">
      <div className="video-container-about">
          <iframe
            width="719"
            height="408"
            src="https://www.youtube.com/embed/VDYxCCUwn0c"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      
      <div className="about-live-content">
      <div className="live-section">
        <h3 className="section-heading">Central Panel</h3>
        <p>
        The start/stop <strong>AI Messages</strong> control lets you start or stop AI-generated messages. On the right, the <strong>Twitch Listener</strong> section connects directly to your Twitch chat. Connect your twitch account and type /mod MrFerretAi into your twitch dashboard.
      </p>
      <h3 className="section-heading">Tokens</h3>
      <p> You must have more tokens than the estimate cost to start the AI generated messages, they will stop if you go below.</p>
      </div>

        <div className="live-section">
          <h3 className="section-heading">OBS Integration for Streaming</h3>
          <p>
            For OBS users, you can integrate the live chat feed by generating, or copying an already generated, <strong>OBS Overlay URL</strong> then adding it as a browser source in OBS. This overlay displays a blend of real and AI chat messages, giving the appearance of a busy chat room. Alternatively, you can use the <strong>popout window</strong> to monitor or record the chat separately. If needed, click <strong>Generate New URL</strong> to refresh the overlay link. This will break previous links.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutLive;
