// frontend/src/containers/components/LoginButton.js
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button className="btn btn-link nav-link" onClick={() => loginWithRedirect()}>
      Login/Register
    </button>
  );
};

export default LoginButton;
