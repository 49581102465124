// frontend/src/redux/selectors.js

import { createSelector } from 'reselect';

const getSessionsState = state => state.session.sessions.sessions; // Direct access to the sessions array
const getMessagesState = state => state.session.messages.messages; // Direct access to the messages array
const getSessionId = (_, sessionId) => sessionId; // Additional argument for sessionId

const getChattersState = state => state.liveSessionReducer.chatters; // Direct access to the chatters array
const getChatterId = (_, chatterId) => chatterId; // Additional argument for chatterId


export const getSessions = createSelector(
  [getSessionsState],
  sessions => sessions
);

export const getMessagesForSession = createSelector(
  [getMessagesState, getSessionId],
  (messages, sessionId) => Array.isArray(messages) ? messages.filter(message => message.session === sessionId).sort((a, b) => a.display_time - b.display_time) : []
);

export const getSessionById = createSelector(
  [getSessionsState, getSessionId],
  (sessions, sessionId) => sessions.find(session => session.id === sessionId)
);

export const getMessageById = createSelector(
  [getMessagesState, (_, messageId) => messageId],
  (messages, messageId) => messages.find(message => message.id === messageId)
);

export const getCurrentLiveSession = createSelector(
  [getSessionsState],
  (sessions) => sessions.find(session => session.is_live_session === true) || null
);

export const getChatterById = createSelector(
  [getChattersState, getChatterId],
  (chatters, chatterId) => chatters.find(chatter => chatter.id === chatterId)
);


export const getSubscriptionStatus = (state) => state.auth.isPaidSubscriber;

// Selector to get token balance
export const getTokenBalanceState = (state) => state.auth.tokenBalance;

// You can also add loading and error selectors for subscription and token balance
export const getSubscriptionLoading = (state) => state.auth.subscriptionLoading;
export const getSubscriptionError = (state) => state.auth.subscriptionError;

export const getTokenLoading = (state) => state.auth.tokenLoading;
export const getTokenError = (state) => state.auth.tokenError;

