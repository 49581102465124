//frontend/src/basicChatPage/components/basicChatTimeline.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicChatMessage from './basicChatMessage';
import { getAllMessagesSorted } from '../redux/basicChatSelectors';  // Import the selector
import {updateTimelineLength, } from '../redux/basicChatMessageActions';
import BasicChatTimeblock from './basicChatTimeblock';  // Import the TimeBlock component
import '../styles/basicChatTimeline.css';
import { clearMessages } from '../redux/basicChatMessageActions';

const BasicChatTimeline = () => {
    const dispatch = useDispatch();
    const messages = useSelector(getAllMessagesSorted);
    const [localTimeBlocks, setLocalTimeBlocks] = useState([]);
    const timelineRef = useRef(null); 

    // Function to insert time blocks between messages based on gaps
    const insertTimeBlocks = useCallback(() => {
        const newTimeBlocks = [];
        for (let i = 0; i < messages.length - 1; i++) {
            const currentMessage = messages[i];
            const nextMessage = messages[i + 1];
            const gap = nextMessage.display_time - currentMessage.display_time;

            if (gap > 1.0) {
                newTimeBlocks.push({
                    id: `local-${i}`,
                    display_time: currentMessage.display_time + 1.0,
                    duration: gap - 1.0
                });
            }
        }
        setLocalTimeBlocks(newTimeBlocks);
    }, [messages]);

    // Recalculate time blocks whenever messages change
    useEffect(() => {
        insertTimeBlocks();
    }, [messages, insertTimeBlocks]);

    const timelineItems = [...messages, ...localTimeBlocks].sort((a, b) => a.display_time - b.display_time);

    // Scroll to the bottom when new messages are added
    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
        }
    }, [timelineItems]);

    const handleResetMessages = () => {
        if (window.confirm("Are you sure you want to clear all messages? This action cannot be undone.")) {
            dispatch(clearMessages());
            dispatch(updateTimelineLength(0));
        }
    };

    return (
        <div className="basic-chat-timeline" ref={timelineRef}>
            <div className="timeline-header">
                <button className="btn btn-sm btn-danger custom-clear-btn" onClick={handleResetMessages}>
                    Clear All Messages
                </button>
            </div>
            <ul>
            {timelineItems.map((item) =>
                item.content !== undefined ? (
                    <li key={item.id}>
                    <BasicChatMessage message={item} />
                    </li>
                ) : (
                    <li key={item.id}>
                    <BasicChatTimeblock display_time={item.display_time} duration={item.duration} />
                    </li>
                )
                )}
            </ul>
            
        </div>
    );
};

export default BasicChatTimeline;