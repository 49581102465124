//frontend/src/basicChatPage/components/basicChatEmotePicker.js
import React, { useEffect, useState } from 'react';
import '../styles/basicChatEmotePicker.css';

const emoteMap = {
  "LUL": "https://static-cdn.jtvnw.net/emoticons/v2/425618/static/light/3.0",
  "PogChamp": "https://static-cdn.jtvnw.net/emoticons/v2/305954156/static/light/3.0",
  "Kappa": "https://static-cdn.jtvnw.net/emoticons/v2/25/static/light/3.0",
  "SwiftRage": "https://static-cdn.jtvnw.net/emoticons/v2/34/static/light/3.0",
  "DansGame": "https://static-cdn.jtvnw.net/emoticons/v2/33/static/light/3.0",
  "PJSalt": "https://static-cdn.jtvnw.net/emoticons/v2/36/static/light/3.0"
};

const EmotePicker = ({ onSelectEmote, isVisible, emoteButtonRef }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isVisible && emoteButtonRef.current) {
      const rect = emoteButtonRef.current.getBoundingClientRect();
      setPosition({ top: rect.bottom + 5, left: rect.left }); // Position below the button with a small offset
    }
  }, [isVisible, emoteButtonRef]);

  if (!isVisible) return null;

  return (
    <div className="emote-picker" style={{ top: position.top, left: position.left }}>
      {Object.entries(emoteMap).map(([name, url]) => (
        <img
          key={name}
          src={url}
          alt={name}
          className="emote-icon"
          onClick={() => onSelectEmote(name)}
        />
      ))}
    </div>
  );
};

export default EmotePicker;
