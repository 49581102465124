//frontend/src/components/common/fakeChatDisplay.js
import React from 'react';  // React with capital 'R'
import diamondIcon from '../../assets/icons/diamond.png';
import coneIcon from '../../assets/icons/cone.png';
import chatSmileyIcon from '../../assets/icons/chat_smiley.png';
import gearIcon from '../../assets/icons/gear.png';
import './fakeChatDisplay.css';  // Fixed the path to the CSS file

// Fixed the component name to be capitalized
const FakeChatDisplay = ({ darkMode, toggleDarkMode }) => {
    return (
        <div className="fake-chat-component">
          {/* Fake Chat Input Area */}
          <div className="fake-chat-input-container">
            <input type="text" className="fake-chat-input" placeholder="Send a message" />
            <div className="chat-input-icons">
              <img src={coneIcon} alt="cone icon" className="icon-bits" />
              <img src={chatSmileyIcon} alt="smiley icon" className="icon-emotes" />
            </div>
          </div>
    
          {/* Fake Button Area */}
          <div className="chat-bottom-bar">
            <div className="fake-counter">
              <img src={diamondIcon} alt="diamond icon" className="icon-diamond" />
              <span>0</span>
            </div>
            <div className="fake-settings" onClick={toggleDarkMode}> {/* Click to toggle dark mode */}
              <img src={gearIcon} alt="settings icon" className="icon-settings" />
            </div>
            <button className="fake-chat-button">Chat</button>
          </div>
        </div>
      );
    };
    
    export default FakeChatDisplay;









