// frontend/src/basicChatPage/components/basicChatPlayback.js
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startTimer, pauseTimer, stopTimer, setPlaybackTime } from '../redux/basicChatTimeActions';
import { getTimelineLength } from '../redux/basicChatSelectors';
import FakeChatDisplay from '../../components/common/fakeChatDisplay';
import { BiExpand } from 'react-icons/bi'
import BasicPlaybackTimeBar from './basicChatPlaybackTimeBar';
import '../styles/basicChatPlayback.css';

const twitchBadgeIcon = 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3';

const emoteMap = {
  "LUL": "https://static-cdn.jtvnw.net/emoticons/v2/425618/static/light/3.0",
  "PogChamp": "https://static-cdn.jtvnw.net/emoticons/v2/305954156/static/light/3.0",
  "Kappa": "https://static-cdn.jtvnw.net/emoticons/v2/25/static/light/3.0",
  "SwiftRage": "https://static-cdn.jtvnw.net/emoticons/v2/34/static/light/3.0",
  "DansGame": "https://static-cdn.jtvnw.net/emoticons/v2/33/static/light/3.0",
  "PJSalt": "https://static-cdn.jtvnw.net/emoticons/v2/36/static/light/3.0"
};

const BasicChatPlayback = () => {
  const dispatch = useDispatch();
  const playbackStatus = useSelector((state) => state.basicChat.playback.playbackStatus);
  const currentTime = useSelector((state) => state.basicChat.playback.currentTime);
  const messageList = useSelector((state) => state.basicChat.messages.messageList);
  const chatters = useSelector((state) => state.basicChat.chatters.chatterList);
  const timelineLength = useSelector(getTimelineLength);
  const messagesEndRef = useRef(null);
  const [popoutWindow, setPopoutWindow] = useState(null);
  const [darkMode, setDarkMode] = useState(false); 

  const openPopout = () => {
    const popout = window.open('/basicPopout', 'basicPopout', 'width=400,height=600,toolbar=no,location=no,menubar=no,status=no');
    if (popout) {
      setPopoutWindow(popout);
      // Send the initial dark mode state when popout opens
      popout.postMessage({
        type: 'UPDATE_DARK_MODE',
        darkMode,
      }, '*');
    }
};

  // Function to get badge URLs
  const getBadgeUrls = (badgeIds = []) => {
    const badgeOptions = [
      { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3', category: 'rope' },
      { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3', category: 'rope' },
      { id: 2, label: 'Subscriber', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3', category: 'sub' },
      { id: 3, label: 'Twitch Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3', category: 'global' },
      { id: 4, label: 'Subscriber Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3', category: 'sub' },
      { id: 5, label: 'Subscriber Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3', category: 'sub' },
      { id: 7, label: 'None', imageUrl: null, category: 'none' }
    ];

    return badgeOptions.filter(badge => badgeIds.includes(badge.id)).map(badge => badge.imageUrl);
  };

  // Function to parse emotes in message content
  const parseEmotes = (messageContent) => {
    const words = messageContent.split(' ');

    return words.map((word, index) => {
      if (emoteMap[word]) {
        return (
          <img 
            key={index} 
            src={emoteMap[word]} 
            alt={word} 
            className="emote-img" 
            style={{ width: '24px', height: '24px', verticalAlign: 'middle' }}
          />
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  const handleTimeChange = (newTime) => {
    dispatch(setPlaybackTime(newTime)); // Update time on drag
  };

  const getMessagesForCurrentTime = () => {
    return messageList
      .filter((message) => message.display_time <= currentTime)
      .sort((a, b) => a.display_time - b.display_time)
      .map((message) => {
        const chatter = chatters.find((chatter) => chatter.id === message.chatter_id);
        const badgeUrls = chatter ? getBadgeUrls(chatter.badge_ids || []) : [];
        return { ...message, badge_urls: badgeUrls }; // Include badge URLs
      });
  };
  

  const renderedMessages = getMessagesForCurrentTime();
  const progressPercentage = timelineLength > 1 ? (currentTime / (timelineLength - 1)) * 100 : 0;

  useEffect(() => {
    if (popoutWindow && !popoutWindow.closed) {
      popoutWindow.postMessage({
        type: 'UPDATE_MESSAGES',
        payload: renderedMessages,
        darkMode,  // Pass the dark mode state along with messages
      }, '*');
    }
}, [renderedMessages, popoutWindow, currentTime, darkMode]);



  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      {/* Container for popout and dark mode toggle */}
      <div className="controls-container">
      <div className="basic-popout-button-container">
        <button className="basicchat-popout-button" onClick={openPopout}>
          <BiExpand size={24} />
        </button>
        </div>
        <div className="dark-mode-toggle-container">
          <label className="dark-mode-label">Dark Mode</label>
          <label className="switch">
            <input type="checkbox" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      {/* Chat playback container */}
      <div className="basicchat-playback-container">
        <div className="basicchat-messages-container">
          {renderedMessages.map((message) => (
            <div key={message.id} className="basicchat-message">
              {message.badge_urls && message.badge_urls.map((url, idx) => (
                <img key={idx} src={url} alt="badge" className="chat-badge-img" />
              ))}
              <span className="basicchat-username" style={{ color: message.color_code }}>
                <strong>{message.chatter_name}</strong>
              </span>
              <span className="basicchat-colon">:</span>
              <span className="basicchat-content">{parseEmotes(message.content)}</span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="fake-chat-display-div-create"><FakeChatDisplay /></div>

      <div className="TimeBar-Container-Create">
      <BasicPlaybackTimeBar
        progressPercentage={progressPercentage}
        currentTime={currentTime}
        timelineLength={timelineLength}
        playbackStatus={playbackStatus}
        stopTimer={() => dispatch(stopTimer())}
        handleTimeChange={handleTimeChange}
        startTimer={() => dispatch(startTimer())}
        pauseTimer={() => dispatch(pauseTimer())}
      />
    </div>
    </div>
  
  );
};

export default BasicChatPlayback;