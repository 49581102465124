//frontend/src/basicChatPage/redux/basicChatTypes.js

export const ADD_CHATTER = 'ADD_CHATTER';
export const UPDATE_CHATTER = 'UPDATE_CHATTER';
export const DELETE_CHATTER = 'DELETE_CHATTER';
export const SELECT_CHATTER = 'SELECT_CHATTER';
export const CLEAR_CHATTERS = 'CLEAR_CHATTERS';



export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SELECT_MESSAGE = 'SELECT_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const UPDATE_TIMELINELENGTH = 'UPDATE_TIMELINELENGTH';



// PlaybackComponent
export const SET_PLAYBACK_TIME = 'SET_PLAYBACK_TIME';
export const SET_PLAYBACK_STATUS = 'SET_PLAYBACK_STATUS';
export const RESET_PLAYBACK = 'RESET_PLAYBACK';

export const SAVE_SESSION_SUCCESS = 'SAVE_SESSION_SUCCESS';
export const SAVE_SESSION_FAILURE = 'SAVE_SESSION_FAILURE';
export const SAVE_SESSION_REQUEST = 'SAVE_SESSION_REQUEST'; 
export const LOAD_SESSIONS_SUCCESS = 'LOAD_SESSIONS_SUCCESS';
export const LOAD_SESSIONS_FAILURE = 'LOAD_SESSIONS_FAILURE';
export const LOAD_SESSIONS_REQUEST = 'LOAD_SESSIONS_REQUEST';


export const LOAD_SESSION_BY_ID_SUCCESS = 'LOAD_SESSION_BY_ID_SUCCESS';
export const LOAD_SESSION_BY_ID_FAILURE = 'LOAD_SESSION_BY_ID_FAILURE';
export const LOAD_SESSION_BY_ID_REQUEST = 'LOAD_SESSION_BY_ID_REQUEST';

export const CLEAR_CHAT_STATE = 'CLEAR_CHAT_STATE';
export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';

export const START_TIMER = 'START_TIMER';
export const PAUSE_TIMER = 'PAUSE_TIMER';
export const STOP_TIMER =  'STOP_TIMER';

export const SET_CHATTER_ARCHETYPE_SUCCESS = 'SET_CHATTER_ARCHETYPE_SUCCESS';
export const SET_CHATTER_ARCHETYPE_REQUEST = 'SET_CHATTER_ARCHETYPE_REQUEST';
export const SET_CHATTER_ARCHETYPE_FAILURE = 'SET_CHATTER_ARCHETYPE_FAILURE';

export const SET_CHATTER_BIO_SUCCESS = 'SET_CHATTER_BIO_SUCCESS';
export const SET_CHATTER_BIO_REQUEST = 'SET_CHATTER_BIO_REQUEST';
export const SET_CHATTER_BIO_FAILURE = 'SET_CHATTER_BIO_FAILURE';