// frontend/src/components/SessionEditor/ChatterSettings.js
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Tabs, Tab } from 'react-bootstrap'; 
import { updateChatter, } from '../../redux/actions/LiveSessionActions'; 
import './ChatterSettings.css';

const badgeOptions = {
  rope: [
      { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3' },
      { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3' }
  ],
  sub: [
      { id: 2, label: 'Tier 1', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3' },
      { id: 4, label: 'Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3' },
      { id: 5, label: 'Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3' }
  ],
  global: [
      { id: 3, label: 'Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3' }
  ]
};

// Predefined color options
const default_colors = [
  ["Red", "#FF0000"],
  ["Blue", "#0000FF"],
  ["Green", "#00FF00"],
  ["FireBrick", "#B22222"],
  ["Coral", "#FF7F50"],
  ["YellowGreen", "#9ACD32"],
  ["OrangeRed", "#FF4500"],
  ["SeaGreen", "#2E8B57"],
  ["GoldenRod", "#DAA520"],
  ["Chocolate", "#D2691E"],
  ["CadetBlue", "#5F9EA0"],
  ["DodgerBlue", "#1E90FF"],
  ["HotPink", "#FF69B4"],
  ["BlueViolet", "#8A2BE2"],
  ["SpringGreen", "#00FF7F"]
];

const archetypeOptions = [
  { value: 'hype_man', label: 'The Hype Man' },
  { value: 'advisor', label: 'The Advisor' },
  { value: 'sarcastic_observer', label: 'The Sarcastic Observer' },
  { value: 'nostalgic_fan', label: 'The Nostalgic Fan' },
  { value: 'troll', label: 'The Troll' },
  { value: 'contrarian', label: 'The Contrarian' },
  { value: 'know_it_all', label: 'The Know-It-All' },
  { value: 'lurker', label: 'The Lurker' },
  { value: 'cynic', label: 'The Cynic' },
  { value: 'memer', label: 'The Memer' },
];

const ChatterSettings = ({ chatterId, closeModal }) => {  
  const dispatch = useDispatch();

  // Fetch the chatter from Redux using the chatterId
  const chatter = useSelector(state => state.liveSessionReducer.chatters.find(c => c.id === chatterId));

  // State for local updates
  const [username, setUsername] = useState(chatter?.username || '');
  const [badgeIds, setBadgeIds] = useState(chatter?.badge_ids || []);
  const [color, setColor] = useState(chatter?.color_code || '#FFFFFF');
  const [archetype, setArchetype] = useState(chatter?.archetype || 'hype_man');
  const [customMessage, setCustomMessage] = useState(chatter?.custom_message || '');
  const [frequency, setFrequency] = useState(chatter?.frequency || 2);
  const [key, setKey] = useState('general');



  const handleBadgeChange = (badgeId, group) => {
    const otherGroupBadges = badgeOptions[group].map(badge => badge.id);
    if (badgeIds.includes(badgeId)) {
      setBadgeIds(badgeIds.filter(id => id !== badgeId));
    } else {
      const updatedBadges = badgeIds.filter(id => !otherGroupBadges.includes(id));
      setBadgeIds([...updatedBadges, badgeId]);
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleColorSelect = (selectedColor) => {
    setColor(selectedColor); // Set the selected color
  };

  const handleArchetypeChange = (e) => {
    setArchetype(e.target.value);
  };

  const handleCustomMessageChange = (e) => {
    setCustomMessage(e.target.value);
  };

  const handleFrequencyChange = (e) => {
    setFrequency(parseInt(e.target.value));
  };

  const handleSave = async () => {
    try {
      await dispatch(updateChatter(chatterId, {
        username,
        badge_ids: badgeIds,
        color_code: color,
        archetype,
        custom_message: customMessage,
        frequency,
      }));
      closeModal();  // Close the modal after saving
    } catch (error) {
      // console.error('Error saving chatter:', error);
    }
  };

  const getBadgeUrls = () => {
    let ropeBadge = null;
    let subBadge = null;
    let globalBadge = null;

    badgeIds.forEach(badgeId => {
      const badge = Object.values(badgeOptions).flat().find(badge => badge.id === badgeId);
      if (badge) {
        if (badgeOptions.rope.some(b => b.id === badgeId)) {
          ropeBadge = badge.imageUrl;
        } else if (badgeOptions.sub.some(b => b.id === badgeId)) {
          subBadge = badge.imageUrl;
        } else if (badgeOptions.global.some(b => b.id === badgeId)) {
          globalBadge = badge.imageUrl;
        }
      }
    });

    return [ropeBadge, subBadge, globalBadge].filter(Boolean);
  };


  if (!chatter) {
    return <p>Chatter not found</p>;
  }

  return (
    <div className="chatter-settings-container">
      <h3>Chatter Settings</h3>
      <Tabs
        id="chatter-settings-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        {/* General Settings */}
        <Tab eventKey="general" title="General">
          <div className="general-settings">

            {/* Username Input and Badge Display */}
            <div className="input-row">
              
              <div className="username-input-container">
                <div className="selected-badges">
                  {getBadgeUrls().map((url, index) => (
                    <img key={index} src={url} alt="badge" className="badge-icon" />
                  ))}
                </div>
                <input
                  type="text"
                  className="form-control"
                  style={{ color: color }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            {/* Color Selection Grid (Two rows) */}
            <label>Color:</label>
            <div className="color-selection-grid">
              <div className="color-row">
                {default_colors.slice(0, 8).map(([colorName, colorValue], index) => (
                  <div
                    key={index}
                    className="color-square"
                    style={{ backgroundColor: colorValue }}
                    onClick={() => handleColorSelect(colorValue)} // Select color on click
                  ></div>
                ))}
              </div>
              <div className="color-row">
                {default_colors.slice(8).map(([colorName, colorValue], index) => (
                  <div
                    key={index}
                    className="color-square"
                    style={{ backgroundColor: colorValue }}
                    onClick={() => handleColorSelect(colorValue)} // Select color on click
                  ></div>
                ))}
              </div>
            </div>

            {/* Badge Selection */}
            <div className="badge-group">
              <label>Badges:</label>
              <div className="badge-selection-grid">
                {Object.keys(badgeOptions).map(group => (
                  <div key={group}>
                    
                    <div className="badge-options-row">
                      {badgeOptions[group].map(option => (
                        <div
                          key={option.id}
                          className={`badge-option ${badgeIds.includes(option.id) ? 'selected-badge' : ''}`}
                          onClick={() => handleBadgeChange(option.id, group)}
                        >
                          <img src={option.imageUrl} alt={option.label} className="badge-icon" />
                          <label>{option.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Tab>

        {/* Live Chatter Settings */} 
        <Tab eventKey="live" title="LIVE">
          <div>
            <h4>LIVE Chatter Settings</h4>

            <div>
              <label>Archetype:</label>
              <select
                className="form-select"
                value={archetype}
                onChange={handleArchetypeChange}
              >
                {archetypeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label>Custom Message (128 characters max):</label>
              <textarea
                className="form-control"
                maxLength="128"
                value={customMessage}
                onChange={handleCustomMessageChange}
                rows="3"
              />
            </div>

            <div>
              <label>Chatter Frequency:</label>
              <select
                className="form-select"
                value={frequency}
                onChange={handleFrequencyChange}
              >
                <option value={1}>Infrequent</option>
                <option value={2}>Normal</option>
                <option value={3}>Frequent</option>
              </select>
            </div>
          </div>
        </Tab>
      </Tabs>

      <div className="modal-footer">
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ChatterSettings;