// frontend/src/basicChatPage/component/basicPopoutChat.js
import React, { useEffect, useState, useRef } from 'react';
import '../styles/basicChatPopout.css';
import FakeChatDisplay from '../../components/common/fakeChatDisplay';

const twitchBadgeIcon = 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3';

const emoteMap = {
  "LUL": "https://static-cdn.jtvnw.net/emoticons/v2/425618/static/light/3.0",
  "PogChamp": "https://static-cdn.jtvnw.net/emoticons/v2/305954156/static/light/3.0",
  "Kappa": "https://static-cdn.jtvnw.net/emoticons/v2/25/static/light/3.0",
  "SwiftRage": "https://static-cdn.jtvnw.net/emoticons/v2/34/static/light/3.0",
  "DansGame": "https://static-cdn.jtvnw.net/emoticons/v2/33/static/light/3.0",
  "PJSalt": "https://static-cdn.jtvnw.net/emoticons/v2/36/static/light/3.0"
};

const PopoutChat = () => {
  const [displayedMessages, setDisplayedMessages] = useState([]); 
  const [darkMode, setDarkMode] = useState(false);
  const messagesEndRef = useRef(null); 

  // Listen for messages from the main window
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'UPDATE_MESSAGES') {
        setDisplayedMessages(event.data.payload);
        if (event.data.darkMode !== undefined) {
          setDarkMode(event.data.darkMode);  // Update dark mode state from parent
        }
      } else if (event.data.type === 'UPDATE_DARK_MODE') {
        // Handle dark mode updates independently
        if (event.data.darkMode !== undefined) {
          setDarkMode(event.data.darkMode);
        }
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
}, []);

const parseCreatePopoutEmotes = (messageContent) => {
  const words = messageContent.split(' ');

  return words.map((word, index) => {
    if (emoteMap[word]) {
      return (
        <img 
          key={index} 
          src={emoteMap[word]} 
          alt={word} 
          className="emote-img" 
          style={{ width: '24px', height: '24px', verticalAlign: 'middle' }}
        />
      );
    } else {
      return <span key={index}>{word} </span>;
    }
  });
};

  // Auto-scroll to the bottom whenever new messages are displayed
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [displayedMessages]);

  // Toggle dark mode when clicking the gear icon
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={darkMode ? 'popout-chat-container dark-mode' : 'popout-chat-container light-mode'}>
      <div className="popout-messages-container">
        {displayedMessages.map((message) => (
          <div key={message.id} className="popout-message">
            {message.badge_urls && message.badge_urls.map((url, idx) => (
              <img key={idx} src={url} alt="badge" className="popout-badge-img" />
            ))}
            <span className="popout-username" style={{ color: message.color_code }}>
              <strong>{message.chatter_name}</strong>
            </span>
            <span className="popout-colon">:</span>
            <span className="popout-content">{parseCreatePopoutEmotes(message.content)}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="fake-chat-display-div"><FakeChatDisplay /></div>
    </div>
  );
};

export default PopoutChat;
