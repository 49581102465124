// frontend/src/services/api.js
// frontend/src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.streamchatandy.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

let authToken = null; // Store the token in a variable

// Function to set the token
export const setAuthToken = (token) => {
  if (token) {
    authToken = token;
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // console.log('API: Attaching token to request header:');
  } else {
    authToken = null;
    delete api.defaults.headers.common['Authorization'];
    // console.warn('API: Token is undefined, removing Authorization header');
  }
};

// Axios request interceptor to ensure the token is set before making any request
const MAX_RETRIES = 5;
const RETRY_INTERVAL = 1000; // Retry every 500ms

api.interceptors.request.use(
  async (config) => {
    if (!authToken) {
      // Retry fetching the token if it's not available immediately
      for (let i = 0; i < MAX_RETRIES; i++) {
        if (authToken) {
          config.headers['Authorization'] = `Bearer ${authToken}`;
          return config; // Proceed once the token is available
        }
        await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL)); // Wait for token to be set
      }
      throw new axios.Cancel('Token not available after retries');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
