//frontend/src/components/LivePage/TwitchAuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sessionService from '../../services/sessionService';
import { useAuth0 } from '@auth0/auth0-react';  // Import Auth0 for token management
import { setAuthToken } from '../../services/api';  // Import to set the Auth0 token

const TwitchAuthCallback = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();  // Get Auth0 token

    useEffect(() => {
        const handleAuthCallback = async () => {
            const hashParams = new URLSearchParams(window.location.hash.replace('#', ''));
            const twitchOAuthToken = hashParams.get('access_token');  // Capture the Twitch token

            if (twitchOAuthToken) {
                // Try fetching the Auth0 token and attach it
                try {
                    const auth0Token = await getAccessTokenSilently();  // Get the Auth0 token
                    setAuthToken(auth0Token);  // Attach Auth0 token to Axios headers

                    // Send the Twitch OAuth token as part of the payload
                    sessionService.connectTwitch({ twitchOAuthToken })
                    .then((response) => {
                        const twitchUsername = response.twitch_username;  // Get the Twitch username
                        localStorage.setItem('twitch_username', twitchUsername);  // Store username locally for access in LiveControls
                        navigate('/live');  // Redirect once the Twitch token is stored
                    })
                    .catch(err => {
                        // console.error('Error connecting Twitch:', err);
                        navigate('/live');  // Still redirect to /live if there's an issue connecting Twitch
                    });
                } catch (error) {
                    // console.error('Error fetching Auth0 token:', error);
                    navigate('/live');  // Redirect to /live even if Auth0 token fetching fails
                }
            } else {
                // console.error('No Twitch OAuth token found');
                navigate('/live');  // Redirect to /live if no Twitch OAuth token is found
            }
        };

        handleAuthCallback();
    }, [navigate, getAccessTokenSilently]);

    return (
        <div>
            <p>Processing Twitch login...</p>
        </div>
    );
};

export default TwitchAuthCallback;
