// frontend/src/redux/actions/liveSessionActions.js

import { 
    START_LIVE_SESSION, 
    STOP_LIVE_SESSION, 
    ADD_LIVE_MESSAGE, 
    UPDATE_LIVE_SESSION, 
    CLEAR_LIVE_MESSAGES, 
    CLEAR_LIVE_SESSION_DATA, 
    FETCH_CHATTERS_REQUEST, 
    FETCH_CHATTERS_SUCCESS,
    FETCH_CHATTERS_FAILURE, 
    TRIGGER_CHAT_EVENT, 
    ADD_CONTEXT_ITEM, 
    REMOVE_CONTEXT_ITEM, 
    DELETE_CHATTER_REQUEST, 
    DELETE_CHATTER_SUCCESS, 
    DELETE_CHATTER_FAILURE, 
    GENERATE_CHATTER_ANDYS_REQUEST, 
    GENERATE_CHATTER_ANDYS_SUCCESS, 
    GENERATE_CHATTER_ANDYS_FAILURE,
    UPDATE_CHATTER_REQUEST, 
    UPDATE_CHATTER_SUCCESS, 
    UPDATE_CHATTER_FAILURE,
    CREATE_CHATTER_REQUEST,
    CREATE_CHATTER_SUCCESS,
    CREATE_CHATTER_FAILURE,
    
} from "./types";
import sessionService from "../../services/sessionService";

// Start the live session
export const startLiveSession = () => ({
    type: START_LIVE_SESSION,
});

export const stopLiveSession = () => ({
    type: STOP_LIVE_SESSION,
});

// Add a new message to the live chat
export const addLiveMessage = (message) => ({
    type: ADD_LIVE_MESSAGE,
    payload: message,
});

// Update session settings like frequency, tone, etc.
export const updateLiveSessionSettings = (settings) => ({
    type: UPDATE_LIVE_SESSION,
    payload: settings,
});

// Clear all live messages (used when a session ends)
export const clearLiveMessages = () => ({
    type: CLEAR_LIVE_MESSAGES,
});

export const clearLiveSessionData = () => ({
    type: CLEAR_LIVE_SESSION_DATA,
});

export const triggerChatEvent = (eventType) => ({
    type: TRIGGER_CHAT_EVENT,
    payload: eventType,
});

export const addContextItem = (context) => ({
    type: ADD_CONTEXT_ITEM,
    payload: context,
});

export const removeContextItem = (index) => ({
    type: REMOVE_CONTEXT_ITEM,
    payload: index,
});

// Chatter Andys //

export const fetchChatters = () => async (dispatch) => {
    dispatch({ type: FETCH_CHATTERS_REQUEST });
    try {
        if (typeof sessionService.getChatters !== 'function') {
            throw new Error('getChatters is not a function');
        }
        const chatters = await sessionService.getChatters();
        dispatch({ type: FETCH_CHATTERS_SUCCESS, payload: chatters });
    } catch (error) {
        // console.error('Error in fetchChatters:', error);
        dispatch({ type: FETCH_CHATTERS_FAILURE, payload: error.toString() });
    }
};

  
// 2. Generate Chatter Andys
export const generateChatterAndys = (numberOfChatters) => async (dispatch) => {
    dispatch({ type: GENERATE_CHATTER_ANDYS_REQUEST });
    try {
      const data = await sessionService.generateChatters(numberOfChatters);
      dispatch({ type: GENERATE_CHATTER_ANDYS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GENERATE_CHATTER_ANDYS_FAILURE, payload: error.toString() });
    }
};
  
// 3. Delete Chatter Andy
export const deleteChatter = (chatterId) => async (dispatch, getState) => {
    dispatch({ type: DELETE_CHATTER_REQUEST });
    try {
      const state = getState();
      const chatter = state.liveSessionReducer.chatters.find(c => c.id === chatterId);
  
      if (chatter && chatter.username === 'Unassigned') {
        alert("The 'Unassigned' ChatterAndy cannot be deleted.");
        return;
      }
  
      await sessionService.deleteChatter(chatterId);
      dispatch({ type: DELETE_CHATTER_SUCCESS, payload: chatterId });
  
      // Refresh chatters list
      dispatch(fetchChatters());
    } catch (error) {
      dispatch({ type: DELETE_CHATTER_FAILURE, payload: error.toString() });
    }
};
  
// 4. Update Chatter Andy
export const updateChatter = (chatterId, chatterData) => async (dispatch) => {
    dispatch({ type: UPDATE_CHATTER_REQUEST });
    try {
        const data = await sessionService.updateChatter(chatterId, chatterData);
        dispatch({ type: UPDATE_CHATTER_SUCCESS, payload: data });
        return data; // Optionally return the updated chatter data
    } catch (error) {
        dispatch({ type: UPDATE_CHATTER_FAILURE, payload: error.toString() });
        throw error; // Optionally rethrow the error for further handling
    }
};


// 5. Create Unassigned Chatter Andy
export const createUnassignedChatter = () => async (dispatch) => {
    try {
        const data = await sessionService.createUnassignedChatter();
        // console.log("Unassigned chatter created successfully:", data);
        return data;
    } catch (error) {
        // console.error("Error creating 'Unassigned' chatter:", error);
    }
};

// 6. Add Chatter Andy
export const createChatter = (chatterData) => async (dispatch) => {
    dispatch({ type: CREATE_CHATTER_REQUEST});
    try {
        const data = await sessionService.createChatter(chatterData);
        dispatch({ type: CREATE_CHATTER_SUCCESS, payload: data });
        // console.log("Chatter created successfully:", data);
        return data;
    } catch (error) {
        dispatch({ type: CREATE_CHATTER_FAILURE, payload: error.toString() });
        // console.error("Error creating chatter:", error);
    }
}