//frontend/src/basicChatPage/redux/basicChatSessionActions.js
import sessionService from '../../services/sessionService';
import {
  SAVE_SESSION_SUCCESS,
  SAVE_SESSION_FAILURE,
  LOAD_SESSIONS_SUCCESS,
  LOAD_SESSIONS_FAILURE,
  LOAD_SESSION_BY_ID_FAILURE,
  LOAD_SESSION_BY_ID_SUCCESS,
  LOAD_SESSIONS_REQUEST,
  CLEAR_CHAT_STATE,
  DELETE_SESSION_FAILURE,
  DELETE_SESSION_SUCCESS,

} from "./basicChatTypes";
import { addChatter, clearChatters } from "./basicChatChatterActions";
import { addMessage, clearMessages } from "./basicChatMessageActions";
import {  resetPlayback } from "./basicChatPlaybackActions";
import { updateTimelineLength } from './basicChatMessageActions';

export const saveSession = (sessionName) => async (dispatch, getState) => {
  try {
    // Get the entire basicChat slice of state
    const { basicChat } = getState();

    const response = await sessionService.saveSession(basicChat, sessionName); // Send full basicChat state
    dispatch({
      type: SAVE_SESSION_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SAVE_SESSION_FAILURE,
      payload: error.message,
    });
  }
};


export const clearChatState = () => (dispatch) => {
  dispatch(clearChatters());  // Clear chatters
  dispatch(clearMessages());  // Clear messages
  dispatch(resetPlayback());  // Reset playback
  dispatch({ type: CLEAR_CHAT_STATE });  // Dispatch the CLEAR_CHAT_STATE action
};

// Inside loadSessions action
export const loadSessions = () => async (dispatch) => {
  dispatch({ type: LOAD_SESSIONS_REQUEST });
  try {
    const sessions = await sessionService.loadSessions(); // Only return session ID and name
    dispatch({
      type: LOAD_SESSIONS_SUCCESS, payload: sessions, });
  } catch (error) {
    dispatch({ type: LOAD_SESSIONS_FAILURE, payload: error.toString(), });
  }
};

// Action to load a specific session by ID
export const loadSessionById = (sessionId) => async (dispatch) => {
  try {
        // Clear the current chat state before loading the new session
    dispatch(clearChatState());
    const response = await sessionService.loadSessionById(sessionId);
    const sessionData = response.session_data; // This is the full saved basicChat state



    // Dispatch chatters from the loaded session
    sessionData.chatters.chatterList.forEach((chatter) => {
      dispatch(addChatter(chatter)); // Dispatch each chatter to update the chatter list
    });

    // Dispatch messages from the loaded session
    sessionData.messages.messageList.forEach((message) => {
      dispatch(addMessage(message)); // Dispatch each message to update the message list
    });

        // Find the highest display_time in the message list to update the timeline length
    const maxDisplayTime = Math.max(...sessionData.messages.messageList.map((msg) => msg.display_time), 0);
    dispatch(updateTimelineLength(maxDisplayTime));
    // Set playback settings from the loaded session
    
    dispatch(loadSessions()); // Reload sessions after loading

    dispatch({
      type: LOAD_SESSION_BY_ID_SUCCESS,
      payload: response, // Store session details like ID and name in currentSession
    });
  } catch (error) {
    dispatch({
      type: LOAD_SESSION_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteSession = (sessionId) => async (dispatch) => {
  try {
    await sessionService.deleteSession(sessionId); // Call the service to delete the session
    dispatch({
      type: DELETE_SESSION_SUCCESS,
      payload: sessionId,  // Send the sessionId of the deleted session
    });
  } catch (error) {
    dispatch({
      type: DELETE_SESSION_FAILURE,
      payload: error.toString(),
    });
  }
};