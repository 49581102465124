import React from 'react';
import '../styles/basicChatMessageEmotePicker.css';

const emoteMap = {
    "LUL": "https://static-cdn.jtvnw.net/emoticons/v2/425618/static/light/3.0",
    "PogChamp": "https://static-cdn.jtvnw.net/emoticons/v2/305954156/static/light/3.0",
    "Kappa": "https://static-cdn.jtvnw.net/emoticons/v2/25/static/light/3.0",
    "SwiftRage": "https://static-cdn.jtvnw.net/emoticons/v2/34/static/light/3.0",
    "DansGame": "https://static-cdn.jtvnw.net/emoticons/v2/33/static/light/3.0",
    "PJSalt": "https://static-cdn.jtvnw.net/emoticons/v2/36/static/light/3.0"
};

const BasicChatMessageEmotePicker = ({ onSelectEmote, isVisible, position }) => {
    if (!isVisible) return null;

    return (
        <div
            className="emote-picker-modal"
            style={{
                position: 'absolute',
                top: position.top,
                left: position.left,
                zIndex: 1000
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {Object.entries(emoteMap).map(([name, url]) => (
                <img
                    key={name}
                    src={url}
                    alt={name}
                    className="emote-icon"
                    onClick={() => onSelectEmote(name)}
                />
            ))}
        </div>
    );
};

export default BasicChatMessageEmotePicker;
