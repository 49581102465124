// frontend/src/basicChatPage/components/basicChatMessage.js
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChatterById, getAllChatters, getAllMessagesSorted } from '../redux/basicChatSelectors';
import { secondsToMMSS } from '../utils/basicChatUtils';
import { updateMessage, updateTimelineLength, deleteMessageAndUpdateTimeline } from '../redux/basicChatMessageActions';
import BasicChatMessageEmotePicker from './basicChatMessageEmotePicker';
import '../styles/basicChatMessage.css';

// Badge data
const badgeOptions = [
    { id: 1, label: 'MOD', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3', category: 'rope' },
    { id: 6, label: 'VIP', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3', category: 'rope' },
    { id: 2, label: 'Subscriber', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3', category: 'sub' },
    { id: 3, label: 'Twitch Prime', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3', category: 'global' },
    { id: 4, label: 'Subscriber Tier 2', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3', category: 'sub' },
    { id: 5, label: 'Subscriber Tier 3', imageUrl: 'https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3', category: 'sub' },
    { id: 7, label: 'None', imageUrl: null, category: 'none' }
];

const emoteMap = {
    "LUL": "https://static-cdn.jtvnw.net/emoticons/v2/425618/static/light/3.0",
    "PogChamp": "https://static-cdn.jtvnw.net/emoticons/v2/305954156/static/light/3.0",
    "Kappa": "https://static-cdn.jtvnw.net/emoticons/v2/25/static/light/3.0",
    "SwiftRage": "https://static-cdn.jtvnw.net/emoticons/v2/34/static/light/3.0",
    "DansGame": "https://static-cdn.jtvnw.net/emoticons/v2/33/static/light/3.0",
    "PJSalt": "https://static-cdn.jtvnw.net/emoticons/v2/36/static/light/3.0"
};

const BasicChatMessage = ({ message }) => {
    const dispatch = useDispatch();
    const currentChatter = useSelector(state => getChatterById(state, message.chatter_id));
    const chatters = useSelector(getAllChatters) || [];
    const allMessages = useSelector(getAllMessagesSorted) || [];
    const emoteButtonRef = useRef(null);
    const messageInputRef = useRef(null);

    // State management
    const [isEditing, setIsEditing] = useState(false);
    const [selectedChatterId, setSelectedChatterId] = useState(message.chatter_id);
    const [editedContent, setEditedContent] = useState(message.content);
    const [editedMinutes, setEditedMinutes] = useState(Math.floor(message.display_time / 60));
    const [editedSeconds, setEditedSeconds] = useState(message.display_time % 60);
    const [isEmotePickerVisible, setIsEmotePickerVisible] = useState(false);
    const [emotePickerPosition, setEmotePickerPosition] = useState({ top: 0, left: 0 });
    
    const formattedTime = secondsToMMSS(message.display_time);

    // Handle badge URLs
    const getBadgeUrls = (badgeIds = []) => {
        let ropeBadge = null, subBadge = null, globalBadge = null;
        badgeIds.forEach((badgeId) => {
            const badge = badgeOptions.find(badge => badge.id === badgeId);
            if (badge) {
                if (badge.category === 'rope' && !ropeBadge) ropeBadge = badge.imageUrl;
                if (badge.category === 'sub' && !subBadge) subBadge = badge.imageUrl;
                if (badge.category === 'global' && !globalBadge) globalBadge = badge.imageUrl;
            }
        });
        return [ropeBadge, subBadge, globalBadge].filter(Boolean);
    };

    const badgeUrls = getBadgeUrls(currentChatter?.badge_ids || []);

    // Toggle editing mode
    const toggleEdit = () => setIsEditing(!isEditing);

    // Save edits
    const handleSaveEdit = () => {
        const totalSeconds = (editedMinutes * 60) + editedSeconds;
        const selectedChatterEdit = chatters.find(chatter => chatter.id === selectedChatterId);
        dispatch(updateMessage(message.id, {
            chatter_id: selectedChatterId,
            chatter_name: selectedChatterEdit.username,
            content: editedContent,
            color_code: selectedChatterEdit.color_code,
            display_time: totalSeconds
        }));

        const maxDisplayTime = Math.max(...allMessages.map(msg => msg.id === message.id ? totalSeconds : msg.display_time));
        dispatch(updateTimelineLength(maxDisplayTime));
        setIsEditing(false);
        setIsEmotePickerVisible(false);
    };

    // Handle selecting an emote
// Handle selecting an emote
const handleEmoteSelect = (emoteName) => {
    if (!emoteName) return;

    const inputField = messageInputRef.current;
    if (inputField) {
        const cursorPosition = inputField.selectionStart;
        const textBeforeCursor = editedContent.slice(0, cursorPosition);
        const textAfterCursor = editedContent.slice(cursorPosition);

        // Insert emote at the cursor position or at the end if no position
        setEditedContent(`${textBeforeCursor} ${emoteName} ${textAfterCursor}`.trim());

        // Move the cursor to the end of the inserted emote
        setTimeout(() => {
            inputField.focus();
            inputField.selectionStart = cursorPosition + emoteName.length + 1;
            inputField.selectionEnd = cursorPosition + emoteName.length + 1;
        }, 0);
    }

    setIsEmotePickerVisible(false);
};

    // Show emote picker at button's position
    const handleEmoteButtonClick = () => {
        if (emoteButtonRef.current) {
            const rect = emoteButtonRef.current.getBoundingClientRect();
            setEmotePickerPosition({
                top: rect.top - 20, // Adjust this value to control the vertical position (e.g., -100 to -80)
                left: rect.left - 25 // Adjust this value to control the horizontal position (e.g., +20 to +0)
            });
            setIsEmotePickerVisible(!isEmotePickerVisible);
        }
    };
    // Render message with emotes
    const renderMessageWithEmotes = (content) => {
        return content.split(' ').map((word, index) => {
            if (emoteMap[word]) {
                return <img key={index} src={emoteMap[word]} alt={word} className="chat-emote-img" />;
            }
            return <span key={index}>{word} </span>;
        });
    };

    return (
        <div className="basic-chat-message">
            {isEditing ? (
                <div className="editing-section">
                    {/* Chatter Dropdown */}
                    <button onClick={handleSaveEdit} className="btn-primary">Save</button>

                     {/* Time Editing Inline */}
                    <div className="time-edit-inline">
                        <label className="time-label">Min:</label>
                        <input
                            type="number"
                            value={editedMinutes}
                            onChange={(e) => setEditedMinutes(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                            className="form-control no-arrows time-input"
                        />
                        <label className="time-label">Sec:</label>
                        <input
                            type="number"
                            value={editedSeconds}
                            onChange={(e) => setEditedSeconds(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                            className="form-control no-arrows time-input"
                        />
                    </div>
                    <select
                        value={selectedChatterId}
                        onChange={(e) => setSelectedChatterId(e.target.value)}
                        className="form-control-select"
                    >
                        {chatters.map(chatter => (
                            <option key={chatter.id} value={chatter.id} style={{ color: chatter.color_code }}>
                                {chatter.username}
                            </option>
                        ))}
                    </select>

                    {/* Message Input with Emote Button */}
                    <div className="input-with-emote">
                    <input
                    type="text"
                    ref={messageInputRef}
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    className="form-control"
                    placeholder="Edit your message here"
                />
                        <button
                            ref={emoteButtonRef}
                            className="emote-button"
                            onClick={handleEmoteButtonClick}
                        >
                            😊
                        </button>
                    </div>

                    {/* Save and Delete buttons */}
                    <button onClick={() => setIsEditing(false)} className="btn-secondary">Cancel</button>
                </div>
            ) : (
                <div className="message-display">
                    <div className="message-content-wrapper">
                        <button onClick={toggleEdit} className="btn btn-sm btn-primary" style={{ marginRight: '5px' }}>Edit</button>
                        <span className="chat-time">{formattedTime}</span>
                        {badgeUrls.map((badgeUrl, index) => (
                            <img key={index} src={badgeUrl} alt="badge" className="chat-badge-img" />
                        ))}
                        <span style={{ color: currentChatter?.color_code || '#000' }}>
                            {currentChatter?.username || 'Unknown'}:
                        </span>
                        <span className="message-content">{renderMessageWithEmotes(editedContent)}</span>
                    </div>

                    {/* The delete button container to push it to the right */}
                    <div className="delete-button-container">
                        <button
                            onClick={() => dispatch(deleteMessageAndUpdateTimeline(message.id))}
                            className="btn btn-sm btn-danger delete-button"
                        >
                            X
                        </button>
                    </div>
                </div>
            )}

            {/* Emote picker modal */}
            <BasicChatMessageEmotePicker
                onSelectEmote={handleEmoteSelect}
                isVisible={isEmotePickerVisible}
                position={emotePickerPosition}
            />
        </div>
    );
};

export default BasicChatMessage;
