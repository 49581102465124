// frontend/src/basicChatPage/redux/basicChatMessageActions.js
import { ADD_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE, CLEAR_MESSAGES, SELECT_MESSAGE, UPDATE_TIMELINELENGTH, } from "./basicChatTypes";

export const addMessage = (message) => {
    return {
        type: ADD_MESSAGE,
        payload: message
    }
}

export const updateMessage = (messageID, messageData) => ({
    type: UPDATE_MESSAGE,
    payload: { messageID, messageData }
});

export const deleteMessage = (messageID) => ({
    type: DELETE_MESSAGE,
    payload: messageID
});

export const deleteMessageAndUpdateTimeline = (messageID) => (dispatch, getState) => {
    const { basicChat } = getState();
    const messageList = basicChat.messages.messageList;

    // Filter out the message that is being deleted
    const remainingMessages = messageList.filter((msg) => msg.id !== messageID);

    // Calculate the new timeline length
    let newTimelineLength = 0;
    if (remainingMessages.length > 0) {
        newTimelineLength = Math.max(...remainingMessages.map((msg) => msg.display_time));
    }

    // First update the timeline length
    dispatch(updateTimelineLength(newTimelineLength));
    // console.log("Delete&Update: : ", newTimelineLength)

    // Then delete the message
    dispatch(deleteMessage(messageID));
};

export const selectMessage = (messageId) => ({
    type: SELECT_MESSAGE,
    payload: messageId
});

export const clearMessages = () => ({
    type: CLEAR_MESSAGES
});

export const updateTimelineLength = (length) => ({
    type: UPDATE_TIMELINELENGTH,
    payload: length + 1,
});