// frontend/src/components/SessionEditor/ChatterSettingsModal.js
import React from 'react';
import './ChatterSettingsModal.css';
import ChatterSettings from './ChatterSettings';

const ChatterSettingsModal = ({ show, handleClose, chatterId, modalPosition, refreshChatters }) => {
    if (!show || !chatterId) return null;

    return (
        <div
            className="chatter-settings-modal"
            style={{
                position: 'absolute',
                top: modalPosition.top - 25,  // Subtract 25 pixels from the top position
                left: modalPosition.left,
                zIndex: 2,
            }}
        >
            <div className="modal-content">
                <div className="modal-body">
                    <ChatterSettings 
                        chatterId={chatterId} 
                        closeModal={handleClose}  // Pass the closeModal function 
                        refreshChatters={refreshChatters}  // Pass refreshChatters function
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatterSettingsModal;
