//src/containers/components/ContactSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import defaultPicture from '../../assets/icons/navbarmrferret.png';
import { FaEnvelope, FaFileAlt, FaShieldAlt } from 'react-icons/fa';
import './ContactSection.css';

const ContactSection = () => {
    return (
        <div className="contact-section-custom">
            <div className="contact-info-custom">

                <div className="contact-box-custom">
                    <h3 className="contact-title-custom">Terms</h3>
                    <div className="contact-box-content-custom">
                        <Link to="/privacy-policy">
                            <FaShieldAlt style={{ marginRight: '5px' }} />
                            Privacy Policy
                        </Link>
                        <div>
                        <Link to="/terms-of-service">
                            <FaFileAlt style={{ marginRight: '5px' }} />
                            Terms of Service
                        </Link>
                        </div>
                    </div>
                </div>
                <div className="logo-wrapper-contact"><img src={defaultPicture} alt="Logo" className="contact-logo-custom" /></div>
                
                <div className="contact-box-custom">
                    <h3 className="contact-title-custom">Support</h3>
                    <div className="contact-box-content-custom">
                        <p>Contact</p>
                        <p>
                            <FaEnvelope style={{ marginRight: '5px' }} />
                            mrferretgames@gmail.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;