// frontend/src/containers/LogoutPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 1000); // Redirect to the homepage after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [navigate]);

  return (
    <div>
      <h1>You have been logged out</h1>
      <p>Redirecting to the homepage...</p>
    </div>
  );
};

export default LogoutPage;