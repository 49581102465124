//frontsend/src/basicChatPage/redux/basicChatChatterReducer.js

import { ADD_CHATTER, UPDATE_CHATTER, DELETE_CHATTER, SELECT_CHATTER, CLEAR_CHATTERS, SET_CHATTER_ARCHETYPE_SUCCESS, SET_CHATTER_BIO_SUCCESS } from "./basicChatTypes";


const initialState = {
    chatterList: [
        {
            sessionID: '',
            id: 0,
            username: 'Unassigned',
            archetype: 'default',
            color_code: '#FE3B34',
            custom_message: '',
            badges_ids: [],
        }
    ],
    selectedChatter: null,
};

const basicChatChatterReducer = (state = initialState, action) => {
    switch (action.type) { 
        
        case ADD_CHATTER: 
            return {
                ...state, 
                chatterList: [...state.chatterList, action.payload] // Fix this reference
            };

        case UPDATE_CHATTER:
            return {
                ...state,
                chatterList: state.chatterList.map(chatter => {  // Fix this reference
                    if (chatter.id === action.payload.chatterID) {
                        return { ...chatter, ...action.payload.chatterData };
                    }
                    return chatter;
                })
            };

        case DELETE_CHATTER:
            return {
                ...state,
                chatterList: state.chatterList.filter(chatter => chatter.id !== action.payload)  // Fix this reference
            };

        case SELECT_CHATTER:
            return {
                ...state,
                selectedChatter: action.payload
            };

        case CLEAR_CHATTERS:
            return {
                ...state,
                chatterList: [{
                    sessionID: '',
                    id: 0,
                    username: 'Unassigned',
                    archetype: 'default',
                    color_code: '#FE3B34',
                    custom_message: '',
                    badges_ids: [],
                }],
                selectedChatter: null
            };

        case SET_CHATTER_ARCHETYPE_SUCCESS:
            return {
                ...state,
                chatterList: state.chatterList.map(chatter => {
                    if (chatter.id === action.payload.chatterID) {
                        return { ...chatter, archetype: action.payload.archetype };
                    }
                    return chatter;
                })
            };

        case SET_CHATTER_BIO_SUCCESS:
            return {
                ...state,
                chatterList: state.chatterList.map(chatter => {
                    if (chatter.id === action.payload.chatterID) {
                        return { ...chatter, custom_message: action.payload.customMessage };
                    }
                    return chatter;
                })
            };
        
        default:
            return state;
    }
};

export default basicChatChatterReducer;