// frontend/src/redux/actions/authActions.js
import { 
  LOGOUT_USER, 
  SET_CURRENT_USER, 
  SET_AUTH_STATUS, 
  GET_SUBSCRIPTIONS_STATUS_REQUEST, 
  GET_SUBSCRIPTIONS_STATUS_SUCCESS, 
  GET_SUBSCRIPTIONS_STATUS_FAILURE, 
  SET_SUBSCRIPTIONS_STATUS_REQUEST, 
  SET_SUBSCRIPTIONS_STATUS_SUCCESS, 
  SET_SUBSCRIPTIONS_STATUS_FAILURE, 
  GET_TOKEN_BALANCE_REQUEST, 
  GET_TOKEN_BALANCE_SUCCESS, 
  GET_TOKEN_BALANCE_FAILURE, 
  SET_TOKEN_BALANCE_REQUEST, 
  SET_TOKEN_BALANCE_SUCCESS, 
  SET_TOKEN_BALANCE_FAILURE,
  SET_OVERLAY_UUID,
  CHECK_TWITCH_OAUTH_REQUEST,
  CHECK_TWITCH_OAUTH_SUCCESS,
  CHECK_TWITCH_OAUTH_FAILURE,
} from '../actions/types';
import { useAuth0 } from '@auth0/auth0-react';
import sessionService from '../../services/sessionService';

// Action to log in and store user info in Redux
export const loginUser = (userData) => (dispatch) => {
  const { updated_at, email, email_verified, name, picture } = userData;
  dispatch({
    type: SET_CURRENT_USER,
    payload: { updated_at, email, email_verified, name, picture },
  });
};

// Action to log out
export const logoutUser = () => (dispatch) => {
  const { logout: auth0Logout } = useAuth0(); // Access Auth0's logout function

  try {
    auth0Logout({ returnTo: window.location.origin });
    dispatch({ type: LOGOUT_USER });
  } catch (error) {
    // console.error("authActions.js: Logout error:", error);
  }
};

// Action to log out
export const logoutAction = () => {
  return {
    type: LOGOUT_USER,
  };
};



export const setAuthStatus = (isAuthenticated) => ({
  type: SET_AUTH_STATUS,
  payload: isAuthenticated,
});



// Get subscription status from backend
export const getSubscriptionStatus = () => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIPTIONS_STATUS_REQUEST });
  try {
    const response = await sessionService.getSubscriptionStatus();
    // console.log("Subscription status fetched:", response.is_paid_subscriber);  // Log fetched status
    dispatch({ type: GET_SUBSCRIPTIONS_STATUS_SUCCESS, payload: response.is_paid_subscriber });
  } catch (error) {
    // console.error("Error fetching subscription status:", error.message);  // Log any error
    dispatch({ type: GET_SUBSCRIPTIONS_STATUS_FAILURE, payload: error.message });
  }
};

// Set subscription status
export const setSubscriptionStatus = (isPaidSubscriber) => async (dispatch) => {
  dispatch({ type: SET_SUBSCRIPTIONS_STATUS_REQUEST });
  try {
    const response = await sessionService.setSubscriptionStatus(isPaidSubscriber);
    dispatch({ type: SET_SUBSCRIPTIONS_STATUS_SUCCESS, payload: response.is_paid_subscriber });
  } catch (error) {
    dispatch({ type: SET_SUBSCRIPTIONS_STATUS_FAILURE, payload: error.message });
  }
};

// Get token balance from backend
export const getTokenBalance = () => async (dispatch) => {
  dispatch({ type: GET_TOKEN_BALANCE_REQUEST });
  try {
    const response = await sessionService.getTokenBalance();
    dispatch({ type: GET_TOKEN_BALANCE_SUCCESS, payload: response.token_balance });
  } catch (error) {
    dispatch({ type: GET_TOKEN_BALANCE_FAILURE, payload: error.message });
  }
};

export const setTokenBalance = (tokenBalance) => async (dispatch) => {
  dispatch({ type: SET_TOKEN_BALANCE_REQUEST });
  try {
    const response = await sessionService.setTokenBalance(tokenBalance);
    dispatch({ type: SET_TOKEN_BALANCE_SUCCESS, payload: response.token_balance });
  } catch (error) {
    dispatch({ type: SET_TOKEN_BALANCE_FAILURE, payload: error.message });
  }
};

export const setOverlayUUID = (uuid) => ({
  type: SET_OVERLAY_UUID,
  payload: uuid,
});

// Existing action to get overlay UUID from backend
export const getOverlayUUID = () => async (dispatch) => {
  try {
    const response = await sessionService.getOverlayUUID();
    const { uuid } = response;
    dispatch(setOverlayUUID(uuid)); // Dispatch the action to set the UUID
  } catch (error) {
    // console.error('Error fetching overlay UUID:', error.message);
  }
};


export const checkTwitchOauthStatus = () => async (dispatch) => {
  dispatch({ type: CHECK_TWITCH_OAUTH_REQUEST });
  try {
    const response = await sessionService.checkOauthStatus();
    dispatch({
      type: CHECK_TWITCH_OAUTH_SUCCESS,
      payload: {
        is_oauth_connected: response.is_oauth_connected,
        twitch_username: response.twitch_username,
      },
    });
  } catch (error) {
    dispatch({
      type: CHECK_TWITCH_OAUTH_FAILURE,
      payload: error.message,
    });
  }
};