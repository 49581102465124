// frontend/src/basicChatPage/components/basicChatPlaybackTimeBar.js
// frontend/src/basicChatPage/components/basicChatPlaybackTimeBar.js
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getTimelineLength } from '../redux/basicChatSelectors';

const BasicPlaybackTimeBar = ({ progressPercentage, currentTime, timelineLength, playbackStatus, stopTimer, handleTimeChange, startTimer, pauseTimer }) => {

    const timelineRef = useRef(null);
    const timelineLengthSelector = useSelector(getTimelineLength);

    useEffect(() => {
        // console.log('BasicPlaybackTimeBar mounted');
        // console.log('PlaybackBar: Timeline length from selector:', timelineLengthSelector);

        return () => {
            // console.log('BasicPlaybackTimeBar unmounted');
        };
    }, [timelineLengthSelector]);

    const handleMouseDown = (event) => {
        handleMouseMove(event); // Immediately update the time on click
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (event) => {
        if (!timelineRef.current) return;

        const timelineRect = timelineRef.current.getBoundingClientRect();
        const clickPosition = event.clientX - timelineRect.left;

        // Calculate the new time based on where the user clicked
        let newTime = (clickPosition / timelineRect.width) * timelineLength;

        // Ensure newTime is within bounds (0 to timelineLength)
        newTime = Math.max(0, Math.min(newTime, timelineLength));

        handleTimeChange(Math.floor(newTime)); // Update time
    };

    const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <div className="basicchat-playback-controls">
          {/* Timeline with handle */}
          <div
            className="basicchat-timeline-container"
            ref={timelineRef}
            onMouseDown={handleMouseDown}
          >
            <div className="basicchat-timeline-bar" style={{ width: `${progressPercentage}%` }} />
            <div
              className="basicchat-timeline-handle"
              style={{ left: `calc(${progressPercentage}% - 5px)` }} // Adjust handle position
            />
          </div>
    
          {/* Container for play/pause/stop buttons and current time */}
          <div className="basicchat-playback-buttons-container">
            <div className="basicchat-playback-buttons">
              <button
                onClick={() =>
                  playbackStatus === 'playing' ? pauseTimer() : startTimer()
                }
              >
                {playbackStatus === 'playing' ? 'Pause' : 'Play'}
              </button>
              <button onClick={stopTimer}>Stop</button>
            </div>
            <div className="basicchat-current-time">
              Current Time: {currentTime} seconds
            </div>
          </div>
        </div>
      );
    };
    
    export default BasicPlaybackTimeBar;