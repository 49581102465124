/// frontend/src/components/LivePage/PopoutChatComponent.js
import React, { useEffect, useState, useRef } from 'react';
import './PopoutChatComponent.css';
import FakeChatDisplay from '../common/fakeChatDisplay';

const PopoutChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [darkMode, setDarkMode] = useState(false);
    const messagesContainerRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(false);

    // Handle incoming messages from parent window
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;

            const { type, payload } = event.data;

            // Only handle specific message types
            if (type === 'INITIAL_MESSAGES' || type === 'NEW_MESSAGES' || type === 'UPDATE_DARK_MODE') {
                // console.log(`[PopoutChatComponent] Processing message type: ${type}`, payload);

                switch (type) {
                    case 'INITIAL_MESSAGES':
                        // console.log(`[PopoutChatComponent] Setting INITIAL_MESSAGES`);
                        setMessages(payload);
                        if (event.data.darkMode !== undefined) {
                            // console.log(`[PopoutChatComponent] Setting darkMode to`, event.data.darkMode);
                            setDarkMode(event.data.darkMode);
                        }
                        break;
                    case 'NEW_MESSAGES':
                        // console.log(`[PopoutChatComponent] Appending NEW_MESSAGES`);
                        setMessages((prevMessages) => {
                            const newMessages = [...prevMessages, ...payload].slice(-100); // Keep the last 100 messages
                            // console.log(`[PopoutChatComponent] Updated messages array`, newMessages);
                            return newMessages;
                        });
                        if (event.data.darkMode !== undefined) {
                            // console.log(`[PopoutChatComponent] Updating darkMode to`, event.data.darkMode);
                            setDarkMode(event.data.darkMode);
                        }
                        break;
                    case 'UPDATE_DARK_MODE':
                        // console.log(`[PopoutChatComponent] Updating darkMode to`, payload);
                        setDarkMode(payload);
                        break;
                    default:
                        break;
                }
            } else {
                // console.warn(`[PopoutChatComponent] Ignored message type: ${type}`, event.data);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer && !isUserScrolling) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
            // console.log(`[PopoutChatComponent] Scrolling to bottom of container`);
        }
    }, [messages, isUserScrolling]);

    const handleScroll = () => {
        const container = messagesContainerRef.current;
        if (!container) return;

        const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 10;
        // console.log(`[PopoutChatComponent] User scrolling, isAtBottom:`, isAtBottom);
        setIsUserScrolling(!isAtBottom);
    };

    // console.log(`[PopoutChatComponent] Rendered with messages`, messages);
    // console.log(`[PopoutChatComponent] Dark mode is`, darkMode);

    return (
        <div className={darkMode ? 'popout-chat-container-v2 dark-mode-v2' : 'popout-chat-container-v2'}>
            <div
                className="popout-messages-container-v2"
                ref={messagesContainerRef}
                onScroll={handleScroll}
            >
                {messages
                    .slice() // Create a copy of the messages array
                    .reverse() // Reverse the order to display newest messages at the bottom
                    .map((message, index) => (
                        <div key={index} className="popout-message-v2">
                            {message.badge_urls && message.badge_urls.map((url, idx) => (
                                <img key={idx} src={url} alt="badge" className="popout-badge-img-v2" />
                            ))}
                            <span className="popout-username-v2" style={{ color: message.chatter_color }}>
                                <strong>{message.chatter_name || 'Unknown'}</strong>
                            </span>
                            <span className="popout-colon-v2">:</span>
                            <span className="popout-content-v2" dangerouslySetInnerHTML={{ __html: message.message }} />
                        </div>
                    ))}
            </div>

            <div className="fake-chat-display-div-v2">
                <FakeChatDisplay darkMode={darkMode} toggleDarkMode={() => setDarkMode(!darkMode)} />
            </div>
        </div>
    );
};

export default PopoutChatComponent;
