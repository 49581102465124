// frontend/src/basicChatPage/redux/basicChatSessionReducer.js

import {
  SAVE_SESSION_REQUEST,
  SAVE_SESSION_SUCCESS,
  SAVE_SESSION_FAILURE,
  LOAD_SESSIONS_REQUEST,
  LOAD_SESSIONS_SUCCESS,
  LOAD_SESSIONS_FAILURE,
  LOAD_SESSION_BY_ID_REQUEST,
  LOAD_SESSION_BY_ID_SUCCESS,
  LOAD_SESSION_BY_ID_FAILURE, DELETE_SESSION_FAILURE, DELETE_SESSION_SUCCESS,
} from "./basicChatTypes";

// Define the initial state for session-related data
const initialState = {
  savedSessions: [],  // Array of saved session objects
  currentSession: null,  // Store the loaded session by ID
  loading: false,
  error: null,
};

// Reducer to handle session-related actions
const basicChatSessionReducer = (state = initialState, action) => {
  switch (action.type) {
      case SAVE_SESSION_REQUEST:
      case LOAD_SESSIONS_REQUEST:
        return { ...state, loading: true, error: null };
      case LOAD_SESSION_BY_ID_REQUEST:
          return {
              ...state,
              loading: true,   // Set loading state when making a request
              error: null,     // Reset error before starting the request
          };

      case SAVE_SESSION_SUCCESS:
          return {
              ...state,
                // Append new saved session
              loading: false,  // Request completed, no longer loading
              error: null,
          };

      case SAVE_SESSION_FAILURE:
          return {
              ...state,
              loading: false,   // Stop loading on failure
              error: action.payload,  // Store the error message
          };

      case LOAD_SESSIONS_SUCCESS:
          return { ...state, savedSessions: action.payload, loading: false };

      case LOAD_SESSIONS_FAILURE:
          return {
              ...state,
              loading: false,   // Stop loading on failure
              error: action.payload,  // Store the error message
          };

      case LOAD_SESSION_BY_ID_SUCCESS:
          return {
          };

      case LOAD_SESSION_BY_ID_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload,  // Store the error message
          };

        case DELETE_SESSION_FAILURE:
            return {
              ...state,
              error: action.payload, // Store the error message
            };

        case DELETE_SESSION_SUCCESS:
                return {
                  ...state,
                  savedSessions: state.savedSessions.filter(
                    (session) => session.id !== action.payload  // Remove the deleted session
                  ),
                };

      default:
          return state;
  }
};

export default basicChatSessionReducer;
