// frontend/src/basicChatPage/components/basicChatControlModal.js
import React from 'react';
import '../styles/basicChatEditModal.css'; // Reuse existing modal styles

const BasicChatControlModal = ({ isOpen, onClose, onSave, minutes, seconds, setMinutes, setSeconds }) => {
    if (!isOpen) return null;

    const handleSave = () => {
        onSave();
        onClose();
    };

    return (
        <div className="basicchat-modal-overlay" onClick={onClose}>
            <div
                className="basicchat-modal"
                onClick={(e) => e.stopPropagation()}
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed' }}
            >
                <div className="basicchat-modal-body">
                    <label>Edit Custom Time (mm:ss):</label>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <input
                            type="text"
                            value={minutes}
                            onChange={(e) => setMinutes(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                            className="form-control"
                            style={{ width: '50px' }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                        <span>:</span>
                        <input
                            type="text"
                            value={seconds}
                            onChange={(e) => setSeconds(Math.max(0, Math.min(59, parseInt(e.target.value, 10))))}
                            className="form-control"
                            style={{ width: '50px' }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <button className="btn btn-primary" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicChatControlModal;
