// src/aboutpage/components/aboutDev.js

import React from 'react';
import '../styles/aboutDev.css';

const AboutDev = () => {
  return (
    <div className="about-dev-container">
      <h2 className="about-dev-title">Future Development</h2>
      <div className="about-dev-cards">
        <div className="dev-card">
          <h3 className="dev-card-title">AI Messages</h3>
          <p>Specify types of chat messages a user will ask, e.g., question, statement, etc.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">Dynamic Discussion</h3>
          <p>AI messages on Live Page.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">AI to AI</h3>
          <p>AI messages on Live Page.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">Filler Messages</h3>
          <p>AI messages on Live Page.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">AI Generated Create! Chats</h3>
          <p>Subscribed users will be able to generate chats on the Create! tool while specifying events for the Chat to react to at user specified timestamps.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">Real-Time Audio-Visual Context</h3>
          <p>AI will respond more contextually based on stream audio and video cues.</p>
        </div>
        <div className="dev-card">
          <h3 className="dev-card-title">More Platforms</h3>
          <p>Integration with platforms like YouTube and Kick to reach a wider audience.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutDev;