// frontend/src/basicChatPage/redux/basicChatPlaybackActions.js
import { SET_PLAYBACK_TIME, SET_PLAYBACK_STATUS, RESET_PLAYBACK } from "./basicChatTypes";

// Action Creators
export const setPlaybackTime = (time) => ({
    type: SET_PLAYBACK_TIME,
    payload: time,
  });
  
  export const setPlaybackStatus = (status) => ({
    type: SET_PLAYBACK_STATUS,
    payload: status,  // 'playing', 'paused', 'stopped'
  });
  
  export const resetPlayback = () => ({
    type: RESET_PLAYBACK,
  });