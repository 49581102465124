// frontend/src/services/obsApi.js
import axios from 'axios';

const obsApi = axios.create({
  baseURL: 'https://api.streamchatandy.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

// Method to validate the overlay UUID
export const validateOverlayUUID = (uuid) => {
  return obsApi.get(`subscriptionstatus/overlay_uuid/?uuid=${uuid}`)
    .then(res => res.data)
    .catch(error => {
      // console.error('Error validating overlay UUID:', error);
      throw error;
    });
};

export default obsApi;


export const checkOverlayStatus = (uuid) => {
  return obsApi.get(`subscriptionstatus/check_ws/?uuid=${uuid}`)
    .then(res => res.data)
    .catch(error => {
      // console.error('Error checking overlay UUID status:', error);
      throw error;
    });
};